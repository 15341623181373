import React, { Component, useState } from "react";
import { Modal, Row, Col, Button, Form, Table, Badge } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import ReactStars from "react-stars";
import { GET_CAMPAIGN } from "./ViewCampaign";
import { GET_QA } from "./ViewQA";

const UPDATE_STARS_CAMPAIGN = gql`
  mutation UpdateStarsCampaign($id: ID!, $stars: Int!, $review: String!) {
    update: updateCampaign(id: $id, stars: $stars, review: $review) {
      id
      stars
      review
    }
  }
`;

const UPDATE_STARS_QA = gql`
  mutation UpdateStarsQA($id: ID!, $stars: Int!, $review: String!) {
    update: updateQA(id: $id, stars: $stars, review: $review) {
      id
      stars
      review
    }
  }
`;

function FinishModal(props) {
  const [stars, setStars] = useState(0);
  const [review, setReview] = useState("");

  var UPDATE_STARS;
  var CACHE_QUERY;
  var CACHE_KEY;
  var ObjectId;
  if (props.campaign != undefined) {
    UPDATE_STARS = UPDATE_STARS_CAMPAIGN;
    CACHE_QUERY = GET_CAMPAIGN;
    CACHE_KEY = "campaign";
    ObjectId = props.campaign.id;
  } else if (props.qa != undefined) {
    UPDATE_STARS = UPDATE_STARS_QA;
    CACHE_QUERY = GET_QA;
    CACHE_KEY = "qa";
    ObjectId = props.qa.id;
  } else {
    return null;
  }

  const [updateStars] = useMutation(UPDATE_STARS, {
    update(cache, { data }) {
      const cachedData = cache.readQuery({
        query: CACHE_QUERY,
        variables: { id: data.update.id },
      });
      const updatedCache = {
        ...cachedData[CACHE_KEY],
        stars: data.update.stars,
        review: data.update.review,
      };
      cache.writeQuery({
        query: CACHE_QUERY,
        variables: { id: data.update.id },
        data: { updatedCache },
      });
    },
  });

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Gefa einkun</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Einkun</Form.Label>
          <ReactStars
            count={5}
            size={30}
            half={false}
            edit={true}
            value={stars}
            onChange={async (value) => {
              setStars(value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Umsögn</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={review}
            onChange={(e) => {
              setReview(e.target.value);
            }}
          />
        </Form.Group>

        <Button
          className="pull-right"
          size="sm"
          onClick={async () => {
            const response = await updateStars({
              variables: {
                id: ObjectId,
                stars,
                review,
              },
            });
            props.onClose();
          }}
        >
          Ljúka
        </Button>
      </Modal.Body>
    </Modal>
  );
}

function FinishModalButton(props) {
  const [show, setShow] = useState(false);

  return (
    <React.Fragment>
      <FinishModal {...props} show={show} onClose={() => setShow(false)} />
      <Button
        className={props.className}
        style={props.style}
        size="sm"
        disabled={props.locked}
        onClick={() => setShow(true)}
      >
        Ljúka herferð
      </Button>
    </React.Fragment>
  );
}
export default FinishModalButton;
