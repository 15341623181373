import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { validate as uuValidate } from "uuid";

import navigation from "../../../../menu-items";
import routes from "../../../../routes";

function Breadcrumb() {
  const [mainState, setMainState] = useState();
  const [itemState, setItemState] = useState();

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  // Add a secondary path if last argument was UUID
  const path2 =
    pathParts.length > 1 && uuValidate(pathParts[pathParts.length - 1])
      ? pathParts.slice(0, -1).join("/")
      : undefined;

  useEffect(() => {
    // Iterate over each top level menu items
    navigation.items.map((item, index) => {
      if (item.type && item.type === "group") {
        getCollapse(item);
      }
      return false;
    });
  }, [location]);

  const getCollapse = (item) => {
    if (item.children) {
      item.children.filter((collapse) => {
        // If it is a collapsable then go deeper
        if (collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
        } else if (collapse.type && collapse.type === "item") {
          const compUrl = "/" + collapse.url.replace(/^\//, "");
          if (location.pathname === compUrl || path2 === compUrl) {
            setItemState(collapse);
            setMainState(item);
          }
        } else if (collapse.type && collapse.type === "item-match") {
          if (location.pathname.match(collapse.match)) {
            setItemState(collapse);
            setMainState(item);
          }
        }
        return false;
      });
    }
  };

  const main =
    mainState?.type === "collapse" ? (
      <li className="breadcrumb-item">
        <a href={mainState.url || "#"}>{mainState.title}</a>
      </li>
    ) : undefined;

  const item =
    itemState?.type === "item" || itemState?.type == "item-match" ? (
      <li className="breadcrumb-item">
        <a href={"#"}>{itemState.title}</a>
      </li>
    ) : undefined;

  const tabTitle =
    (itemState ? itemState.title + " | " : "") + "Herferðagreining";
  return (
    <Fragment>
      <Helmet>
        <title>{tabTitle}</title>
      </Helmet>
      {item ? (
        <div className="page-header">
          <div className="page-block">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="page-header-title">
                  {/*<h5 className="m-b-10">{title}</h5>*/}
                </div>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="feather icon-home" />
                    </Link>
                  </li>
                  {main}
                  {item}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-header"></div>
      )}
    </Fragment>
  );
}

export default Breadcrumb;
