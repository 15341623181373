import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import FailWhale from "../../components/FailWhale";
import { dateFormat, numberFormat, currencyFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import * as Bar from "@nivo/bar";
import Sugar from "sugar";
import Color from "../../utils/Color";
import useQuery from "../../utils/useQuery";

import { renderBar, renderAreas } from "../../utils/CustomGraph";

const color_scheme = [
  "#2965aa",
  "#a8322f",
  "#87a939",
  "#6e5094",
  "#288da2",
  "#e2df30",
  "#ce2ca8",
  "#d47822",
  "#43c282",
  "#2dc2b6",
];

const imageCache = {};
const loadImage = (url) => {
  var img = imageCache[url];
  if (img) return img;

  const image = new Image();
  if ("fetchPriority" in image) {
    image.fetchPriority = "high";
  }
  const nullImage =
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
  image.onerror = function () {
    this.src = nullImage;
  };
  image.src = url;

  imageCache[url] = image;
  return image;
};

export default function GraphReport(props) {
  const query = useQuery();
  const history = useHistory();
  const [selectedFranchiseId, setSelectedFranchiseId] = useState();
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    history.replace({ search: query.toString() });
  }, [query.toString()]);

  let pricesurveyId = props.match.params.id;
  const { data: suppliers } = useRest(`/api/suppliers`);
  const { data: trademarks } = useRest(`/api/trademarks`);
  const {
    data: item = {
      products: [],
      franchises: [],
      subcategories: [],
    },
    loading: priceSurveyLoading,
  } = useRest(`/api/price-surveys/${pricesurveyId}`);

  const { data: points = [] } = useRest(
    `/api/reports/price_survey_product_report/${pricesurveyId}`
  );

  //Note(ingimar): Hacky way to force rerender after 1.3 seconds
  const forceUpdate = React.useReducer(() => ({}), {})[1];
  useEffect(() => {
    const timerid = setTimeout(() => {
      forceUpdate();
    }, 1300);
    return () => {
      clearTimeout(timerid);
    };
  }, []);

  useEffect(() => {
    if (!selectedFranchiseId && item?.franchises) {
      setSelectedFranchiseId(item.franchises.first()?.id);
    }

    if (!selectedFranchiseId && item.franchises) {
      const franchisesIds = item.franchises.map((f) => f.id);
      const fid = query.get("franchise_id");
      if (fid && franchisesIds.includes(fid)) {
        setSelectedFranchiseId(item.franchises.first()?.id);
      } else {
        setSelectedFranchiseId(franchisesIds.first());
      }
    }
  }, [item]);

  const franchiseData = (points || [])
    .filter((p) => p.avg_price > 0)
    .filter((p) => {
      if (selectedFranchiseId) {
        return p.franchise_id === selectedFranchiseId;
      }
      return true;
    })
    .filter((p) => {
      if (selectedSubcategoryId) {
        return p.sub_category_id === selectedSubcategoryId;
      }
      return true;
    });

  const selectableDates = franchiseData
    .map((p) => p.date)
    .unique()
    .sort()
    .reverse();

  useEffect(() => {
    if (franchiseData && !selectableDates.includes(selectedDate)) {
      const date = selectableDates.first();
      setSelectedDate(date);
    }
  }, [franchiseData]);

  if (item === null) {
    return <FailWhale />;
  }

  const barProps = {
    margin: { top: 80, right: 80, bottom: 100, left: 40 },
    axisBottom: {
      tickRotation: 15,
    },
    padding: 0.5,
    theme: {
      fontSize: 12,
    },
    colors: color_scheme,
    colorBy: "indexValue",

    labelFontSize: 16,
    keys: ["avg_price"],
    indexBy: "product",
    tooltip: ({ indexValue, value, color }) => (
      <div className="p-2 bg-light text-dark">
        <strong>
          Verð - {indexValue}: {value} kr
        </strong>
      </div>
    ),
    layers: [...Bar.canvasDefaultProps.layers, renderAreas],
  };

  const barData = franchiseData
    .filter((p) => {
      if (selectedDate) {
        return p.date === selectedDate;
      }
      return true;
    })
    .map((data) => {
      const product = item.products.find(
        (product) => data.product_id == product.id
      );
      const supplier = suppliers?.find((s) => data.supplier_id == s.id);
      const trademark = trademarks?.find((s) => data.trademark_id == s.id);
      return {
        ...data,
        barImage: product?.logoUrl ? loadImage(product.logoUrl) : null,
        areaImage: trademark?.logoUrl ? loadImage(trademark.logoUrl) : null,
        color: trademark?.color,
        trademarkSortOrder: trademark?.sortPriority || 999,
      };
    })
    .sort((a, b) => {
      if (a.trademark_id !== b.trademark_id) {
        return (
          a.trademarkSortOrder - b.trademarkSortOrder || // 1. sort by custom order
          a.trademark_id.localeCompare(b.trademark_id)
        ); // 2. sort by trademark
      }
      return (
        b.avg_price - a.avg_price || a.product.localeCompare(b.product) // 3. sort by price
      ); // 4. sort by product name
    });

  const selectedFranchise = item.franchises.find(
    (f) => f.id === selectedFranchiseId
  );
  const suppliersIds = barData.map((f) => f.supplier_id).unique();

  if (barData.length === 0) {
    barData.push({
      avg_price: 0,
      product: "",
      franchise: "",
      date: dateFormat(),
    });
  }
  const heighestValue = barData.map((d) => d.avg_price).max();
  const maxValue = Math.ceil((heighestValue * 1.1) / 100) * 100;
  const graphWidth = Math.max(360, barData.length * 140);

  const canRenderChart = points && trademarks && !priceSurveyLoading;

  return (
    <div id="graph-report">
      <div className="sidebar">
        <Card title="Mismunur vara í keðju">
          {selectedFranchise?.logo && (
            <img src={selectedFranchise.logo} className="wid-200 pb-1" />
          )}
          <label>Velja keðju</label>
          <select
            className="form-control"
            onChange={(e) => {
              const franchiseId = e.target.value;
              setSelectedFranchiseId(franchiseId);
              query.set("franchise_id", franchiseId);
            }}
            value={selectedFranchiseId}
          >
            {item.franchises.map((f) => (
              <option key={f.id} value={f.id}>
                {f.name}
              </option>
            ))}
          </select>

          <label>Velja Dagsetningu</label>
          <select
            className="form-control"
            onChange={(e) => setSelectedDate(e.target.value)}
            value={selectedDate}
          >
            {selectableDates.map((date, i) => (
              <option key={"date-" + i} value={date}>
                {dateFormat(date)}
              </option>
            ))}
          </select>

          <label>Velja Undirflokk</label>
          <select
            className="form-control"
            onChange={(e) => setSelectedSubcategoryId(e.target.value)}
            value={selectedSubcategoryId}
          >
            <option value={""}>Allt</option>
            {item.subcategories.map((s) => (
              <option key={s.id} value={s.id}>
                {s.title}
              </option>
            ))}
          </select>
        </Card>
      </div>
      <div className="graph-card-container">
        <Card
          title={<div className="text-right">{dateFormat(selectedDate)}</div>}
        >
          <div
            style={{
              display: "inline-block",
              left: "10px",
              top: "10px",
              float: "left",
              padding: 0,
              position: "absolute",
            }}
          >
            <Button
              onClick={() => history.push(`/price-surveys/${item.id}`)}
              size="sm"
            >
              Til baka
            </Button>
          </div>
          <div className="graph-container">
            <div
              className="arrow arrow-left"
              onClick={() => {
                const i = item.franchises
                  .map("id")
                  .indexOf(selectedFranchiseId);
                const len = item.franchises.length;

                setSelectedFranchiseId(item.franchises[(i - 1 + len) % len].id);
              }}
            >
              <i className="feather f-32 icon-arrow-left" />
            </div>
            <div
              className="arrow arrow-right"
              onClick={() => {
                const i = item.franchises
                  .map("id")
                  .indexOf(selectedFranchiseId);
                const len = item.franchises.length;

                setSelectedFranchiseId(item.franchises[(i + 1) % len].id);
              }}
            >
              <i className="feather f-32 icon-arrow-right" />
            </div>
            <div className="graph">
              {canRenderChart && (
                <Bar.BarCanvas
                  width={graphWidth}
                  height={600}
                  maxValue={maxValue}
                  {...barProps}
                  data={barData}
                  renderBar={renderBar}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
