import React, { Component, useState } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";

import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import NewCategoryModal from "./Categories/NewCategoryModal";

import { gql, useQuery, useMutation } from "@apollo/client";
import { hasData } from "../utils/Graphql";

const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      id
      title
    }
  }
`;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $title: String!) {
    updated: updateCategory(id: $id, title: $title) {
      id
      title
    }
  }
`;

const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($id: ID!) {
    deleted: removeCategory(id: $id) {
      id
      title
    }
  }
`;

function CategoryItem(props) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { item } = props;

  const [remove, { loading }] = useMutation(REMOVE_CATEGORY, {
    variables: { id: item.id },
    optimisticResponse: {
      __typename: "Mutation",
      deleted: {
        id: item.id,
      },
    },
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      const queryResult = cache.readQuery({
        query: GET_CATEGORIES,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_CATEGORIES,
          data: {
            categories: queryResult.categories.filter((item) => item.id !== id),
          },
        });
      }
    },
  });

  return (
    <React.Fragment>
      <tr
        key={"category-" + item.id}
        data-id={item.id}
        onClick={() => {
          setShowUpdate(true);
        }}
      >
        <td className="align-middle">{item.title}</td>
      </tr>
      {/*
        <td className="align-middle">
          <Button size="sm" variant="outline-primary" className="mr-1">
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <UpdateCategoryModal
        item={item}
        show={showUpdate}
        onClose={() => setShowUpdate(false)}
      />
      <Confirm
        show={showConfirmDelete}
        title="Ertu viss?"
        onConfirm={() => {
          remove();
          setShowConfirmDelete(false);
        }}
        onClose={() => {
          setShowConfirmDelete(false);
        }}
      />
      */}
    </React.Fragment>
  );
}

function Categorylist(props) {
  const { loading, error, data } = useQuery(GET_CATEGORIES);

  if (!hasData(data) && loading) {
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  }
  if (error) {
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );
  }

  return data.categories
    .filter(() => true)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => <CategoryItem key={item.id} item={item} />);
}

function UpdateCategoryModal(props) {
  const [title, setTitle] = useState(props.item.title);
  const [muterror, setMuterror] = useState(null);
  const [update, { loading }] = useMutation(UPDATE_CATEGORY);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data, error } = await update({
      variables: { id: props.item.id, title },
    });

    if (error) {
      setError("Failed to update category");
    } else {
      props.onClose();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Uppfæra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function Categories(props) {
  const [showNew, setShowNew] = useState(false);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="Flokkar">
            {/*
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => setShowNew(true)}
            >
              Nýr flokkur
              <i className="fas fa-chevron-right" />
            </Button>
            */}
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Nafn</th>
                </tr>
              </thead>
              <tbody>{<Categorylist />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <NewCategoryModal show={showNew} onClose={() => setShowNew(false)} />
    </React.Fragment>
  );
}

export default Categories;
