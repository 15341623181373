export default {
  items: [
    {
      id: "navigation",
      title: "Valmynd",
      type: "group",
      icon: "fas fa-layout",
      children: [
        {
          id: "overview",
          title: "Yfirlit",
          type: "item",
          url: "/",
          classes: "nav-item",
          icon: "fas fa-home",
        },
        {
          id: "images",
          title: "Myndir",
          type: "item",
          url: "/images",
          classes: "nav-item",
          icon: "fas fa-file-image",
        },
        {
          id: "user-campaign-heatmap",
          title: "Virkni",
          type: "item",
          url: "/user-campaign-heatmap",
          classes: "nav-item",
          icon: "fas fa-users",
        },
        {
          id: "campaigns",
          title: "Herferðir",
          type: "item",
          url: "/campaigns",
          classes: "nav-item",
          icon: "fas fa-chart-bar",
        },
        {
          id: "qa",
          title: "Gæðaeftirlit",
          type: "item",
          url: "/qa",
          classes: "nav-item",
          icon: "fas fa-camera",
        },
        {
          id: "pricesurvey",
          title: "Verðkannanir",
          type: "item",
          url: "/price-surveys",
          classes: "nav-item",
          icon: "fas fa-chart-line",
        },
        {
          id: "shelfanalysis",
          title: "Hillugreiningar",
          type: "item",
          url: "/shelf-analysis",
          classes: "nav-item",
          icon: "fas fa-chart-line",
        },
        {
          id: "tasks",
          title: "Verkefni",
          type: "item",
          url: "/tasks",
          classes: "nav-item",
          icon: "fas fa-list-alt",
        },
        {
          // hidden category for proper breadcrumbs
          id: "items",
          title: "Annað",
          type: "collapse",
          classes: "",
          icon: "fas fa-bars",
          children: [
            {
              id: "franchises",
              title: "Keðjur",
              type: "item",
              url: "/franchises",
              classes: "nav-item",
              icon: "fas fa-shopping-cart",
            },
            {
              id: "categories",
              title: "Flokkar",
              type: "item",
              url: "/categories",
              classes: "nav-item",
              icon: "fas fa-sitemap",
            },
            {
              id: "suppliers",
              title: "Birgjar",
              type: "item",
              url: "/suppliers",
              classes: "nav-item",
              icon: "fas fa-boxes",
            },
            {
              id: "trademarks",
              title: "Vörumerki",
              type: "item",
              url: "/trademarks",
              classes: "nav-item",
              icon: "fas fa-trademark",
            },
            {
              id: "products",
              title: "Vörur",
              type: "item",
              url: "/products",
              classes: "nav-item",
              icon: "fas fa-box",
            },
          ],
        },
        {
          id: "users",
          title: "Notendur",
          type: "item",
          url: "/users",
          classes: "nav-item",
          icon: "fas fa-users",
        },
        {
          // hidden category for proper breadcrumbs
          id: "campaigns-hidden",
          title: "Herferðir",
          type: "collapse",
          url: "/campaigns",
          classes: "hidden",
          children: [
            {
              id: "edit-campaign",
              title: "Breyta herferð",
              type: "item-match",
              url: "/campaigns",
              match: new RegExp("/campaigns/edit/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
            {
              id: "view-campaign",
              title: "Skoða herferð",
              type: "item-match",
              url: "/campaigns",
              match: new RegExp("/campaigns/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
            {
              id: "new-campaign",
              title: "Ný herferð",
              type: "item-match",
              url: "/campaigns/new",
              match: new RegExp("/campaigns/new", "g"),
              classes: "nav-item item-disabled",
            },
          ],
        },
        {
          // hidden category for proper breadcrumbs
          id: "ps-hidden",
          title: "Verðkannanir",
          type: "collapse",
          url: "/price-surveys",
          classes: "hidden",
          children: [
            {
              id: "edit-price-survey",
              title: "Breyta Verðkönnun",
              type: "item-match",
              url: "/price-surveys/edit",
              match: new RegExp("/price-surveys/edit/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
            {
              id: "franchise-graph-price-survey",
              title: "Mismunur vara í keðju",
              type: "item-match",
              url: "/price-surveys/franchise-graph-report",
              match: new RegExp(
                "/price-surveys/franchise-graph-report/[0-9a-z-]+",
                "g"
              ),
              classes: "nav-item item-disabled",
            },
            {
              id: "date-graph-price-survey",
              title: "vöruþróun vöru",
              type: "item-match",
              url: "/price-surveys/date-graph-report",
              match: new RegExp(
                "/price-surveys/date-graph-report/[0-9a-z-]+",
                "g"
              ),
              classes: "nav-item item-disabled",
            },
            {
              id: "product-graph-price-survey",
              title: "Keðjumismunur vöru",
              type: "item-match",
              url: "/price-surveys/product-graph-report",
              match: new RegExp(
                "/price-surveys/product-graph-report/[0-9a-z-]+",
                "g"
              ),
              classes: "nav-item item-disabled",
            },
            {
              id: "view-price-survey",
              title: "Skoða Verðkönnun",
              type: "item-match",
              url: "/price-surveys/",
              match: new RegExp("/price-surveys/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
          ],
        },
        {
          // hidden category for proper breadcrumbs
          id: "qa-hidden",
          title: "Gæðaeftirlit",
          type: "collapse",
          url: "/qa",
          classes: "hidden",
          children: [
            {
              id: "edit-qa",
              title: "Breyta Gæðaeftirlit",
              type: "item-match",
              url: "/qa/edit",
              match: new RegExp("/qa/edit/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
            {
              id: "view-qa",
              title: "Skoða Gæðaeftirlit",
              type: "item-match",
              url: "/qa",
              match: new RegExp("/qa/[0-9a-z-]+", "g"),
              classes: "nav-item item-disabled",
            },
            {
              id: "new-qa",
              title: "Nýtt Gæðaeftirlit",
              type: "item-match",
              url: "/qa/new",
              match: new RegExp("/qa/new", "g"),
              classes: "nav-item item-disabled",
            },
          ],
        },
      ],
    },
  ],
};
