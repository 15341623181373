import React from "react";

import AngledBanner from "./AngledBanner";

function ComingSoon() {
  return (
    <AngledBanner backgroundColor="green" textColor="white">
      Í þróun
    </AngledBanner>
  );
}

export default ComingSoon;
