import React, { Component } from "react";
import { Row, Col, Media, Table, Image, Modal, Form } from "react-bootstrap";

import Card from "../App/components/MainCard";
import GalleryMasonry from "../App/components/GalleryMasonry";
import NoResults from "../App/components/NoResults";

import { gql, useQuery } from "@apollo/client";
import { Query } from "@apollo/client/react/components";

const GET_FRANCHISE = gql`
  query GetFranchise($id: ID!) {
    franchise(id: $id) {
      id
      name
      stores {
        id
        name
      }
    }
  }
`;

class ViewFranchise extends Component {
  constructor(props) {
    super(props);

    let franchiseId = this.props.match.params.franchiseId;

    this.state = {
      franchiseId,
    };
  }

  currencyFormatter = new Intl.NumberFormat("is-IS", {
    style: "currency",
    currency: "ISK",
  });

  render() {
    return (
      <React.Fragment>
        <Query query={GET_FRANCHISE} variables={{ id: this.state.franchiseId }}>
          {({ loading, error, data }) => {
            if (error) {
              return (
                <Row className="justify-content-md-center">
                  <Col xs md="6" xl="4">
                    <Card title="Villa">
                      <h3 className="font-weight-bold text-muted">
                        Engin herferð fannst.
                      </h3>
                    </Card>
                  </Col>
                </Row>
              );
            }

            return (
              <Row>
                <Col
                  xs={12}
                  xl={{ span: 6, offset: 1 }}
                  key={"information-campaign-" + this.state.franchiseId}
                >
                  <Card title="Grunn upplýsingar">
                    <Table responsive size="sm">
                      <tbody>
                        <tr key="info-campaign-title">
                          <th>Heiti Keðju</th>
                          <td className="text-right">
                            {loading ? "..." : data.franchise.name}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                  <Card title="Verslanir">
                    <Table responsive size="sm">
                      <tbody>
                        {loading ? (
                          <tr key="info-campaign-title">
                            <th>Heiti verslunar</th>
                            <td className="text-right">{"..."}</td>
                          </tr>
                        ) : (
                          data.franchise.stores.map((store) => (
                            <tr key="info-campaign-title">
                              <th>Heiti verslunar</th>
                              <td className="text-right">{store.name}</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

export default ViewFranchise;
