import React from "react";

function AngledBanner({ backgroundColor, textColor, children }) {
  if (backgroundColor === undefined) backgroundColor = "green";
  if (textColor === undefined) textColor = white;

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        right: "0",
        width: "170px",
        height: "150px",
        zIndex: "2",
        overflow: "hidden",
      }}
    >
      <span
        style={{
          marginTop: "52px",
          marginLeft: "-2px",
          display: "block",
          color: textColor,
          backgroundColor: backgroundColor,
          height: "30px",
          width: "220px",
          fontSize: "20px",
          textAlign: "center",
          transform: "rotate(45deg)",
          transformOrigin: "center center",
        }}
      >
        {children}
      </span>
    </div>
  );
}

export default AngledBanner;
