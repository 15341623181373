import React, { Component } from "react";
import Select from "react-select";
import { gql, useQuery } from "@apollo/client";

export const GET_TRADEMARKS = gql`
  query GetTrademarks {
    allTrademarks {
      id
      title
    }
  }
`;

function TrademarkSelect(props) {
  const { loading, error, data } = useQuery(GET_TRADEMARKS);
  if (error) return <p>Error...</p>;

  const getOptions = (data) => {
    if (data && data.allTrademarks) {
      return data.allTrademarks.map((cc) => {
        return { value: cc.id, label: cc.title };
      });
    }
    return [];
  };

  const getValue = (data, value) => {
    if (data && data.allTrademarks) {
      const val = data.allTrademarks
        .filter((i) => i)
        .map((cc) => {
          return { value: cc.id, label: cc.title };
        })
        .find((v) => v.value === value);
      return val;
    }
    return null;
  };

  return (
    <React.Fragment>
      <Select
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: "0px",
          }),
        }}
        className="basic-single form-control"
        classNamePrefix="select"
        placeholder={""}
        isLoading={loading}
        value={getValue(data, props.value)}
        onChange={(e) => props.handleSelectUpdate(e, props.name)}
        name={props.name}
        options={getOptions(data)}
      />
    </React.Fragment>
  );
}

export default TrademarkSelect;
