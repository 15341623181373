import React, { Fragment, useState } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import Card from "../App/components/MainCard";
import UserSelect from "./UserSelect";
import KeyHandler, { KEYUP } from "react-key-handler";

import { hasData } from "../utils/Graphql";

const GET_FRANCHISES = gql`
  query GetFranchises {
    franchises {
      id
      name
      logo
      manager {
        id
        name
      }
    }
  }
`;

const UPDATE_FRANCHISE = gql`
  mutation UpdateFranchise($id: ID!, $managerId: ID!) {
    updateFranchise(id: $id, managerId: $managerId) {
      id
      name
      logo
      manager {
        id
        name
      }
    }
  }
`;

function FranchiseItem({ item }) {
  const [editVisible, setVisible] = useState(false);
  const [editManager, setEditManager] = useState(false);
  const [updateFranchise] = useMutation(UPDATE_FRANCHISE);

  return (
    <tr key={"franchise-" + item.id}>
      <td className="align-middle">
        <img
          src={item.logo}
          alt={item.name}
          style={{ maxWidth: 50, maxHeight: 50 }}
        />
      </td>
      <td className="align-middle">{item.name}</td>
      <td
        className="align-middle"
        onMouseOver={() => setVisible(true)}
        onMouseOut={() => setVisible(false)}
        onClick={() => setEditManager(true)}
      >
        {editManager ? (
          <Fragment>
            <UserSelect
              handleSelectUpdate={async (selected) => {
                await updateFranchise({
                  variables: {
                    id: item.id,
                    managerId: selected.value,
                  },
                });
                setEditManager(false);
              }}
              value={item.manager?.id}
            />
            <KeyHandler
              keyEventName={KEYUP}
              code={"Escape"}
              onKeyHandle={() => {
                setEditManager(false);
              }}
            />
          </Fragment>
        ) : (
          <Fragment>
            {item.manager?.name}
            {editVisible && <i className="fa fa-edit ml-1"></i>}
          </Fragment>
        )}
      </td>
      <td className="align-middle"></td>
      <td className="align-middle">
        <Button
          size="sm"
          variant="outline-primary"
          href={"/franchises/view/" + item.id}
        >
          Skoða nánar
          <i className="fas fa-chevron-right" />
        </Button>
      </td>
    </tr>
  );
}

function Franchiselist({ loading, error, data }) {
  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  return data.franchises.map((item) => (
    <FranchiseItem key={"franchise-" + item.id} item={item} />
  ));
}

function Franchises() {
  const result = useQuery(GET_FRANCHISES);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="List yfir keðjur">
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Nafn</th>
                  <th>Ábyrgðarmaður</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{Franchiselist(result)}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Franchises;
