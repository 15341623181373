import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat, fancyDateFormatShort } from "../Formatting";

import { useRest } from "../../utils/Rest";
import Sugar from "sugar";
import FailWhale from "../../components/FailWhale";
import useQuery from "../../utils/useQuery";

import * as Line from "@nivo/line";

const color_scheme = [
  "#2965aa",
  "#a8322f",
  "#87a939",
  "#6e5094",
  "#288da2",
  "#e2df30",
  "#ce2ca8",
  "#d47822",
  "#43c282",
  "#2dc2b6",
];

const imageCache = {};
const loadImage = (url) => {
  var img = imageCache[url];
  if (img) return img;

  const image = new Image();
  const nullImage =
    "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
  image.onerror = function () {
    this.src = nullImage;
  };
  image.src = url;

  imageCache[url] = image;
  return image;
};

export default function DateGraphReport(props) {
  const query = useQuery();
  const history = useHistory();
  const [selectedProductId, setSelectedProductId] = useState();

  useEffect(() => {
    history.replace({ search: query.toString() });
  }, [query.toString()]);

  let pricesurveyId = props.match.params.id;
  const {
    data: item = {
      products: [],
    },
    loading: priceSurveyLoading,
  } = useRest(`/api/price-surveys/${pricesurveyId}`);

  const { data: points = [] } = useRest(
    `/api/reports/price_survey_product_report/${pricesurveyId}`
  );

  const selectableProductIds = points
    .map((p) => p.product_id)
    .unique()
    .sort();

  useEffect(() => {
    if (selectedProductId === undefined && selectableProductIds) {
      const pid = query.get("product_id");
      if (pid && selectableProductIds.includes(pid)) {
        setSelectedProductId(pid);
      } else {
        const id = selectableProductIds.first();
        setSelectedProductId(id);
      }
    }
  }, [item, points]);

  const productData = points
    .filter((p) => {
      if (selectedProductId) {
        return p.product_id === selectedProductId;
      }
      return true;
    })
    .map((p) => {
      const {
        franchise,
        franchise_id,
        avg_price,
        date,
        product,
        product_id,
        ...rest
      } = p;
      return {
        franchise,
        franchise_id,
        avg_price,
        date,
        product,
        product_id,
      };
    });

  if (priceSurveyLoading) {
    //TODO: use loading component
    return null;
  }

  if (item === null) {
    return <FailWhale />;
  }

  const graphProps = {
    margin: { top: 50, right: 100, bottom: 80, left: 40 },
    enableGridX: false,
    //colors: color_scheme,
    colors: (() => {
      let i = 0; //static variable
      return (a, b) => {
        if (a.color) return a.color;
        return color_scheme[i++ % color_scheme.length];
      };
    })(),
    axisBottom: {
      tickRotation: 15,
    },
    padding: 0.5,
    pointSize: 8,
    //debugMesh: true,
    //pointColor: { theme: "background" },
    //pointBorderWidth: 2,
    //pointBorderColor: { theme: "background" },
    //colorBy: "indexValue",
    tooltip: ({ point }) => {
      return (
        <div className="p-2 bg-light text-dark">
          <strong>
            Verð - {point.serieId}: {point.data.yFormatted} kr
          </strong>
        </div>
      );
    },
  };

  const dates = productData
    .map((p) => p.date)
    .unique()
    .sort();

  var graphData = Object.entries(productData.groupBy("franchise_id")).map(
    ([id, group]) => {
      const franchise = item.franchises.find((f) => f.id == id);
      const data = dates
        .map(
          (date) =>
            group.find((g) => g.date == date) ?? {
              avg_price: null,
              date,
            }
        )
        .sortBy("date")
        .map((data, idx, arr) => {
          if (data.avg_price === null) {
            for (var i = idx; 0 <= i; i--) {
              if (arr[i].avg_price !== null) {
                data.avg_price = arr[i].avg_price;
                break;
              }
            }
          }
          return {
            x: fancyDateFormatShort(data.date),
            y: data.avg_price,
          };
        });
      return {
        id: group.first().franchise,
        color: franchise.color,
        data,
      };
    }
  );
  var selectedProduct = item.products.find((p) => p.id == selectedProductId);
  const heighestValue = productData.map((d) => d.avg_price).max();
  const maxValue = Math.ceil((heighestValue * 1.1) / 100) * 100;

  var cardTitle = "Vöruþróun vöru yfir tíma";

  const canRenderChart = points && !priceSurveyLoading;

  return (
    <div id="graph-report">
      <div className="sidebar">
        <Card title={cardTitle}>
          {selectedProduct?.logoUrl && (
            <img src={selectedProduct.logoUrl + "?w=200"} />
          )}

          <label>Velja Vörur</label>
          <select
            className="form-control"
            onChange={(e) => {
              const productId = e.target.value;
              setSelectedProductId(productId);
              query.set("product_id", productId);
            }}
            value={selectedProductId}
          >
            {item.products
              .filter((p) => selectableProductIds.includes(p.id))
              .map((f) => (
                <option key={f.id} value={f.id}>
                  {f.title}
                </option>
              ))}
          </select>
        </Card>
      </div>
      <div className="graph-card-container">
        <Card
          title={
            <div className="text-right">
              {selectedProduct?.title ?? "vantar"}
            </div>
          }
        >
          <div
            style={{
              display: "inline-block",
              left: "10px",
              top: "10px",
              float: "left",
              padding: 0,
              position: "absolute",
            }}
          >
            <Button
              onClick={() => history.push(`/price-surveys/${item.id}`)}
              size="sm"
            >
              Til baka
            </Button>
          </div>
          <div className="graph-container">
            <div
              className="arrow arrow-left"
              onClick={() => {
                const i = selectableProductIds.indexOf(selectedProductId);
                const len = selectableProductIds.length;

                setSelectedProductId(selectableProductIds[(i - 1 + len) % len]);
              }}
            >
              <i className="feather f-32 icon-arrow-left" />
            </div>
            <div
              className="arrow arrow-right"
              onClick={() => {
                const i = selectableProductIds.indexOf(selectedProductId);
                const len = selectableProductIds.length;
                setSelectedProductId(selectableProductIds[(i + 1) % len]);
              }}
            >
              <i className="feather f-32 icon-arrow-right" />
            </div>
            <div className="graph">
              <Line.ResponsiveLineCanvas
                height={600}
                {...graphProps}
                yScale={{
                  type: "linear",
                  max: maxValue,
                  stacked: false,
                }}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: "left-to-right",
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                  },
                ]}
                data={graphData}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
