import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Form, Modal, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import { ResponsivePie } from "@nivo/pie";
import Sugar from "sugar";

const color_scheme = [
  "#2965aa",
  "#a8322f",
  "#87a939",
  "#6e5094",
  "#288da2",
  "#e2df30",
  "#ce2ca8",
  "#d47822",
  "#43c282",
  "#2dc2b6",
];

function PopupPie({ show, onClose, title, ...props }) {
  const popupProps = {
    margin: { top: 50, right: 50, bottom: 50, left: 50 },
    colors: color_scheme,
    arcLinkLabelsDiagonalLength: 30,
    arcLinkLabelsStraightLength: 30,
    valueFormat: (value) => `${value}%`,
    arcLinkLabelsSkipAngle: 0,
    ...props,
  };
  return (
    <Modal show={show} onHide={onClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: "600px" }}>
          <ResponsivePie {...popupProps} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

function RatioPie(props) {
  const commonProps = {
    margin: { top: 24, right: 8, bottom: 25, left: 8 },
    colors: color_scheme,
    labelFontSize: 30,
    valueFormat: (value) => `${value}%`,
    arcLinkLabelsDiagonalLength: 20,
    arcLinkLabelsStraightLength: 24,
    arcLinkLabelsSkipAngle: 8,
    ...props,
  };

  return (
    <div style={{ height: "200px" }}>
      <ResponsivePie {...commonProps} />
    </div>
  );
}

export default function MeasurementsReport(props) {
  const [selectedMeasureId, setSelectedMeasureId] = useState("");
  const [showSupplierPie, setShowSupplierPie] = useState(false);
  const [showTrademarksPie, setShowTrademarksPie] = useState(false);
  const [showMyTrademarksPie, setShowMyTrademarksPie] = useState(false);
  const [sortColumn, setSortColumn] = useState("supplier");
  const [sortOrderDesc, setSortOrder] = useState(true);
  let id = props.match.params.id;
  const { data: item, loading } = useRest(`/api/shelf-analysis/${id}`);
  const { data: points } = useRest(`/api/reports/measurements_report/${id}`);
  const { data: trademarks } = useRest(`/api/trademarks`);

  const selectableMeasurementIds = item?.measures?.map((m) => m.id) ?? [];
  useEffect(() => {
    if (item && item.measures?.length > 0) {
      const measure = item.measures.first();
      setSelectedMeasureId(measure.id);
    }
  }, [item]);

  const Title = (
    <div>
      <div>Mælinga skýrsla</div>
    </div>
  );

  const pointsByMeasureId = (points || []).groupBy("measurementId");

  var totalSlots = (pointsByMeasureId[selectedMeasureId] || [])
    .map((p) => p.slot_count)
    .sum();
  var data = (pointsByMeasureId[selectedMeasureId] || [])
    .map((d) => ({
      ...d,
      ratio: d.slot_count / totalSlots,
    }))
    .sort((a, b) => {
      if (sortColumn == "supplier") {
        if (sortOrderDesc) return b.supplier.localeCompare(a.supplier);
        else return a.supplier.localeCompare(b.supplier);
        //return a.supplier.localeCompare(b.supplier) || a.product.localeCompare(b.product)
      } else if (sortColumn == "product") {
        if (sortOrderDesc) return b.product.localeCompare(a.product);
        else return a.product.localeCompare(b.product);
        //return a.product.localeCompare(b.product) || a.supplier.localeCompare(b.supplier)
      } else if (sortColumn == "count") {
        if (sortOrderDesc) return b.slot_count - a.slot_count;
        else return a.slot_count - b.slot_count;
      } else if (sortColumn == "ratio") {
        if (sortOrderDesc) return b.ratio - a.ratio;
        else return a.ratio - b.ratio;
      } else return 0;
    });
  var totalSlots = (data || []).map((p) => p.slot_count).sum();

  var measure = item?.measures.find((m) => m.id == selectedMeasureId);

  const dataBySupplier = data.groupBy("supplier");
  let supplierData = [];
  for (let supplier in dataBySupplier) {
    const dataGroup = dataBySupplier[supplier];
    const id = supplier === "null" ? "Aðrir" : supplier;
    //const value = dataGroup.map((d) => d.slot_count).sum() / totalSlots;
    const value = (
      (dataGroup.map((d) => d.slot_count).sum() / totalSlots) *
      100
    ).toFixed(0);
    if (value < 1) continue;
    supplierData.push({ id, value });
  }

  const dataByTrademark = data.groupBy("trademark");
  let trademarkData = [];
  for (let trademark in dataByTrademark) {
    const dataGroup = dataByTrademark[trademark];
    const id = trademark === "null" ? "Aðrir" : trademark;
    const value = (
      (dataGroup.map((d) => d.slot_count).sum() / totalSlots) *
      100
    ).toFixed(0);
    if (value < 1) continue;
    trademarkData.push({ id, value });
  }
  trademarkData.sortBy("value");

  const ownedTrademarks = (trademarks || [])
    .filter((t) => t.isMine)
    .map((t) => t.title);
  let ownedData = [];
  if (ownedTrademarks.length > 0) {
    const mine = data
      .filter((d) => ownedTrademarks.includes(d.trademark))
      .map((d) => d.slot_count)
      .sum();
    const other = data
      .filter((d) => !ownedTrademarks.includes(d.trademark))
      .map((d) => d.slot_count)
      .sum();

    const mineP = ((mine / totalSlots) * 100).toFixed(0);
    const otherP = ((other / totalSlots) * 100).toFixed(0);
    ownedData.push({ id: "Mín", value: mineP });
    ownedData.push({ id: "Aðrir", value: otherP });
  }

  return (
    <div className="row">
      <div className="col-12">
        <Card title={Title} className="arrow-container">
          <div
            className="arrow arrow-left"
            onClick={() => {
              const i = selectableMeasurementIds.indexOf(selectedMeasureId);
              const len = selectableMeasurementIds.length;

              setSelectedMeasureId(
                selectableMeasurementIds[(i - 1 + len) % len]
              );
            }}
          >
            <i className="feather f-32 icon-arrow-left" />
          </div>
          <div
            className="arrow arrow-right"
            onClick={() => {
              const i = selectableMeasurementIds.indexOf(selectedMeasureId);
              const len = selectableMeasurementIds.length;
              setSelectedMeasureId(selectableMeasurementIds[(i + 1) % len]);
            }}
          >
            <i className="feather f-32 icon-arrow-right" />
          </div>
          <div className="row" style={{ margin: "0" }}>
            <div className="col-12 col-lg-8">
              {measure && measure.image && (
                <a
                  target={"blank"}
                  href={
                    "https://kurvan-io.imgix.net" + measure.image.bucketPath
                  }
                >
                  <img
                    className="d-block my-1 mx-auto mw-100"
                    src={
                      "https://kurvan-io.imgix.net" +
                      measure.image.bucketPath +
                      "?width=1000"
                    }
                  />
                </a>
              )}
            </div>

            <div className="col-12 col-lg-4">
              <div className="col-12">
                <label htmlFor="measures">Mælingar</label>
                <select
                  className="form-control mb-1"
                  id="measures"
                  value={selectedMeasureId}
                  onChange={(e) => setSelectedMeasureId(e.target.value)}
                >
                  {item?.measures?.map((m) => (
                    <option key={m.id} value={m.id}>
                      {dateFormat(m.date)} - {m.storeArea?.title} -{" "}
                      {m.store?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="mt-1 text-center col-12"
                onClick={() => setShowSupplierPie(true)}
              >
                <label>Birgjar</label>
                <RatioPie data={supplierData} />
              </div>
              <div
                className="mt-1 text-center col-12"
                onClick={(e) => setShowTrademarksPie(true)}
              >
                <label>Vörumerki</label>
                <RatioPie data={trademarkData} />
              </div>
              <div
                className="mt-1 text-center col-12"
                onClick={() => setShowMyTrademarksPie(true)}
              >
                <label>Mín vörumerki</label>
                <RatioPie data={ownedData} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="col-12">
        <Card noHeader>
          <div className="data-container">
            <Table responsive size="xs" className="p-1">
              <thead>
                <tr>
                  <th
                    width="100"
                    className="cursor-pointer"
                    onClick={() => {
                      if (sortColumn != "count") {
                        setSortColumn("count");
                        setSortOrder(true);
                      } else {
                        setSortOrder(!sortOrderDesc);
                      }
                    }}
                  >
                    Fjöldi
                  </th>
                  <th
                    width="100"
                    className="cursor-pointer"
                    onClick={() => {
                      if (sortColumn != "ratio") {
                        setSortColumn("ratio");
                        setSortOrder(true);
                      } else {
                        setSortOrder(!sortOrderDesc);
                      }
                    }}
                  >
                    Hlutfall
                  </th>
                  <th
                    width="200"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (sortColumn != "supplier") {
                        setSortColumn("supplier");
                        setSortOrder(false);
                      } else {
                        setSortOrder(!sortOrderDesc);
                      }
                    }}
                  >
                    Birgjar
                  </th>
                  <th
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (sortColumn != "product") {
                        setSortColumn("product");
                        setSortOrder(false);
                      } else {
                        setSortOrder(!sortOrderDesc);
                      }
                    }}
                  >
                    Vara
                  </th>
                </tr>
              </thead>
              <tbody>
                {(data || []).map((r, i) => (
                  <tr key={i}>
                    <td className="">{r.slot_count}</td>
                    <td>{((r.slot_count / totalSlots) * 100).toFixed(0)}%</td>
                    <td>{r.supplier}</td>
                    <td>{r.product}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </div>
      <PopupPie
        title="Vörumerki"
        show={showTrademarksPie}
        onClose={(e) => setShowTrademarksPie(false)}
        data={trademarkData}
      />
      <PopupPie
        title="Birgjar"
        show={showSupplierPie}
        onClose={() => setShowSupplierPie(false)}
        data={supplierData}
      />
      <PopupPie
        title="Mín vörumerki"
        show={showMyTrademarksPie}
        onClose={() => setShowMyTrademarksPie(false)}
        data={ownedData}
      />
    </div>
  );
}
