import React from "react";

//This needs to be here so parcel can infer the import for the lazy packages....I think
import app from "@apollo/client";

//const HomePage = React.lazy(() => import('./Pages/Home.js'));
//const DashboardPage = React.lazy(() => import('./Pages/Dashboard.js'));
//const CampaignsPage = React.lazy(() => import('./Pages/Campaigns.js'));
//const ViewCampaignPage = React.lazy(() => import('./Pages/ViewCampaign.js'));
//const EditCampaign = React.lazy(() => import('./Pages/EditCampaign.js'));
//const NewCampaign = React.lazy(() => import('./Pages/NewCampaign.js'));

//const UsersPage = React.lazy(() => import('./Pages/Users.js'));
//const CategoriesPage = React.lazy(() => import('./Pages/Categories.js'));
//const SuppliersPage = React.lazy(() => import('./Pages/Suppliers.js'));
//const TrademarksPage = React.lazy(() => import('./Pages/Trademarks.js'));
//const ProductsPage = React.lazy(() => import('./Pages/Products.js'));
//const ImagesPage = React.lazy(() => import('./Pages/Images.js'));
//const FranchisesPage = React.lazy(() => import('./Pages/Franchises.js'));
//const ViewFranchisePage = React.lazy(() => import('./Pages/ViewFranchise.js'));

//const QAPage = React.lazy(() => import('./Pages/QAs.js'));
//const NewQAPage  = React.lazy(() => import('./Pages/NewQA.js'));
//const ViewQAPage  = React.lazy(() => import('./Pages/ViewQA.js'));
//const EditQAPage  = React.lazy(() => import('./Pages/EditQA.js'));

//const TasksPage = React.lazy(() => import('./Pages/Tasks.js'));
//const ViewTaskPage = React.lazy(() => import('./Pages/ViewTask.js'));
//const UserCampaignHeatmap = React.lazy(() => import('./Pages/UserCampaignHeatmap.js'));

import HomePage from "./Pages/Home.js";
import DashboardPage from "./Pages/Dashboard.js";
import CampaignsPage from "./Pages/Campaigns.js";
import ViewCampaignPage from "./Pages/ViewCampaign.js";
import EditCampaign from "./Pages/EditCampaign.js";
import NewCampaign from "./Pages/NewCampaign.js";

import UsersPage from "./Pages/Users.js";
import CategoriesPage from "./Pages/Categories.js";
import SuppliersPage from "./Pages/Suppliers.js";
import TrademarksPage from "./Pages/Trademarks.js";
import ProductsPage from "./Pages/Products.js";
import ImagesPage from "./Pages/Images.js";
import FranchisesPage from "./Pages/Franchises.js";
import ViewFranchisePage from "./Pages/ViewFranchise.js";

import QAPage from "./Pages/QAs.js";
import NewQAPage from "./Pages/NewQA.js";
import ViewQAPage from "./Pages/ViewQA.js";
import EditQAPage from "./Pages/EditQA.js";

import PriceSurveysPage from "./Pages/PriceSurveys.js";
import EditPriceSurveyPage from "./Pages/EditPriceSurvey.js";
import ViewPriceSurveyPage from "./Pages/ViewPriceSurvey.js";

import ShelfAnalysisListPage from "./Pages/ShelfAnalysisList.js";
import EditShelfAnalysisPage from "./Pages/EditShelfAnalysis.js";
import ViewShelfAnalysisPage from "./Pages/ViewShelfAnalysis.js";
import MarketshareReportPage from "./Pages/ShelfAnalysis/MarketshareReport.js";
import MeasurementReportPage from "./Pages/ShelfAnalysis/MeasurementReport.js";
import MeasurementsReportPage from "./Pages/ShelfAnalysis/MeasurementsReport.js";

import TasksPage from "./Pages/Tasks.js";
import ViewTaskPage from "./Pages/ViewTask.js";
import UserCampaignHeatmap from "./Pages/UserCampaignHeatmap.js";

import PriceProductReport from "./Pages/PriceSurveys/Report1.js";
import ProductReportPerFranchise from "./Pages/PriceSurveys/ProductReportPerFranchise.js";
import FranchiseGraphReport from "./Pages/PriceSurveys/FranchiseGraphReport.js";
import ProductGraphReport from "./Pages/PriceSurveys/ProductGraphReport.js";
import DateGraphReport from "./Pages/PriceSurveys/DateGraphReport.js";

const routes = [
  { path: "/", exact: true, name: "Mælaborð", component: DashboardPage },
  {
    path: "/campaigns",
    exact: true,
    name: "Herferðir",
    component: CampaignsPage,
  },
  {
    path: "/campaigns/new",
    exact: true,
    name: "Ný herferð",
    component: NewCampaign,
  },
  {
    path: "/campaigns/:campaignId",
    exact: true,
    name: "Skoða herferð",
    component: ViewCampaignPage,
  },
  {
    path: "/campaigns/edit/:campaignId",
    exact: true,
    name: "Breyta herferð",
    component: EditCampaign,
  },

  { path: "/qa", exact: true, name: "Gæðaeftirlit", component: QAPage },
  {
    path: "/qa/new",
    exact: true,
    name: "Nýtt gæðaeftirlit",
    component: NewQAPage,
  },
  {
    path: "/qa/:qaId",
    exact: true,
    name: "Skoða gæðaeftirlit",
    component: ViewQAPage,
  },
  {
    path: "/qa/edit/:qaId",
    exact: true,
    name: "Breyta gæðaeftirlit",
    component: EditQAPage,
  },
  {
    path: "/shelf-analysis",
    exact: true,
    name: "Hillugreiningar",
    component: ShelfAnalysisListPage,
  },
  {
    path: "/shelf-analysis/new",
    exact: true,
    name: "Ný Hillugreining",
    component: EditShelfAnalysisPage,
  },
  {
    path: "/shelf-analysis/edit/:id",
    exact: true,
    name: "Breyta Hillugreiningu",
    component: EditShelfAnalysisPage,
  },
  {
    path: "/shelf-analysis/:id",
    exact: true,
    name: "Skoða Hillugreiningu",
    component: ViewShelfAnalysisPage,
  },
  {
    path: "/shelf-analysis/marketshare-report/:id",
    exact: true,
    name: "Markaðshlutfalls skýrsla",
    component: MarketshareReportPage,
  },
  {
    path: "/shelf-analysis/measurement-report/:id",
    exact: true,
    name: "Mælingar skýrsla",
    component: MeasurementReportPage,
  },
  {
    path: "/shelf-analysis/measurements-report/:id",
    exact: true,
    name: "Mælinga skýrsla",
    component: MeasurementsReportPage,
  },
  {
    path: "/price-surveys",
    exact: true,
    name: "Verðkannanir",
    component: PriceSurveysPage,
  },
  {
    path: "/price-surveys/new",
    exact: true,
    name: "Ný Verðkönnun",
    component: EditPriceSurveyPage,
  },
  {
    path: "/price-surveys/edit/:pricesurveyId",
    exact: true,
    name: "Breyta Verðkönnun",
    component: EditPriceSurveyPage,
  },
  {
    path: "/price-surveys/:pricesurveyId",
    exact: true,
    name: "Skoða Verðkönnun",
    component: ViewPriceSurveyPage,
  },
  {
    path: "/price-surveys/product-report/:id",
    exact: true,
    name: "Vöruskýrsla",
    component: ProductReportPerFranchise,
  },
  {
    path: "/price-surveys/product-graph-report/:id",
    exact: true,
    name: "Vörugraf skýrsla",
    component: ProductGraphReport,
  },
  {
    path: "/price-surveys/date-graph-report/:id",
    exact: true,
    name: "Vöruþróun vöru",
    component: DateGraphReport,
  },
  {
    path: "/price-surveys/franchise-graph-report/:id",
    exact: true,
    name: "Keðjugraf skýrsla",
    component: FranchiseGraphReport,
  },

  { path: "/users", exact: true, name: "Notendur", component: UsersPage },
  {
    path: "/categories",
    exact: true,
    name: "Flokkar",
    component: CategoriesPage,
  },
  {
    path: "/suppliers",
    exact: true,
    name: "Birgjar",
    component: SuppliersPage,
  },
  {
    path: "/trademarks",
    exact: true,
    name: "Vörumerki",
    component: TrademarksPage,
  },
  { path: "/products", exact: true, name: "Vörur", component: ProductsPage },

  {
    path: "/franchises",
    exact: true,
    name: "Keðja",
    component: FranchisesPage,
  },
  {
    path: "/franchises/view/:franchiseId",
    exact: true,
    name: "Skoða keðju",
    component: ViewFranchisePage,
  },

  { path: "/images", exact: true, name: "Myndir", component: ImagesPage },
  { path: "/tasks", exact: true, name: "Vinnulisti", component: TasksPage },
  {
    path: "/tasks/:id",
    exact: true,
    name: "Vinnulisti",
    component: ViewTaskPage,
  },
  {
    path: "/user-campaign-heatmap",
    exact: true,
    name: "Herferða hitamap",
    component: UserCampaignHeatmap,
  },
];

export default routes;
