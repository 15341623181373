import StackdriverErrorReporter from "stackdriver-errors-js";

const apiKey = "AIzaSyDUR1yl_Yy-cW_hCBXKPe8qxUl-70sXScA";

export const reporter = (version) => {
  let errorHandler;
  if (process.env.NODE_ENV == "production") {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
      key: apiKey,
      projectId: "kurvan-io",
      service: "appkurvan",
      version: "appkurvan-" + version,
    });
  } else {
    errorHandler = { report: console.error };
  }

  return errorHandler;
};
