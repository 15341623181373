import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useMemo,
  memo,
} from "react";

import { Link, useHistory } from "react-router-dom";
import { CloseTask } from "./Tasks";
import {
  Row,
  Col,
  Media,
  Table,
  Image,
  Modal,
  Form,
  Badge,
  Dropdown,
  Card,
} from "react-bootstrap";

import MainCard from "../App/components/MainCard";
import Button from "../components/Button";
import AngledBanner from "../components/AngledBanner";
import { useRest } from "../utils/Rest";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";

import { gql, useQuery, useMutation } from "@apollo/client";
import { useApolloClient } from "@apollo/client";
import UploadProfile from "../components/UploadProfile";

import { ResponsiveLineCanvas } from "@nivo/line";

function Markers({ ctx, lineGenerator, series, points, ...props }) {
  const pointSize = 10;
  const pointBorderWidth = 3;

  lineGenerator.context(ctx);
  series.forEach((serie) => {
    //Marker line
    if (serie.marker) {
      const old = ctx.getLineDash();
      ctx.setLineDash([4]);
      ctx.strokeStyle = serie.marker.lineStyle?.stroke ?? "black";
      ctx.lineWidth = serie.marker.lineStyle?.strokeWidth ?? 1;

      if (serie.marker.axis == "x") {
        const value = props.xScale(serie.marker.value);
        ctx.moveTo(value, 0);
        ctx.lineTo(value, props.innerHeight);
        ctx.stroke();
        if (serie.marker.legend) {
          ctx.font = "20px sans-serif";
          ctx.fillStyle = "black";
          //TODO
          //legendOrientation: 'vertical',
          ctx.fillText(serie.marker.legend, value, props.innerHeight - 20);
          ctx.fillText("testing", 100, 100); //props.innerWidth-20, value);
        }
      } else if (serie.marker.axis == "y") {
        const value = props.yScale(serie.marker.value);
        ctx.moveTo(0, value);
        ctx.lineTo(props.innerWidth, value);
        ctx.stroke();
        if (serie.marker.legend) {
          ctx.font = "20px sans-serif";
          ctx.fillStyle = "black";
          //TODO
          //legendOrientation: 'vertical',
          ctx.fillText(serie.marker.legend, props.innerWidth, value - 5);
        }
      }

      ctx.setLineDash(old);
    }

    ctx.strokeStyle = serie.color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    lineGenerator(serie.data.map((d) => d.position));
    ctx.stroke();

    points
      .filter((p) => p.serieId == serie.id)
      .forEach((point) => {
        const color = serie.marker
          ? serie.marker.value > point.data.y
            ? "red"
            : "green"
          : "gray";
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.arc(point.x, point.y, pointSize / 2, 0, 2 * Math.PI);
        if (pointBorderWidth > 0) {
          ctx.strokeStyle = color;
          ctx.lineWidth = pointBorderWidth;
          ctx.stroke();
        }
      });
  });
}

function LineGraph(props) {
  console.log("linegraph props: ", props);
  const commonOptions = {
    margin: { top: 20, right: 80, left: 60, bottom: 40 },
    //legends: [
    //  {
    //    anchor: 'bottom-right',
    //    direction: 'column',
    //    itemWidth: 80,
    //    itemHeight: 20,
    //  }
    //],

    xScale: {
      type: "time",
      format: "%Y-%m-%dT%H:%M:%S",
      //precision: 'day',
      useUTC: true,
    },
    xFormat: "time:%Y-%m-%d",
    axisBottom: {
      tickRotation: 30,
      format: "%Y-%m-%d",
    },

    yScale: {
      type: "linear",
      max: 60,
    },
    yFormat: ">-.1f",
    axisLeft: {
      format: (value) => value + "%",
    },

    layers: [
      "grid",
      "axes",
      "areas",
      "crosshair",
      //'lines',
      //'points',
      //"slices",
      //"mesh",
      //"legends",
      Markers,
    ],
  };

  return (
    <div style={{ height: "400px" }}>
      <ResponsiveLineCanvas {...{ ...commonOptions, ...props }} />
    </div>
  );
}

function beDate(date) {
  if (typeof date !== Date) {
    return new Date(date);
  }
  return date;
}

function BasicInfoCard(props) {
  const history = useHistory();
  const { loading, data: shelfanalysis, error } = props;

  let dateDiffDays = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
  };

  const buttons = [
    {
      name: "Mælinga skýrsla",
      icon: <i className="fas fa-chevron-right" />,
      size: "sm",
      className: "ml-1",
      // variant="primary",
      disabled: loading,
      title: !shelfanalysis?.hasBeenAccepted
        ? "Verið að bíða eftir staðfestingu!"
        : "",
      onClick: () => {
        if (!loading) {
          history.push(
            `/shelf-analysis/measurements-report/${shelfanalysis?.id}`
          );
        }
      },
    },
  ];
  const dropdownButtons = [
    {
      name: "Breyta",
      icon: <i className="fas fa-chevron-right" />,
      size: "sm",
      className: "ml-1",
      disabled: loading,
      onClick: () => {
        if (!loading) history.push(`/shelf-analysis/edit/${shelfanalysis?.id}`);
      },
    },
    <UploadProfile key={"test"} id={shelfanalysis?.id} />,
  ];
  return (
    <MainCard
      title={loading ? "..." : shelfanalysis.title}
      buttons={buttons}
      dropdownButtons={dropdownButtons}
    >
      {shelfanalysis && !shelfanalysis.hasBeenAccepted && (
        <div className="text-warning">* Verið að bíða eftir staðfestingu!</div>
      )}
      <Row>
        <Col xs={12} md={4}>
          {!loading && shelfanalysis.thumbImageUrl && (
            <img
              style={{
                display: "block",
                width: "150px",
                margin: "0px auto 10px",
              }}
              src={shelfanalysis.thumbImageUrl + "?w=150"}
            />
          )}
        </Col>
        <Col xs={12} md={8}>
          <Table responsive size="sm">
            <tbody>
              <tr key="info-shelfanalysis-date-start">
                <th>Tímabil hefst</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateFormat(new Date(shelfanalysis.dateStart))}
                </td>
              </tr>
              <tr key="info-shelfanalysis-date-end">
                <th>Tímabil lýkur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateFormat(new Date(shelfanalysis.dateEnd))}
                </td>
              </tr>
              <tr key="info-shelfanalysis-days-total">
                <th>Fjöldi daga í tímabili</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateDiffDays(
                        shelfanalysis.dateEnd,
                        shelfanalysis.dateStart
                      )}
                </td>
              </tr>
              {/*
              <tr key="info-shelfanalysis-category">
                <th>Flokkur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : shelfanalysis.filter
                        .filter((f) => f.type == "category")
                        .map((t) => (
                          <Badge
                            className="ml-1"
                            style={{
                              display: "inline-block",
                              float: "left",
                              marginBottom: "5px",
                            }}
                            variant="primary"
                            key={"category-" + t.value}
                          >
                            {t.name}
                          </Badge>
                        ))}
                </td>
              </tr>
              <tr key="info-shelfanalysis-subcategory">
                <th>Undirflokkur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : shelfanalysis.filter
                        .filter((f) => f.type == "sub_category")
                        .map((t) => (
                          <Badge
                            className="ml-1"
                            style={{
                              display: "inline-block",
                              float: "left",
                              marginBottom: "5px",
                            }}
                            variant="primary"
                            key={"subcategory-" + t.value}
                          >
                            {t.name}
                          </Badge>
                        ))}
                </td>
              </tr>
              <tr key="info-shelfanalysis-products">
                <th>Vörur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : shelfanalysis.products.map((product) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"product-" + product.id}
                        >
                          {product.title}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-shelfanalysis-franchises">
                <th>Keðjur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : shelfanalysis.franchises.map((franchise) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"franchise-" + franchise.id}
                        >
                          {franchise.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              {shelfanalysis.hasBeenAccepted && (
                <tr key="info-shelfanalysis-measurements">
                  <th>Mælingar</th>
                  <td className="text-right">
                    {loading
                      ? "..."
                      : shelfanalysis.measures.map((measure) => (
                          <a
                            className="ml-1"
                            style={{
                              display: "inline-block",
                              float: "left",
                              marginBottom: "5px",
                            }}
                            key={"measure-" + measure.id}
                            href={
                              "/shelf-analysis/measurement-report/" + measure.id
                            }
                          >
                            {dateFormat(measure.date)}
                          </a>
                        ))}
                  </td>
                </tr>
              )}
              */}
            </tbody>
          </Table>
        </Col>
      </Row>
    </MainCard>
  );
}

function getSeriesForTrademarks(item, trademarks, points) {
  if (!item || !trademarks || !points) return null;
  console.log("Item: ", item);

  const pointsByMeasureId = points.groupBy("measurementId");

  const data = item.measures
    .map((m) => {
      const data = pointsByMeasureId[m.id] || [];
      const totalSlots = (data || []).map((p) => p.slot_count).sum();
      const mine = data
        .filter((d) => trademarks.includes(d.trademark))
        .map((d) => d.slot_count)
        .sum();

      return { date: m.date, ratio: mine / totalSlots, mine, totalSlots };
    })
    .map((d) => ({ x: d.date, y: d.ratio * 100 }));

  const series = [
    {
      id: "mine",
      marker: {
        axis: "y",
        value: item.shelfRatio,
        lineStyle: { stroke: "#b0413e", strokeWidth: 2 },
        //legend: 'Hlutfall',
        //legendOrientation: 'vertical',
      },
      data,
    },
  ];

  return series;
}

function ViewShelfAnalysis(props) {
  let shelfAnalysisId = props.match.params.id;
  const { loading, error, data } = useRest(
    `/api/shelf-analysis/${shelfAnalysisId}`
  );
  const { data: points } = useRest(
    `/api/reports/measurements_report/${shelfAnalysisId}`
  );
  const { data: trademarks } = useRest(`/api/trademarks`);

  if (!loading && !data) {
    return (
      <Row className="justify-content-md-center">
        <Col xs md="6" xl="4">
          <MainCard title="Villa">
            <h3 className="font-weight-bold text-muted">
              Villa við birtingu vörukönnunar
            </h3>
          </MainCard>
        </Col>
      </Row>
    );
  }

  const ownedTrademarks = (trademarks || [])
    .filter((t) => t.isMine)
    .map((t) => t.title);

  const accepted = data?.hasBeenAccepted ?? false;
  const series = getSeriesForTrademarks(data, ownedTrademarks, points);

  const ratio = data?.shelfRatio;
  const last = series?.last()?.data.last();
  var needsAttention = last && ratio && last.y < ratio;

  return (
    <React.Fragment>
      <Row>
        <Col
          xs={12}
          xl={12}
          key={"information-shelfanalysis-" + shelfAnalysisId}
        >
          <BasicInfoCard loading={loading} data={data} />
        </Col>
        {accepted && series && (
          <Col xs={12} xl={12}>
            <MainCard title="Mín vörumerki">
              {needsAttention && (
                <AngledBanner backgroundColor="red" textColor="white">
                  Athugasemd
                </AngledBanner>
              )}
              <LineGraph
                {...{
                  data: series,
                }}
              />
            </MainCard>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
}

export default ViewShelfAnalysis;
