import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import Sugar from "sugar";
Sugar.extend();

import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import Button from "../components/Button";
import UserSelect from "./UserSelect";
import SelectWithData from "./SelectWithData";
import CategorySelect, { GET_CATEGORIES } from "./CategorySelect";
import TrademarkSelect, { GET_TRADEMARKS } from "./TrademarkSelect";
import ProductSelect, { GET_PRODUCTS } from "./ProductSelect";
import SupplierSelect, { GET_SUPPLIERS } from "./SupplierSelect";
import NewCategoryModal from "./Categories/NewCategoryModal";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import { GET_QAS } from "./QAs";

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      id
      value: id
      label: name
    }
  }
`;

export const GET_FRANCHISES = gql`
  query GetFranchises {
    options: franchises {
      id
      value: id
      label: name
    }
  }
`;

const QAFragment = gql`
  fragment QAFields on QA {
    id
    title
    categoryId
    productId
    trademarkId
    supplierId
    dateStart
    dateEnd
    franchises {
      id
      value: id
      label: name
    }
    stores {
      id
      value: id
      label: name
    }
    manager {
      id
      name
    }
  }
`;

const GET_QA = gql`
  query GetQA($id: ID!) {
    qa(id: $id) {
      ...QAFields
    }
    stores {
      type: __typename
      value: id
      label: name
    }
    franchises {
      type: __typename
      value: id
      label: name
    }
  }
  ${QAFragment}
`;

const DELETE_QA = gql`
  mutation RemoveQA($id: ID!) {
    removeQA(id: $id)
  }
`;

const ADD_CATEGORIES = gql`
  mutation AddCategory($title: String!) {
    addCategory(title: $title) {
      id
      title
    }
  }
`;

const EDIT_TITLE = gql`
  mutation UpdateTitleQA($id: ID!, $value: String!) {
    updateQA(id: $id, title: $value) {
      ...QAFields
    }
  }
  ${QAFragment}
`;

const EDIT_CATEGORY = gql`
  mutation UpdateCategoryQA($id: ID!, $value: ID!) {
    updateQA(id: $id, categoryId: $value) {
      id
    }
  }
`;
const EDIT_PRODUCT = gql`
  mutation UpdateProductQA($id: ID!, $value: ID!) {
    updateQA(id: $id, productId: $value) {
      id
    }
  }
`;
const EDIT_TRADEMARK = gql`
  mutation UpdateTrademarkQA($id: ID!, $value: ID!) {
    updateQA(id: $id, trademarkId: $value) {
      id
    }
  }
`;
const EDIT_SUPPLIER = gql`
  mutation UpdateSupplierQA($id: ID!, $value: ID!) {
    updateQA(id: $id, supplierId: $value) {
      id
    }
  }
`;

const EDIT_USER = gql`
  mutation UpdateCategoryQA($id: ID!, $value: ID!) {
    updateQA(id: $id, managerId: $value) {
      id
    }
  }
`;

const EDIT_DATESTART = gql`
  mutation UpdateDateStartQA($id: ID!, $value: String!) {
    updateQA(id: $id, dateStart: $value) {
      id
    }
  }
`;

const EDIT_DATEEND = gql`
  mutation UpdateDateEndQA($id: ID!, $value: String!) {
    updateQA(id: $id, dateEnd: $value) {
      id
    }
  }
`;

const EDIT_FRANCHISES = gql`
  mutation UpdateFranchisesQA($id: ID!, $value: [ID!]!) {
    updateQA(id: $id, franchises: $value) {
      id
    }
  }
`;

const EDIT_STORES = gql`
  mutation UpdateFranchisesQA($id: ID!, $value: [ID!]!) {
    updateQA(id: $id, stores: $value) {
      id
    }
  }
`;

const onChangeHandler =
  (client, qaId, mutationQuery, name, getFunc, setFunc) => async (e) => {
    var value;
    if (getFunc) {
      value = getFunc(e);
    } else {
      if (e.target === undefined) return;
      value = e.target.value;
    }

    if (value === undefined) return;

    let data = client.readQuery({
      query: GET_QA,
      variables: { id: qaId },
    });
    let mutData = JSON.parse(JSON.stringify(data));
    if (setFunc) {
      Object.set(mutData, `qa.${name}`, setFunc(value, e));
    } else {
      Object.set(mutData, `qa.${name}`, value);
    }
    client.writeQuery({ query: GET_QA, data: mutData });

    await client.mutate({
      mutation: mutationQuery,
      variables: { id: qaId, value },
      update(cache, data) {},
    });
  };

const actionDeleteQA = (client, qaId, setDeleteSate) => async (e) => {
  setDeleteSate(true);
  await client.mutate({
    mutation: DELETE_QA,
    variables: { id: qaId },
    refetchQueries: [GET_QAS],
  });
  window.location = "/qa";
};

const handleFranchisesAndStores =
  (data, client, qaId) => (storesAndFranchises) => {
    const stores = storesAndFranchises.filter((sf) => sf.type === "Store");
    const franchises = storesAndFranchises.filter(
      (sf) => sf.type === "Franchise"
    );

    handleStores(stores);
    handleFranchises(franchises);
  };

function EditTitle(props) {
  const [title, setTitle] = useState(props.title);
  return (
    <Form.Control
      name={"title"}
      type={"text"}
      value={title}
      onBlur={props.onChange}
      onChange={(e) => {
        setTitle(e.target.value);
      }}
    />
  );
}

function EditQA(props) {
  const { loading, data, qaId } = props;
  const client = useApolloClient();

  const [deleteState, setDeleteState] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 5, offset: 1 }}>
          <Card title="Gæðaeftirlit (breyta)">
            <Button
              style={{
                position: "absolute",
                top: "20px",
                right: "110px",
              }}
              size="sm"
              variant="danger"
              disabled={loading && !deleteState}
              onClick={() => {
                setShowConfirmDelete(true);
              }}
            >
              Eyða
              <i className="fas fa-chevron-right" />
            </Button>
            <Button
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
              size="sm"
              variant="primary"
              disabled={loading}
              href={"/qa/" + qaId}
            >
              Til baka
              <i className="fas fa-chevron-right" />
            </Button>
            <Form.Group>
              <Form.Row key={"qa-form-title"}>
                <Form.Label column lg={5}>
                  Heiti herferðar
                </Form.Label>
                <Col>
                  {loading ? (
                    <Form.Control type={"text"} disabled={true} value={"..."} />
                  ) : (
                    <EditTitle
                      title={data.qa.title}
                      onChange={onChangeHandler(
                        client,
                        qaId,
                        EDIT_TITLE,
                        "title"
                      )}
                    />
                  )}
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-supplier"}>
                <Form.Label column lg={5}>
                  Birgi
                </Form.Label>
                <Col>
                  <InputGroup>
                    <SupplierSelect
                      handleSelectUpdate={onChangeHandler(
                        client,
                        qaId,
                        EDIT_SUPPLIER,
                        "supplierId",
                        (e) => e.value
                      )}
                      value={loading ? null : data.qa.supplierId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-trademark"}>
                <Form.Label column lg={5}>
                  Vörumerki
                </Form.Label>
                <Col>
                  <InputGroup>
                    <TrademarkSelect
                      handleSelectUpdate={onChangeHandler(
                        client,
                        qaId,
                        EDIT_TRADEMARK,
                        "trademarkId",
                        (e) => e.value
                      )}
                      value={loading ? null : data.qa.trademarkId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-product"}>
                <Form.Label column lg={5}>
                  Vara
                </Form.Label>
                <Col>
                  <InputGroup>
                    <ProductSelect
                      handleSelectUpdate={onChangeHandler(
                        client,
                        qaId,
                        EDIT_PRODUCT,
                        "productId",
                        (e) => e.value
                      )}
                      value={loading ? null : data.qa.productId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-category"}>
                <Form.Label column lg={5}>
                  Flokkur
                </Form.Label>
                <Col>
                  <InputGroup>
                    <CategorySelect
                      handleSelectUpdate={onChangeHandler(
                        client,
                        qaId,
                        EDIT_CATEGORY,
                        "categoryId",
                        (e) => e.value
                      )}
                      value={loading ? null : data.qa.categoryId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-dateStart"}>
                <Form.Label column lg={5}>
                  Tímabil hefst
                </Form.Label>
                <Col>
                  <DatePicker
                    selected={loading ? undefined : new Date(data.qa.dateStart)}
                    wrapperClassName="w-100"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={onChangeHandler(
                      client,
                      qaId,
                      EDIT_DATESTART,
                      "dateStart",
                      (e) => e.toISOString()
                    )}
                    className="form-control"
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-dateEnd"}>
                <Form.Label column lg={5}>
                  Tímabil lýkur
                </Form.Label>
                <Col>
                  <DatePicker
                    selected={loading ? undefined : new Date(data.qa.dateEnd)}
                    wrapperClassName="w-100"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={onChangeHandler(
                      client,
                      qaId,
                      EDIT_DATEEND,
                      "dateEnd",
                      (e) => e.toISOString()
                    )}
                    className="form-control"
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-manager"}>
                <Form.Label column lg={5}>
                  Ábyrgðarmaður
                </Form.Label>
                <Col>
                  <UserSelect
                    handleSelectUpdate={onChangeHandler(
                      client,
                      qaId,
                      EDIT_USER,
                      "managerId",
                      (e) => e.value
                    )}
                    value={loading ? null : data.qa.manager?.id}
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-franchises"}>
                <Form.Label column lg={5}>
                  Keðjur
                </Form.Label>
                <Col>
                  {(loading ? [] : data.qa.franchises).map((s) => (
                    <ButtonGroup size="sm" className="mr-1 mb-1">
                      <Button
                        variant="primary"
                        key={"franchise-" + s.value}
                        onClick={() => {
                          const update = onChangeHandler(
                            client,
                            qaId,
                            EDIT_FRANCHISES,
                            "franchises",
                            (result) =>
                              (result || []).map((option) => option.value),
                            (val, e) => e || []
                          );
                          const newFranchises = data.qa.franchises.filter(
                            (o) => o.value != s.value
                          );
                          update(newFranchises);
                        }}
                      >
                        {s.label} <i className="fas fa-times" />
                      </Button>
                    </ButtonGroup>
                  ))}
                  <SelectWithData
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    isMulti
                    query={GET_FRANCHISES}
                    placeholder={""}
                    onChange={onChangeHandler(
                      client,
                      qaId,
                      EDIT_FRANCHISES,
                      "franchises",
                      (result) => (result || []).map((option) => option.value),
                      (val, e) => e || []
                    )}
                    value={loading ? undefined : data.qa.franchises}
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"qa-form-stores"}>
                <Form.Label column lg={5}>
                  Verslanir
                </Form.Label>
                <Col>
                  {(loading ? [] : data.qa.stores).map((s) => (
                    <ButtonGroup size="sm" className="mr-1 mb-1">
                      <Button
                        variant="primary"
                        key={"store-" + s.value}
                        onClick={() => {
                          const update = onChangeHandler(
                            client,
                            qaId,
                            EDIT_STORES,
                            "stores",
                            (result) =>
                              (result || []).map((option) => option.value),
                            (val, e) => e || []
                          );
                          const newStores = data.qa.stores.filter(
                            (o) => o.value != s.value
                          );
                          update(newStores);
                        }}
                      >
                        {s.label} <i className="fas fa-times" />
                      </Button>
                    </ButtonGroup>
                  ))}
                  <SelectWithData
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    isMulti
                    query={GET_STORES}
                    placeholder={""}
                    onChange={onChangeHandler(
                      client,
                      qaId,
                      EDIT_STORES,
                      "stores",
                      (result) => (result || []).map((option) => option.value),
                      (val, e) => e || []
                    )}
                    value={loading ? undefined : data.qa.stores}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          </Card>
        </Col>
        <Confirm
          show={showConfirmDelete}
          title="Ertu viss?"
          onConfirm={() => {
            actionDeleteQA(client, qaId, setDeleteState)();
            setShowConfirmDelete(false);
          }}
          onClose={() => {
            setShowConfirmDelete(false);
          }}
        />
      </Row>
    </React.Fragment>
  );
}

function EditQAWithData(props) {
  const qaId = props.match.params.qaId;
  const client = useApolloClient();
  const { loading, error, data } = useQuery(GET_QA, {
    variables: { id: qaId },
    options: {
      fetchPolicy: "cache-first",
    },
  });

  if (error) {
    <React.Fragment>
      <Col>
        <Card title="Grunn upplýsingar">
          <h3>{error.message}</h3>
        </Card>
      </Col>
    </React.Fragment>;
  }

  if (data && data.qa == null) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Villa">
            <h3>Gæðaeftirlit fannst ekki</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  return <EditQA qaId={qaId} loading={loading} data={data} />;
}
export default EditQAWithData;
