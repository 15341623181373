import React, { Component, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";

export const ADD_PRODUCT = gql`
  mutation AddProduct($title: String!) {
    addProduct(title: $title) {
      id
      title
    }
  }
`;

export default function NewProductModal(props) {
  const [title, setTitle] = useState("");
  const [muterror, setMuterror] = useState(null);
  const [addProduct, { loading }] = useMutation(ADD_PRODUCT, {
    refetchQueries: ["GetProducts"],
    update(cache, { data: { addProduct } }) {
      if (props.onAdd) {
        props.onAdd(addProduct, cache);
      }
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await addProduct({ variables: { title } });
    if (error) {
      setMuterror("Failed to create User");
      return;
    }
    if (props.onNew) props.onNew(data.addProduct);

    setTitle("");
    props.onClose();
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Ný vara</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
