import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

function GaugeChart(props) {
  const widthRef = useRef(null);
  const svgRef = useRef();
  var height = props.height || 250;
  var width = props.width || 440;
  const { goal = 2, minGoal = 1, result = 0 } = props;

  const clamp = (value, min, max) => Math.max(min, Math.min(max, value));

  let needleProcentage = clamp(result / (goal + 1) / 2, 0, 1);

  const degToRad = (deg) => {
    return (deg * Math.PI) / 180;
  };
  const percToDeg = (perc) => {
    return perc * 360;
  };
  const percToRad = (perc) => {
    return degToRad(percToDeg(perc));
  };
  const needlePos = (perc, len, radius) => {
    var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
    thetaRad = percToRad(perc / 2);
    centerX = 0;
    centerY = 0;
    topX = centerX - len * Math.cos(thetaRad);
    topY = centerY - len * Math.sin(thetaRad);
    leftX = centerX - radius * Math.cos(thetaRad - Math.PI / 2);
    leftY = centerY - radius * Math.sin(thetaRad - Math.PI / 2);
    rightX = centerX - radius * Math.cos(thetaRad + Math.PI / 2);
    rightY = centerY - radius * Math.sin(thetaRad + Math.PI / 2);
    return (
      "M " +
      leftX +
      " " +
      leftY +
      " L " +
      topX +
      " " +
      topY +
      " L " +
      rightX +
      " " +
      rightY
    );
  };

  useEffect(() => {
    var halfWidth = width / 2;
    const len = halfWidth / 1.2;
    const radius = len / 12;
    const svg = d3
      .select(svgRef.current)
      .append("g")
      .attr("transform", `translate( ${halfWidth},${height * 0.95})`);

    const procent = 1 - minGoal / (goal + 1);
    const YellowAngle = (procent * -Math.PI) / 2;

    const generic_arc = d3
      .arc()
      .innerRadius(halfWidth * 0.8)
      .outerRadius(halfWidth * 0.95);

    // Gauge
    svg
      .append("path")
      .attr("class", "arc")
      .attr("class", "red")
      .attr("d", generic_arc.startAngle(-Math.PI / 2).endAngle(YellowAngle))
      .attr("fill", "hsl(10deg 90% 45%)");
    svg
      .append("path")
      .attr("class", "arc")
      .attr("class", "yellow")
      .attr("d", generic_arc.startAngle(YellowAngle).endAngle(0))
      .attr("fill", "hsl(55deg 90% 55%)");
    svg
      .append("path")
      .attr("class", "arc")
      .attr("class", "green")
      .attr("d", generic_arc.startAngle(0).endAngle(Math.PI / 2))
      .attr("fill", "hsl(88deg 60% 40%)");

    // Needle
    svg
      .append("circle")
      .attr("class", "needle-center")
      .attr("cx", 0)
      .attr("cy", 0)
      .attr("r", radius);
    svg
      .append("path")
      .attr("class", "needle")
      .attr("d", needlePos(needleProcentage, len, radius));

    if (!props.noNumbers) {
      // Labels
      svg
        .append("text")
        .attr("dx", -(halfWidth * 0.94))
        .attr("dy", 20)
        .text("0%");
      svg
        .append("text")
        .attr("dx", -15)
        .attr("dy", -(halfWidth + 4))
        .text("100%");
      svg
        .append("text")
        .attr("dx", halfWidth * 0.8)
        .attr("dy", 20)
        .text("200%");
    }

    return () => {
      svg.remove();
    };
  }, [needleProcentage]);
  //, widthRef.current?.offsetWidth

  return (
    <div ref={widthRef} style={{ width: "100%" }}>
      <div
        style={{ width: width + "px", marginLeft: "auto", marginRight: "auto" }}
      >
        <svg
          style={{ width: "inherit", height: height * 1.05 + "px" }}
          ref={svgRef}
        ></svg>
      </div>
    </div>
  );
}

export default GaugeChart;
