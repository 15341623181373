import Color from "./Color";
import { dateFormat, numberFormat, currencyFormat } from "../Pages/Formatting";

const drawImage = (ctx, image, bar) => {
  if (!ctx) return;
  if (!image) return;
  const max_height = 50;
  const max_w = bar.width - bar.width / 5;
  const aspect_ratio = image.width / image.height;
  const h = Math.min(max_w / aspect_ratio, max_height);
  const w = h * aspect_ratio;
  const imageX = bar.x + (bar.width - w) / 2;
  const imageY = bar.y + 20;

  ctx.drawImage(image, imageX, imageY, w, h);
};
export const renderBar = (ctx, props) => {
  var {
    bar: {
      color,
      height,
      width,
      x,
      y,
      data: { data },
    },
    borderColor,
    borderRadius,
    borderWidth,
    label,
    labelColor,
    shouldRenderLabel,
  } = props;

  height = height || 1;
  const c = data.color || color;
  const newc = Color.shadeColor(c, -25);
  var grd = ctx.createLinearGradient(x, 0, x + width, 0);
  grd.addColorStop(0.0, newc);
  grd.addColorStop(0.1, c);
  grd.addColorStop(0.9, c);
  grd.addColorStop(1, newc);
  ctx.fillStyle = grd;

  ctx.beginPath();

  ctx.rect(x, y, width, height);
  ctx.fill();

  if (height > 2) {
    ctx.save();

    ctx.font = "24px Ariel";
    ctx.textBaseline = "middle";
    ctx.textAlign = "center";
    ctx.fillStyle = labelColor;
    ctx.translate(x + width / 2, y - 12);
    ctx.fillText(numberFormat(label), 0, 0);

    ctx.restore();
  }

  if (height > 20 && data.barImage) {
    const image = data.barImage;
    if (!image.complete) {
      image.onload = () => {
        //drawImage(ctx, image, props.bar);
      };
    } else {
      drawImage(ctx, image, props.bar);
    }
  }
};

export const renderAreas = (ctx, props) => {
  ctx.save();

  ctx.strokeStyle = "#101b33";
  ctx.lineWidth = 2;

  ctx.beginPath();

  const area = Object.values(
    props.bars
      .map((bar) => ({
        x: bar.x,
        y: 10,
        bar_x: bar.x,
        bar_y: bar.y,
        bar_width: bar.width,
        bar_height: bar.height,
        supplier_id: bar.data.data.supplier_id,
        trademark_id: bar.data.data.trademark_id,
        image: bar.data.data.areaImage,
      }))
      .groupBy("trademark_id")
  ).map((a) => {
    const first = a.first();
    const last = a.last();

    return {
      ...first,
      width: last.x + last.bar_width - first.x,
    };
  });

  area.map(({ x, y, width }) => {
    const margin = 3;
    ctx.moveTo(x - margin, y);
    ctx.lineTo(x - margin, -margin);
    ctx.lineTo(x + width + margin, -margin);
    ctx.lineTo(x + width + margin, y);
  });
  ctx.stroke();
  ctx.closePath();

  ctx.beginPath();
  area.map(({ x, y, width, image }) => {
    if (image) {
      const max_height = 60;
      const max_width = 80;
      const aspect_ratio = image.width / image.height;
      const h = Math.min(max_width / aspect_ratio, max_height);
      const w = h * aspect_ratio;

      const imageX = x + (width - w) / 2;
      const imageY = -h - 20;
      ctx.drawImage(image, imageX, imageY, w, h);
    }
  });

  ctx.restore();
};
