import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";

import Card from "../App/components/MainCard";
import { useHistory } from "react-router-dom";

import { gql, useQuery, useMutation } from "@apollo/client";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import Sugar from "sugar";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="justify-content-md-center"></Row>
      </React.Fragment>
    );
  }
}

export default Home;
