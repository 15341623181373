import React from "react";
import styled, { keyframes } from "styled-components";

const bounceLoading = keyframes`
  to {
    opacity: 0.3;
    transform: translate3d(0, -1.0rem, 0);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  height: calc(${(props) => props.size} * 2);
  /*position: fixed;*/
`;

const Dot = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin: ${(props) => props.size} ${(props) => props.margin} 0
    ${(props) => props.margin};
  background: ${(props) => props.background};
  border-radius: 50%;
  animation: ${(props) => props.duration} ${(props) => props.bounceLoading}
    infinite alternate;
  &:nth-child(2n + 0) {
    animation-delay: 0.3s;
  }
  &:nth-child(3n + 0) {
    animation-delay: 0.6s;
  }
`;

export function Loading({ size, margin, background, duration, dots }) {
  //Set defaults
  if (dots === undefined) dots = 3;
  if (size === undefined) size = "1.0rem";
  if (margin === undefined) margin = "0.5rem";
  if (background === undefined) background = "gray";
  if (duration === undefined) duration = "0.8s";

  let dotList = [];
  for (let i = 0; i < dots; i++) {
    dotList.push(i);
  }

  const dotRender = dotList.map((dot) => (
    <Dot
      key={dot}
      {...{
        dots,
        size,
        margin,
        background,
        duration,
      }}
    ></Dot>
  ));

  return <Wrapper size={size}>{dotRender}</Wrapper>;
}
