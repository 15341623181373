import React, { Component, useState } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Link, useLocation } from "react-router-dom";
import { Card, Col, Button, Form, Table, Badge, Modal } from "react-bootstrap";
import KeyHandler, { KEYPRESS, KEYUP } from "react-key-handler";
import GoogleMapReact from "google-map-react";
import CreateTaskButton from "../Pages/CreateTaskModal";
import { CloseTask } from "../Pages/Tasks";

import {
  dateFormat,
  dateTimeFormat,
  currencyFormat,
} from "./../Pages/Formatting";
import {
  getZoomLevel,
  getMapCenter,
  defaultCoords,
  MapMarker,
} from "../utils/GoogleMaps";

const GET_IMAGE = gql`
  query image($id: ID!) {
    image(id: $id) {
      id
      date
      datetimeTaken
      url
      comment

      tags
      needsAttention
      campaign {
        id
        title
      }
      qa {
        id
        title
      }
      suppliers {
        id
        title
      }
      category {
        id
        title
      }
      trademarks {
        id
        title
      }
      products {
        id
        title
      }
      store {
        id
        name
        franchiseId
        gps {
          lat
          lng
        }
      }
      manager {
        id
        name
      }
      linkedTasks {
        id
        title
        closed
      }
    }
  }
`;

const sizeImgUrl = (img) => {
  if (window.innerWidth > window.innerHeight)
    return `${img?.url}?w=${window.innerWidth}&max-h=${window.innerHeight}`;
  return `${img?.url}?min-w=${window.innerWidth}&h=${window.innerHeight}`;
};

function BetterImageViewer(props) {
  const images = props.images || [];
  const startIndex = props.startIndex || 0;

  if (images.length == 0) {
    return null;
  }

  const [imageIdx, setImageIdx] = useState(startIndex);

  const selectedImage = images[imageIdx] || null;
  if (selectedImage == null) {
    return null;
  }

  const { loading, error, data } = useQuery(GET_IMAGE, {
    variables: { id: selectedImage.id },
  });

  const currentImage = data?.image || selectedImage;

  const canSelectNext = () => imageIdx != null && imageIdx < images.length - 1;
  const selectNext = () => {
    if (canSelectNext()) {
      setImageIdx(imageIdx + 1);
    }
  };

  const canSelectPrevious = () => imageIdx != null && imageIdx > 0;
  const selectPrevious = () => {
    if (canSelectPrevious()) {
      setImageIdx(imageIdx - 1);
    }
  };

  const NextArrow = () => {
    const prefetchImages = [
      images[(imageIdx + 1) % images.length],
      images[(imageIdx + 2) % images.length],
      images[(imageIdx + 3) % images.length],
      images[(imageIdx + 4) % images.length],
      images[(imageIdx + 5) % images.length],
    ];
    return (
      <React.Fragment>
        {prefetchImages.map((imgUrl, index) =>
          imgUrl ? (
            <link
              key={index}
              rel="preload"
              as="image"
              href={sizeImgUrl(imgUrl)}
            />
          ) : null
        )}
        <div className="navigation-arrow right" onClick={() => selectNext()}>
          <i className="feather f-32 icon-arrow-right" />
        </div>
        <KeyHandler
          keyEventName={KEYUP}
          code={"ArrowRight"}
          onKeyHandle={selectNext}
        />
      </React.Fragment>
    );
  };

  const PreviousArrow = () => {
    const prefetchImages = [
      images[(imageIdx - 1) % images.length],
      images[(imageIdx - 2) % images.length],
      images[(imageIdx - 3) % images.length],
      images[(imageIdx - 4) % images.length],
      images[(imageIdx - 5) % images.length],
    ];
    return (
      <React.Fragment>
        {prefetchImages.map((imgUrl, index) =>
          imgUrl ? (
            <link
              key={index}
              rel="preload"
              as="image"
              href={sizeImgUrl(imgUrl)}
            />
          ) : null
        )}
        <div className="navigation-arrow left" onClick={selectPrevious}>
          <i className="feather f-32 icon-arrow-left" />
        </div>
        <KeyHandler
          keyEventName={KEYUP}
          code={"ArrowLeft"}
          onKeyHandle={selectPrevious}
        />
      </React.Fragment>
    );
  };

  return (
    props.show && (
      <div className="betterImageViewer">
        <div className="image">
          <div
            className="backdrop"
            style={{
              backgroundImage: `url('${sizeImgUrl(currentImage)}')`,
            }}
          ></div>
          {canSelectPrevious() && <PreviousArrow />}

          <a className="image-wrapper" href={currentImage.url} target="_blank">
            <img src={sizeImgUrl(currentImage)} />
          </a>
          {canSelectNext() && <NextArrow />}
        </div>
        <div className="info">
          <div onClick={props.onClose}>
            <i className="fas fa-times f-24 p-10 pull-right"></i>
            <KeyHandler
              keyEventName={KEYUP}
              code={"Escape"}
              onKeyHandle={props.onClose}
            />
          </div>
          <div className="item-list">
            {currentImage.store && (
              <div className="item">
                <b>Búð</b>
                <br />
                {currentImage.store.name}
              </div>
            )}
            {currentImage.store?.gps && (
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  marginBottom: 10,
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCd0bKIf2AqsdP4jSIyCH3231VGYHjqd6o",
                  }}
                  {...{
                    center: getMapCenter([
                      currentImage.store.gps || defaultCoords,
                    ]),
                    zoom: currentImage.store.gps
                      ? getZoomLevel([currentImage.store.gps], 15)
                      : 6,
                  }}
                >
                  <MapMarker
                    key={currentImage.store.id}
                    {...(currentImage.store.gps || defaultCoords)}
                    text={currentImage.store.name}
                  ></MapMarker>
                </GoogleMapReact>
              </div>
            )}
            <div className="item">
              <b>Mynd tekin</b>
              <br />
              {dateTimeFormat(currentImage.date)}
            </div>
            {currentImage.qa && (
              <div className="item">
                <b>Gæðaeftirlit</b>
                <br />
                {currentImage.qa.title}
              </div>
            )}
            {currentImage.campaign && (
              <div className="item">
                <b>Herferð</b>
                <br />
                {currentImage.campaign.title}
              </div>
            )}
            {currentImage.category && (
              <div className="item">
                <b>Flokkur</b>
                <br />
                <Badge variant="secondary">{currentImage.category.title}</Badge>
              </div>
            )}
            {currentImage.products && (
              <div className="item">
                <b>Vörur</b>
                <br />
                {currentImage.products.map((p, i) => (
                  <Badge
                    key={"badges-product-" + p.id + i}
                    variant="primary"
                    className="mr-1"
                  >
                    {p.title}
                  </Badge>
                ))}
              </div>
            )}
            {currentImage.trademarks && (
              <div className="item">
                <b>Vörumerki</b>
                <br />
                {currentImage.trademarks.map((p, i) => (
                  <Badge
                    key={"badges-trademark-" + p.id + i}
                    variant="success"
                    className="mr-1"
                  >
                    {p.title}
                  </Badge>
                ))}
              </div>
            )}
            {currentImage.suppliers && (
              <div className="item">
                <b>Birgjar</b>
                <br />
                {currentImage.trademarks.map((p, i) => (
                  <Badge
                    key={"badges-supplier-" + p.id + i}
                    variant="danger"
                    className="mr-1"
                  >
                    {p.title}
                  </Badge>
                ))}
              </div>
            )}
            <div className="item">
              <b>Tögg</b>
              <br />
              {(currentImage.tags || []).first(3).map((tag, i) => (
                <Badge
                  key={"badges" + tag + i}
                  variant={"primary"}
                  className="mr-1"
                >
                  {tag}
                </Badge>
              ))}
            </div>
            {(currentImage.comment || currentImage.needsAttention) && (
              <div className="item">
                {currentImage.needsAttention && (
                  <i
                    title="Þarf athygli"
                    className="fas fa-exclamation-circle f-20"
                    style={{ color: "red" }}
                  />
                )}
                &nbsp;
                <b>{currentImage.needsAttention ? "Athugasemd" : "Ummæli"}</b>
                <br />
                {currentImage.comment}
              </div>
            )}
            {currentImage.store && (
              <div className="item">
                <b className="align-middle">Verkefni</b>
                <CreateTaskButton
                  key={currentImage.id}
                  title={`Uppstilling hjá ${currentImage.store.name} þarfnast skoðunar`}
                  description={currentImage.comment}
                  image={currentImage}
                  campaign={currentImage.campaign}
                  qa={currentImage.qa}
                >
                  {(props) => (
                    <Button
                      title="Stofna verkefni út frá mynd"
                      size="sm"
                      variant="primary"
                      className="btn-icon ml-1"
                      style={{
                        width: 25,
                        height: 25,
                        fontSize: `${25 * 0.66}px`,
                      }}
                      onClick={props.onClick}
                    >
                      <i className="fa fa-plus"></i>
                    </Button>
                  )}
                </CreateTaskButton>
              </div>
            )}
            {(currentImage.linkedTasks || []).first(3).map((task, i) => {
              let shortTitle = task.title.substring(0, 40);
              if (shortTitle !== task.title) {
                shortTitle = task.title.substring(0, 40 - 3) + "...";
              }
              return (
                <div key={"task-" + i} className="item" title={task.title}>
                  <CloseTask
                    item={task}
                    style={{
                      display: "inline-block",
                      marginRight: "10px",
                    }}
                  />
                  <Link to={"/tasks/" + task.id}>{shortTitle}</Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    )
  );
}

export default BetterImageViewer;
