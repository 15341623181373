import React, { useState } from "react";
import { Row, Col, Button, Form, Table, Badge } from "react-bootstrap";
import { Get } from "react-axios";

import Select from "react-select";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import Card from "../App/components/MainCard";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import GaugeChart from "./GaugeChart";

import { GET_USERS } from "../GraphqlQueries/Users";
import UserSelect from "./UserSelect";
import CategorySelect from "./CategorySelect";

const GoalUnits = {
  unit: "stk",
  isk: "kr-",
  litre: "lítrar",
};

function beDate(date) {
  if (typeof date !== Date) {
    date = new Date(date);
  }
  return date;
}

function FilterData(campaigns, filter) {
  const now = new Date();
  return (campaigns || [])
    .filter((item) => {
      if (filter.user && filter.user.value !== "") {
        if (item.manager?.id != filter.user.value) {
          return false;
        }
      }
      if (filter.category && filter.category.value !== "") {
        if (item.category?.id != filter.category.value) {
          return false;
        }
      }
      if (filter.status) {
        const start = new Date(item.dateStart);
        const end = new Date(item.dateEnd);
        const active = now > start && now < end;
        if (filter.status.value == "soon" && start < now) return false;
        if (filter.status.value == "active" && !active) return false;
        if (filter.status.value == "done" && now < end) return false;
      }

      return true;
    })
    .sort((a, b) => {
      let x = beDate(a.dateStart);
      let y = beDate(b.dateStart);

      return y - x;
    });
}

function CampaignsList({ filter, data, error, loading }) {
  const readyState = {
    unknown: {
      button: "info",
      icon: "info",
    },
    success: {
      button: "success",
      icon: "check-circle",
    },
    danger: {
      button: "danger",
      icon: "slash",
    },
  };
  const history = useHistory();
  const campaigns = data;

  let listData;

  if (!data && loading) {
    listData = (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  } else if (error) {
    listData = (
      <tr>
        <td>Error...</td>
      </tr>
    );
  } else if (campaigns && campaigns.length < 1) {
    listData = (
      <tr>
        <td>Ekkert fannst</td>
      </tr>
    );
  } else {
    let resultResolver = (item) =>
      item.result !== null
        ? item.result > item.goal
          ? "success"
          : "danger"
        : "unknown";

    let getStatus = (item) => {
      let now = new Date();
      let start = new Date(item.dateStart);
      let end = new Date(item.dateEnd);

      let lock = null;

      if (item.locked) {
        lock = <i className="fas fa-lock" />;
      }

      if (now < start) {
        //Upcoming
        return (
          <Badge variant={"primary"} className="mr-1">
            Væntanlegt {lock}
          </Badge>
        );
      } else if (now < end) {
        //Ongoing
        return (
          <Badge variant={"warning"} className="mr-1">
            Í gangi {lock}
          </Badge>
        );
      } else if (item.stars == null) {
        //Done
        return (
          <Badge variant={"secondary"} className="mr-1">
            Búið {lock}
          </Badge>
        );
      } else if (item.stars > 4) {
        return (
          <Badge variant={"success"} className="mr-1">
            Framúrskarandi {lock}
          </Badge>
        );
      } else if (item.stars > 3) {
        return (
          <Badge variant={"success"} className="mr-1">
            Ágæt {lock}
          </Badge>
        );
      } else if (item.stars > 2) {
        return (
          <Badge variant={"warning"} className="mr-1">
            Hvorki góð né slæm verkefni {lock}
          </Badge>
        );
      } else if (item.stars > 1) {
        return (
          <Badge variant={"danger"} className="mr-1">
            Undir væntingum {lock}
          </Badge>
        );
      } else {
        return (
          <Badge variant={"danger"} className="mr-1">
            Óviðunnandi {lock}
          </Badge>
        );
      }
    };

    const filteredData = FilterData(campaigns, filter);

    listData = filteredData?.map((item) => (
      <tr
        key={"campaign-" + item.id}
        data-id={item.id}
        onClick={() => history.push(`/campaigns/${item.id}`)}
      >
        <td
          className="align-middle"
          style={{
            padding: "0 0.75em",
            textAlign: "center",
          }}
        >
          {!loading && item.campaignImageUrl && (
            <img
              style={{
                maxHeight: "40px",
                maxWidth: "60px",
              }}
              src={item.campaignImageUrl + "?w=60"}
            />
          )}
        </td>
        <td data-test="title" className="align-middle">
          {item.title} ({item.imageCount})
        </td>
        <td data-test="category" className="align-middle">
          {item.category?.title}
        </td>
        <td data-test="status" className="align-middle">
          {getStatus(item)}
        </td>
        <td data-test="dateStartEnd" className="align-middle">
          {dateFormat(new Date(item.dateStart)) +
            " - " +
            dateFormat(new Date(item.dateEnd))}
        </td>
        <td className="align-middle">
          {item.goal} {GoalUnits[item.goalUnit]}
        </td>
        <td className="align-middle" style={{ padding: "5px" }}>
          <GaugeChart
            width={60}
            height={30}
            noNumbers
            goal={item.goal}
            minGoal={item.minimumGoal}
            result={item.result}
          />
        </td>
        <td className="align-middle">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => history.push(`/campaigns/${item.id}`)}
          >
            Opna
            <i className="fas fa-chevron-right" />
          </Button>
        </td>
        <td className="align-middle">
          {item.dateCreated ? dateFormat(new Date(item.dateCreated)) : ""}
        </td>
      </tr>
    ));
  }

  return (
    <Table data-test="campaigns" responsive hover>
      <thead>
        <tr>
          <th></th>
          <th>Heiti herferðar</th>
          <th>Flokkur</th>
          <th>Staða</th>
          <th>Herferð í gangi</th>
          <th>Markmið</th>
          <th>Árangur</th>
          <th>Niðurstaða</th>
          <th>Stofnað</th>
        </tr>
      </thead>
      <tbody>{listData}</tbody>
    </Table>
  );
}

const availableStatuses = [
  { value: "", label: "Staða allt" },
  { value: "soon", label: "Væntanlegt" },
  { value: "active", label: "Í gangi" },
  { value: "done", label: "Búið" },
];

export default function Campaigns() {
  const history = useHistory();
  const [filter, setFilter] = useState({
    category: "",
    user: "",
    status: availableStatuses[2],
  });

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card noHeader>
            <Row>
              <Col xs={12} md={12}>
                <h5>Leit</h5>
              </Col>
              <Col xs={12} md={3} key="search-category">
                <CategorySelect
                  placeholder="Allir flokkar"
                  extraOptions={[{ value: "", label: "Allir flokkar" }]}
                  value={filter.category?.value || ""}
                  handleSelectUpdate={(e) => {
                    setFilter({
                      ...filter,
                      category: e,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={3} key="search-user">
                <UserSelect
                  placeholder="Allir ábyrðaraðilar"
                  extraOptions={[{ value: "", label: "Allir ábyrðaraðilar" }]}
                  value={filter.user?.value || ""}
                  handleSelectUpdate={(e) => {
                    setFilter({
                      ...filter,
                      user: e,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={3}>
                <Select
                  id="statusFilter"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0px",
                    }),
                  }}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder={"Staða"}
                  key={filter.status}
                  value={filter.status}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      status: e,
                    })
                  }
                  options={availableStatuses}
                />
              </Col>
            </Row>
          </Card>
          <Card title="Herferðir">
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => history.push("/campaigns/new")}
            >
              Ný herferð
              <i className="fas fa-chevron-right" />
            </Button>

            <Get url="/Api/CampaignList">
              {(error, response, isLoading, makeRequest, axios) => (
                <CampaignsList
                  filter={filter}
                  data={response?.data}
                  loading={isLoading}
                  error={error}
                />
              )}
            </Get>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
