import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Alert } from "react-bootstrap";

import Card from "../App/components/MainCard";
import UserProfileImage from "../components/UserProfileImage";

import NewUserModal from "./Users/NewUserModal";
import EditUserModal from "./Users/EditUserModal";

import { gql, useQuery, useMutation } from "@apollo/client";
import { GET_USERS } from "../GraphqlQueries/Users";

const SEND_RESET_EMAIL = gql`
  mutation SendResetEmail($userId: ID!) {
    sendResetPassword(userId: $userId)
  }
`;

function UserRow({ user, setAlert, setUserEditModal }) {
  const [sendResetPassword, { loading: sendingReset }] =
    useMutation(SEND_RESET_EMAIL);

  return (
    <tr key={user.id} data-id={user.id}>
      <td width={40}>
        <UserProfileImage user={user} />
      </td>
      <td className="align-middle">{user.name}</td>
      <td className="align-middle">{user.email}</td>
      <td className="align-middle">{user.phone}</td>
      <td className="align-middle">
        <Button
          title="Breyta"
          size="sm"
          variant="outline-primary"
          onClick={(e) => setUserEditModal(user)}
        >
          <i className="fas fa-edit" />
        </Button>
        <Button
          title="Senda tölvupóst til að endurstilla lykilorð"
          disabled={sendingReset}
          size="sm"
          variant="outline-primary"
          className="ml-1"
          onClick={async () => {
            var result = await sendResetPassword({
              variables: { userId: user.id },
            });

            if (result.data?.sendResetPassword) {
              setAlert({
                type: "success",
                title: "Sending tókst",
                message: `Póstur hefur verið sendur á ${user.email}`,
              });
            } else {
              setAlert({
                type: "warning",
                title: "Sending mistókst",
                message: "Lenti í vandræðum að senda póstinn",
              });
            }
          }}
        >
          {sendingReset ? (
            <i className="fas fa-redo fa-spin" />
          ) : (
            <i className="fas fa-redo" />
          )}
        </Button>
      </td>
    </tr>
  );
}

function Users(props) {
  const { data, refetch } = useQuery(GET_USERS);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [userEditModal, setUserEditModal] = useState(null);
  const [newUserLoading, setNewUserLoading] = useState(false);

  const [showAlert, setAlert] = useState(undefined);

  useEffect(() => {
    setNewUserLoading(false);
  }, [data]);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          {showAlert && (
            <Alert
              style={{
                position: "fixed",
                top: "10px",
                right: "10px",
                zIndex: "2000",
              }}
              variant={showAlert.type}
              onClose={() => setAlert(undefined)}
              dismissible
            >
              <Alert.Heading>{showAlert.title}</Alert.Heading>
              <p>{showAlert.message}</p>
            </Alert>
          )}
          <Card title="Notendur">
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => setShowNewUserModal(true)}
            >
              Nýr notandi
              <i className="fas fa-chevron-right" />
            </Button>
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Nafn</th>
                  <th>Netfang</th>
                  <th>Sími</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(data?.users || []).map((user) => (
                  <UserRow
                    key={user.id}
                    {...{
                      user,
                      setAlert,
                      setUserEditModal,
                    }}
                  />
                ))}
                {newUserLoading && (
                  <tr key={"loading"}>
                    <td width={40}>
                      <UserProfileImage user={undefined} />
                    </td>
                    <td className="align-middle">...</td>
                    <td className="align-middle">...</td>
                    <td className="align-middle">..</td>
                    <td className="align-middle"></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <NewUserModal
        key={data?.users?.length}
        show={showNewUserModal}
        onClose={(isNewUser) => {
          setShowNewUserModal(false);
          if (isNewUser === true) {
            setNewUserLoading(true);
          }
          refetch();
        }}
      />
      {userEditModal && (
        <EditUserModal
          key={userEditModal.id}
          show={true}
          user={userEditModal}
          onClose={() => setUserEditModal(null)}
        />
      )}
    </React.Fragment>
  );
}

export default Users;
