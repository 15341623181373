import React, { useRef } from "react";
import { Dropdown } from "react-bootstrap";

export default function UploadProfileButton(props) {
  const inputFile = useRef(null);

  const Submit = (file, projectId) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("projectId", projectId);

    fetch("/upload/profile-project", {
      method: "POST",
      body: formData,
    }).then((r) => {
      console.log(r);
    });
  };

  return (
    <React.Fragment>
      <Dropdown.Item
        as={props.as || "li"}
        className={props.className}
        style={props.style}
        disabled={props.locked}
        onClick={() => {
          inputFile.current.click();
        }}
      >
        <i className="fas fa-upload pl-1" />
        <a href="#"> Profile mynd </a>
      </Dropdown.Item>

      <input
        ref={inputFile}
        style={{ display: "none" }}
        id="upload"
        type="file"
        accept="image/*"
        onChange={(e) => {
          Submit(e.target.files[0], props.id);
        }}
      />
    </React.Fragment>
  );
}
