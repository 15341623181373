import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Media,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { Mutation } from "@apollo/client/react/components";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";

import UserSelect from "./UserSelect";
import CategorySelect, { GET_CATEGORIES } from "./CategorySelect";
import TrademarkSelect, { GET_TRADEMARKS } from "./TrademarkSelect";
import ProductSelect, { GET_PRODUCTS } from "./ProductSelect";
import SupplierSelect, { GET_SUPPLIERS } from "./SupplierSelect";
import NewCategoryModal from "./Categories/NewCategoryModal";
import NewProductModal from "./NewProductModal";
import NewTrademarkModal from "./NewTrademarkModal";
import NewSupplierModal from "./NewSupplierModal";
import { UserContext } from "./../UserContext";

import Card from "../App/components/MainCard";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import SelectWithData from "./SelectWithData";
import { GET_CAMPAIGNS } from "./Campaigns";

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      value: id
      label: name
    }
  }
`;

export const GET_FRANCHISES = gql`
  query GetFranchises {
    options: franchises {
      value: id
      label: name
    }
  }
`;

const ADD_CAMPAIGN = gql`
  mutation AddCampaign(
    $title: String!
    $categoryId: ID!
    $supplierId: ID
    $productId: ID
    $trademarkId: ID
    $managerId: ID!
    $dateStart: String!
    $dateEnd: String!
    $goal: Float!
    $minimumGoal: Float!
    $goalUnit: String!
    $costsExpected: CostsInput
    $franchises: [ID!]
    $stores: [ID!]
    $comment: String
    $requestService: String
  ) {
    addCampaign(
      title: $title
      categoryId: $categoryId
      supplierId: $supplierId
      productId: $productId
      trademarkId: $trademarkId
      managerId: $managerId
      dateStart: $dateStart
      dateEnd: $dateEnd
      goal: $goal
      minimumGoal: $minimumGoal
      goalUnit: $goalUnit
      franchises: $franchises
      stores: $stores
      costsExpected: $costsExpected
      comment: $comment
      requestService: $requestService
    ) {
      id
      title
    }
  }
`;

const GoalUnits = [
  { value: "unit", label: "stk" },
  { value: "isk", label: "kr-" },
  { value: "litre", label: "lítrar" },
];

function NewCampaign(props) {
  const [state, setState] = useState({
    submitting: false,
    error: "",
    form: {
      title: "",
      categoryId: undefined,
      managerId: undefined,
      productId: undefined,
      trademarkId: undefined,
      supplierId: undefined,
      dateStart: new Date(),
      dateEnd: new Date().addMonths(1),
      goal: undefined,
      minimumGoal: undefined,
      goalUnit: GoalUnits[0],
      franchises: [],
      stores: [],
      costsExpected: {
        production: undefined,
        radio: undefined,
        television: undefined,
        internet: undefined,
        print: undefined,
        franchise: undefined,
        other: undefined,
      },
      requestService: "",
      comment: "",
    },
    showCategoryModal: false,
    showSupplierModal: false,
    showProductModal: false,
    showTrademarkModal: false,
    costTotal: 0,
  });

  const [feedback, setFeedback] = useState("");

  const handleState = (newState) => {
    setFeedback("");
    setState((state) => ({ ...state, ...newState }));
  };

  const handleInputCosts = (value, name) => {
    handleState({
      form: {
        ...state.form,
        costsExpected: {
          ...state.form.costsExpected,
          [name]: value,
        },
      },
    });
  };

  const handleUpdate = (value, name) => {
    handleState({
      form: {
        ...state.form,
        [name]: value,
      },
    });
  };

  const handleInputUpdate = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.type === "number") {
      value = parseFloat(value);
    }

    handleUpdate(value, name);
  };

  const handleSelectUpdate = (e, name) => {
    handleUpdate(e.value, name);
  };

  const handleSubmit = (addCampaign, client) => async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.checkValidity();

    if (state.form.title == "") {
      setFeedback("Þarf að gefa heiti");
      return;
    }
    if (state.form.categoryId == undefined) {
      setFeedback("Þarf að velja flokk");
      return;
    }

    if (state.form.managerId == undefined) {
      setFeedback("Þarf að velja ábyrgðarmann");
      return;
    }

    try {
      handleState({ submitting: true });
      const { data, error } = await addCampaign({
        variables: {
          title: state.form.title,
          categoryId: state.form.categoryId,
          trademarkId: state.form.trademarkId,
          productId: state.form.productId,
          supplierId: state.form.supplierId,
          managerId: state.form.managerId,
          dateStart: state.form.dateStart.toISOString(),
          dateEnd: state.form.dateEnd.toISOString(),
          goal: state.form.goal || 0,
          minimumGoal: state.form.minimumGoal || 0,
          goalUnit: state.form.goalUnit.value,
          costsExpected: state.form.costsExpected,
          franchises: state.form.franchises.map((i) => i.value),
          stores: state.form.stores.map((i) => i.value),
          comment: state.form.comment,
          requestService: state.form.requestService,
        },
      });
      handleState({ submitting: false });
      props.history.push(`/campaigns`);
    } catch (error) {
      handleState({ submitting: false, error: error.message });
    }
  };

  const { submitting } = state;
  const client = useApolloClient();

  if (state.error) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Grunn upplýsingar">
            <h3>{state.error}</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  let costExpect = Object.sum(state.form.costsExpected, function (i) {
    if (!Object.isNumber(i)) return 0;
    return i;
  });

  return (
    <React.Fragment>
      <Mutation mutation={ADD_CAMPAIGN}>
        {(addCampaign) => (
          <Form onSubmit={handleSubmit(addCampaign, client)}>
            <Row>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                xl={{ span: 5, offset: 1 }}
              >
                <Card title="Grunn upplýsingar">
                  {feedback && <div style={{ color: "red" }}>{feedback}</div>}
                  <Form.Group>
                    <Form.Row key={"campaign-form-title"}>
                      <Form.Label column lg={5}>
                        Heiti herferðar
                      </Form.Label>
                      <Col>
                        <Form.Control
                          required
                          name={"title"}
                          type={"text"}
                          onChange={handleInputUpdate}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-supplier"}>
                      <Form.Label column lg={5}>
                        Birgi
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <SupplierSelect
                            required={true}
                            name={"supplierId"}
                            handleSelectUpdate={handleSelectUpdate}
                            value={state.form.supplierId}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showSupplierModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-trademark"}>
                      <Form.Label column lg={5}>
                        Vörumerki
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <TrademarkSelect
                            required={true}
                            name={"trademarkId"}
                            handleSelectUpdate={handleSelectUpdate}
                            value={state.form.trademarkId}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showTrademarkModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-product"}>
                      <Form.Label column lg={5}>
                        Vara
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <ProductSelect
                            required={true}
                            name={"productId"}
                            handleSelectUpdate={handleSelectUpdate}
                            value={state.form.productId}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showProductModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-category"}>
                      <Form.Label column lg={5}>
                        Flokkur
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <CategorySelect
                            id="categorySelect"
                            required={true}
                            name={"categoryId"}
                            handleSelectUpdate={handleSelectUpdate}
                            value={state.form.categoryId}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showCategoryModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-dateStart"}>
                      <Form.Label column lg={5}>
                        Herferð hefst
                      </Form.Label>
                      <Col>
                        <DatePicker
                          selected={state.form.dateStart}
                          wrapperClassName="w-100"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={(date) => handleUpdate(date, "dateStart")}
                          className="form-control"
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-dateEnd"}>
                      <Form.Label column lg={5}>
                        Herferð lýkur
                      </Form.Label>
                      <Col>
                        <DatePicker
                          selected={state.form.dateEnd}
                          wrapperClassName="w-100"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={(date) => handleUpdate(date, "dateEnd")}
                          className="form-control"
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-goal"}>
                      <Form.Label column lg={5}>
                        Sölumarkmið
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.goal}
                            onValueChange={(e) =>
                              handleUpdate(e.floatValue, "goal")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <Select
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "90px",
                                  borderRadius: "0px",
                                }),
                              }}
                              name={"goalUnit"}
                              className="basic-single form-control"
                              classNamePrefix="select"
                              onChange={(e) => handleUpdate(e, "goalUnit")}
                              options={GoalUnits}
                              value={state.form.goalUnit}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-minimum-goal"}>
                      <Form.Label column lg={5}>
                        Lámarks sölumarkmið
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.minimumGoal}
                            onValueChange={(e) =>
                              handleUpdate(e.floatValue, "minimumGoal")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <Select
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "90px",
                                  borderRadius: "0px",
                                }),
                              }}
                              name={"goalUnit"}
                              className="basic-single form-control"
                              classNamePrefix="select"
                              onChange={(e) => handleUpdate(e, "goalUnit")}
                              options={GoalUnits}
                              value={state.form.goalUnit}
                            />
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-manager"}>
                      <Form.Label column lg={5}>
                        Ábyrgðarmaður
                      </Form.Label>
                      <Col>
                        <UserSelect
                          id="managerSelect"
                          name={"managerId"}
                          handleSelectUpdate={handleSelectUpdate}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-franchises"}>
                      <Form.Label column lg={5}>
                        Keðjur
                      </Form.Label>
                      <Col>
                        {state.form.franchises.map((s) => (
                          <ButtonGroup size="sm" className="mr-1 mb-1">
                            <Button
                              variant="primary"
                              key={"franchise-" + s.value}
                              onClick={() => {
                                const newFranchises =
                                  state.form.franchises.filter(
                                    (o) => o.value != s.value
                                  );
                                handleUpdate(newFranchises, "franchises");
                              }}
                            >
                              {s.label} <i className="fas fa-times" />
                            </Button>
                          </ButtonGroup>
                        ))}
                        <SelectWithData
                          closeMenuOnSelect={false}
                          controlShouldRenderValue={false}
                          isMulti
                          query={GET_FRANCHISES}
                          placeholder={""}
                          onChange={(e) => handleUpdate(e, "franchises")}
                          value={state.form.franchises}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"campaign-form-stores"}>
                      <Form.Label column lg={5}>
                        Verslanir
                      </Form.Label>
                      <Col>
                        {state.form.stores.map((s) => (
                          <ButtonGroup size="sm" className="mr-1 mb-1">
                            <Button
                              variant="primary"
                              key={"store-" + s.value}
                              onClick={() => {
                                const newStores = state.form.stores.filter(
                                  (o) => o.value != s.value
                                );
                                handleUpdate(newStores, "stores");
                              }}
                            >
                              {s.label} <i className="fas fa-times" />
                            </Button>
                          </ButtonGroup>
                        ))}
                        <SelectWithData
                          closeMenuOnSelect={false}
                          controlShouldRenderValue={false}
                          isMulti
                          query={GET_STORES}
                          placeholder={""}
                          onChange={(e) => handleUpdate(e, "stores")}
                          value={state.form.stores}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Card>
              </Col>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                xl={{ span: 5, offset: 0 }}
              >
                <Card title="Áætlaður kostnaður">
                  <Form.Group>
                    <Form.Row key={"campaign-form-cc-production"}>
                      <Form.Label column lg={5}>
                        Framleiðsla efnis
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.production}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "production")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-radio"}>
                      <Form.Label column lg={5}>
                        Útvarp
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.radio}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "radio")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-television"}>
                      <Form.Label column lg={5}>
                        Sjónvarp
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.television}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "television")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-internet"}>
                      <Form.Label column lg={5}>
                        Netmiðlar
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.internet}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "internet")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-print"}>
                      <Form.Label column lg={5}>
                        Prentmiðlar
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.print}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "print")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-franchise"}>
                      <Form.Label column lg={5}>
                        Markaðsfé til verslunar
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.franchise}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "franchise")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key={"campaign-form-cc-other"}>
                      <Form.Label column lg={5}>
                        Annar kostnaður
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <NumberFormat
                            value={state.form.costsExpected.other}
                            onValueChange={(e) =>
                              handleInputCosts(e.floatValue, "other")
                            }
                            thousandSeparator="."
                            decimalSeparator=","
                            className="form-control"
                            allowNegative={false}
                          />
                          <InputGroup.Append>
                            <InputGroup.Text>ISK</InputGroup.Text>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>
                    <Form.Row key="campaign-form-cost-total">
                      <Form.Label column lg={5}>
                        Heildarkostnaður
                      </Form.Label>
                      <Form.Label className="col-form-label">
                        {currencyFormat(costExpect)}
                      </Form.Label>
                    </Form.Row>
                  </Form.Group>
                </Card>
              </Col>
              <Col
                xs={12}
                lg={{ span: 8, offset: 2 }}
                xl={{ span: 10, offset: 1 }}
              >
                <Card title="Óska eftir þjónustu">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      name="requestService"
                      onChange={handleInputUpdate}
                      rows={3}
                    />
                  </Form.Group>
                </Card>

                <Card title="Athugasemdir">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      name="comment"
                      onChange={handleInputUpdate}
                      rows={3}
                    />
                  </Form.Group>
                </Card>
                <Card noHeader>
                  <div className="text-right">
                    <button
                      data-test="submit"
                      type="submit"
                      disabled={submitting}
                      className="btn btn-success"
                    >
                      Áfram
                    </button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Mutation>
      <NewCategoryModal
        show={state.showCategoryModal}
        onClose={() => handleState({ showCategoryModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => {
          handleUpdate(data.id, "categoryId");
        }}
      />
      <NewProductModal
        show={state.showProductModal}
        onClose={() => handleState({ showProductModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => {
          handleUpdate(data.id, "productId");
        }}
      />
      <NewTrademarkModal
        show={state.showTrademarkModal}
        onClose={() => handleState({ showTrademarkModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => {
          handleUpdate(data.id, "trademarkId");
        }}
      />
      <NewSupplierModal
        show={state.showSupplierModal}
        onClose={() => handleState({ showSupplierModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => {
          handleUpdate(data.id, "supplierId");
        }}
      />
    </React.Fragment>
  );
}

export default NewCampaign;
