import React, { Component, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Media,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { Mutation } from "@apollo/client/react/components";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";

import UserSelect from "./UserSelect";
import CategorySelect, { GET_CATEGORIES } from "./CategorySelect";
import TrademarkSelect, { GET_TRADEMARKS } from "./TrademarkSelect";
import ProductSelect, { GET_PRODUCTS } from "./ProductSelect";
import SupplierSelect, { GET_SUPPLIERS } from "./SupplierSelect";
import NewCategoryModal from "./Categories/NewCategoryModal";
import NewProductModal from "./NewProductModal";
import NewTrademarkModal from "./NewTrademarkModal";
import NewSupplierModal from "./NewSupplierModal";
import { UserContext } from "./../UserContext";

import Card from "../App/components/MainCard";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import SelectWithData from "./SelectWithData";

const GET_QAS = gql`
  query GetQA {
    allQA {
      id
      title
      profileImageUrl
      category {
        id
        title
      }
      manager {
        id
        name
      }
      dateStart
      dateEnd
      locked
    }
  }
`;

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      value: id
      label: name
    }
  }
`;

export const GET_FRANCHISES = gql`
  query GetFranchises {
    options: franchises {
      value: id
      label: name
    }
  }
`;

const ADD_QA = gql`
  mutation AddQA(
    $title: String!
    $categoryId: ID!
    $supplierId: ID
    $productId: ID
    $trademarkId: ID
    $managerId: ID!
    $dateStart: String!
    $dateEnd: String!
    $franchises: [ID!]
    $stores: [ID!]
    $comment: String
    $requestService: String
  ) {
    addQA(
      title: $title
      categoryId: $categoryId
      supplierId: $supplierId
      productId: $productId
      trademarkId: $trademarkId
      managerId: $managerId
      dateStart: $dateStart
      dateEnd: $dateEnd
      franchises: $franchises
      stores: $stores
      comment: $comment
      requestService: $requestService
    ) {
      id
      title
    }
  }
`;

function NewQA(props) {
  const [state, setState] = useState({
    submitting: false,
    error: "",
    form: {
      title: "",
      categoryId: undefined,
      managerId: undefined,
      productId: undefined,
      trademarkId: undefined,
      supplierId: undefined,
      dateStart: new Date(),
      dateEnd: new Date(),
      franchises: [],
      stores: [],
      requestService: "",
      comment: "",
    },
    showCategoryModal: false,
    showProductModal: false,
    showTrademarkModal: false,
    showSupplierModal: false,
  });
  const [feedback, setFeedback] = useState("");

  const handleState = (newState) => {
    setFeedback("");
    setState((state) => ({ ...state, ...newState }));
  };

  const handleUpdate = (value, name) => {
    handleState({
      form: {
        ...state.form,
        [name]: value,
      },
    });
  };

  const handleInputUpdate = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.type === "number") {
      value = parseFloat(value);
    }

    handleUpdate(value, name);
  };

  const handleSelectUpdate = (e, name) => {
    handleUpdate(e.value, name);
  };

  const handleSubmit = (addQA, client) => async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.checkValidity();

    if (state.form.title == "") {
      setFeedback("Þarf að gefa heiti");
      return;
    }

    if (state.form.categoryId == undefined) {
      setFeedback("Þarf að velja flokk");
      return;
    }

    if (state.form.managerId == undefined) {
      setFeedback("Þarf að velja ábyrgðarmann");
      return;
    }

    try {
      handleState({ submitting: true });
      const { data, error } = await addQA({
        variables: {
          title: state.form.title,
          categoryId: state.form.categoryId,
          trademarkId: state.form.trademarkId,
          productId: state.form.productId,
          supplierId: state.form.supplierId,
          managerId: state.form.managerId,
          dateStart: state.form.dateStart.toISOString(),
          dateEnd: state.form.dateEnd.toISOString(),
          franchises: state.form.franchises.map((i) => i.value),
          stores: state.form.stores.map((i) => i.value),
          comment: state.form.comment,
          requestService: state.form.requestService,
        },
      });
      handleState({ submitting: false });
      await client.query({ query: GET_QAS, fetchPolicy: "network-only" });
      props.history.push(`/qa`);
    } catch (error) {
      handleState({ submitting: false, error: error.message });
    }
  };

  const { submitting } = state;
  const client = useApolloClient();

  if (state.error) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Grunn upplýsingar">
            <h3>{state.error}</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Mutation mutation={ADD_QA}>
        {(addQA) => (
          <Form onSubmit={handleSubmit(addQA, client)}>
            <Row>
              <Col
                xs={12}
                md={{ span: 8, offset: 2 }}
                xl={{ span: 5, offset: 1 }}
              >
                <Card title="Grunn upplýsingar">
                  {feedback && <div style={{ color: "red" }}>{feedback}</div>}
                  <Form.Group>
                    <Form.Row key={"qa-form-title"}>
                      <Form.Label column lg={5}>
                        Heiti
                      </Form.Label>
                      <Col>
                        <Form.Control
                          required
                          name={"title"}
                          type={"text"}
                          onChange={handleInputUpdate}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-supplier"}>
                      <Form.Label column lg={5}>
                        Birgi
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <SupplierSelect
                            required={true}
                            name={"supplierId"}
                            value={state.form.supplierId}
                            handleSelectUpdate={handleSelectUpdate}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showSupplierModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-trademark"}>
                      <Form.Label column lg={5}>
                        Vörumerki
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <TrademarkSelect
                            required={true}
                            name={"trademarkId"}
                            value={state.form.trademarkId}
                            handleSelectUpdate={handleSelectUpdate}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showTrademarkModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-product"}>
                      <Form.Label column lg={5}>
                        Vara
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <ProductSelect
                            required={true}
                            name={"productId"}
                            value={state.form.productId}
                            handleSelectUpdate={handleSelectUpdate}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showProductModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-category"}>
                      <Form.Label column lg={5}>
                        Flokkur
                      </Form.Label>
                      <Col>
                        <InputGroup>
                          <CategorySelect
                            id="categorySelect"
                            required={true}
                            name={"categoryId"}
                            value={state.form.categoryId}
                            handleSelectUpdate={handleSelectUpdate}
                          />
                          <InputGroup.Append>
                            <Button
                              variant="primary"
                              onClick={() =>
                                handleState({ showCategoryModal: true })
                              }
                            >
                              <i className="fas fa-plus" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-dateStart"}>
                      <Form.Label column lg={5}>
                        Hefst
                      </Form.Label>
                      <Col>
                        <DatePicker
                          selected={state.form.dateStart}
                          wrapperClassName="w-100"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={(date) => handleUpdate(date, "dateStart")}
                          className="form-control"
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-dateEnd"}>
                      <Form.Label column lg={5}>
                        Lýkur
                      </Form.Label>
                      <Col>
                        <DatePicker
                          selected={state.form.dateEnd}
                          wrapperClassName="w-100"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="dd/mm/yyyy"
                          onChange={(date) => handleUpdate(date, "dateEnd")}
                          className="form-control"
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-manager"}>
                      <Form.Label column lg={5}>
                        Ábyrgðarmaður
                      </Form.Label>
                      <Col>
                        <UserSelect
                          id="managerSelect"
                          name={"managerId"}
                          handleSelectUpdate={handleSelectUpdate}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-franchises"}>
                      <Form.Label column lg={5}>
                        Keðjur
                      </Form.Label>
                      <Col>
                        {state.form.franchises.map((s) => (
                          <ButtonGroup size="sm" className="mr-1 mb-1">
                            <Button
                              variant="primary"
                              key={"franchise-" + s.value}
                              onClick={() => {
                                const newFranchises =
                                  state.form.franchises.filter(
                                    (o) => o.value != s.value
                                  );
                                handleUpdate(newFranchises, "franchises");
                              }}
                            >
                              {s.label} <i className="fas fa-times" />
                            </Button>
                          </ButtonGroup>
                        ))}
                        <SelectWithData
                          closeMenuOnSelect={false}
                          controlShouldRenderValue={false}
                          isMulti
                          query={GET_FRANCHISES}
                          placeholder={""}
                          onChange={(e) => handleUpdate(e, "franchises")}
                          value={state.form.franchises}
                        />
                      </Col>
                    </Form.Row>

                    <Form.Row key={"qa-form-stores"}>
                      <Form.Label column lg={5}>
                        Verslanir
                      </Form.Label>
                      <Col>
                        {state.form.stores.map((s) => (
                          <ButtonGroup size="sm" className="mr-1 mb-1">
                            <Button
                              variant="primary"
                              key={"store-" + s.value}
                              onClick={() => {
                                const newStores = state.form.stores.filter(
                                  (o) => o.value != s.value
                                );
                                handleUpdate(newStores, "stores");
                              }}
                            >
                              {s.label} <i className="fas fa-times" />
                            </Button>
                          </ButtonGroup>
                        ))}
                        <SelectWithData
                          closeMenuOnSelect={false}
                          controlShouldRenderValue={false}
                          isMulti
                          query={GET_STORES}
                          placeholder={""}
                          onChange={(e) => handleUpdate(e, "stores")}
                          value={state.form.stores}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </Card>
              </Col>
              <Col xs={12} xl={{ span: 5 }}>
                <Card title="Óska eftir þjónustu">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      name="requestService"
                      onChange={handleInputUpdate}
                      rows={3}
                    />
                  </Form.Group>
                </Card>

                <Card title="Athugasemdir">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      name="comment"
                      onChange={handleInputUpdate}
                      rows={3}
                    />
                  </Form.Group>
                </Card>
              </Col>
              <Col xs={12} xl={{ span: 10, offset: 1 }}>
                <Card noHeader>
                  <div className="text-right">
                    <button
                      data-test="submit"
                      type="submit"
                      disabled={submitting}
                      className="btn btn-success"
                    >
                      Áfram
                    </button>
                  </div>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Mutation>
      <NewCategoryModal
        show={state.showCategoryModal}
        onClose={() => handleState({ showCategoryModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => handleUpdate(data.id, "categoryId")}
      />
      <NewProductModal
        show={state.showProductModal}
        onClose={() => handleState({ showProductModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => handleUpdate(data.id, "productId")}
      />
      <NewTrademarkModal
        show={state.showTrademarkModal}
        onClose={() => handleState({ showTrademarkModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => handleUpdate(data.id, "trademarkId")}
      />
      <NewSupplierModal
        show={state.showSupplierModal}
        onClose={() => handleState({ showSupplierModal: false })}
        handleSelectUpdate={handleSelectUpdate}
        onNew={(data) => handleUpdate(data.id, "supplierId")}
      />
    </React.Fragment>
  );
}

export default NewQA;
