import React from "react";
import { Switch, Route } from "react-router-dom";

import AdminLayout from "./layout/AdminLayout";
import PrivateRoute from "../utils/PrivateRoute";
import Login from "../Pages/Login";
import Reset from "../Pages/Reset";
import Forgot from "../Pages/Forgot";

function App() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset/:userId/:token" component={Reset} />
      <Route path="/forgot" component={Forgot} />
      <PrivateRoute path="/" component={AdminLayout} />
    </Switch>
  );
}

export default App;
