import React, { Component, useState } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import Select from "react-select";

import Card from "../App/components/MainCard";
import Loader from "../App/layout/Loader";
import Confirm from "../components/Confirm";
import { hasData } from "../utils/Graphql";
import UserSelect from "./UserSelect";
import NewTrademarkModal from "./NewTrademarkModal";
import { useRest } from "../utils/Rest";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_TRADEMARKS = gql`
  query GetTrademarks {
    allTrademarks {
      id
      title
      manager {
        id
        name
      }
      isMine
    }
  }
`;

function TrademarkItem(props) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { item } = props;

  return (
    <React.Fragment>
      <tr
        key={"trademark-" + item.id}
        data-id={item.id}
        onClick={() => {
          setShowUpdate(true);
        }}
      >
        <td className="align-middle">{item.title}</td>
        <td className="align-middle">{item.manager?.name}</td>
        <td className="align-middle">
          {item.isMine && <i className="fas fa-star"></i>}
        </td>
        <td className="align-middle" width={170}>
          <Button size="sm" variant="outline-primary" className="mr-1">
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
        </td>
      </tr>
      {showUpdate && (
        <UpdateTrademarkModal
          item={item}
          show={showUpdate}
          onClose={() => {
            props.onUpdate();
            setShowUpdate(false);
          }}
        />
      )}
      {/*
        <td className="align-middle" width={170}>
          <Button size="sm" variant="outline-primary" className="mr-1">
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <Confirm
        show={showConfirmDelete}
        title="Ertu viss?"
        confirm="Já"
        cancel="hætta við"
        onConfirm={() => {
          remove();
          setShowConfirmDelete(false);
        }}
        onClose={() => {
          setShowConfirmDelete(false);
        }}
      />
      */}
    </React.Fragment>
  );
}

function Trademarklist() {
  const { loading, error, data, refetch } = useQuery(GET_TRADEMARKS);

  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  return data.allTrademarks
    .filter(() => true)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => (
      <TrademarkItem key={item.id} item={item} onUpdate={() => refetch()} />
    ));
}

function UpdateTrademarkModal(props) {
  const [title, setTitle] = useState(props.item.title);
  const [managerId, setManager] = useState(props.item.manager?.id);
  const [isMine, setIsMine] = useState(props.item.isMine);
  const [muterror, setMuterror] = useState(null);
  const { loading, error, data, persist } = useRest(
    `/api/trademarks/${props.item.id}`
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    await persist({
      managerId,
      isMine,
    });

    if (error) {
      setError("Failed to update Trademark");
    } else {
      props.onClose();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Uppfæra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Titill</Form.Label>
            <Form.Control type="text" disabled={true} value={title} />
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor="mine-check">Mitt vörumerki</Form.Label>
            <input
              id="mine-check"
              type="checkbox"
              className="form-check wid-20 hei-20"
              checked={isMine}
              onChange={(e) => setIsMine(e.target.checked)}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Ábyrðaraðili</Form.Label>
            <UserSelect
              extraOptions={[{ value: null, label: "Enginn" }]}
              handleSelectUpdate={(selected) => {
                setManager(selected.value);
              }}
              value={managerId}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function Trademarks(props) {
  const [showNew, setShowNew] = useState(false);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="Vörumerki">
            {/*
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => setShowNew(true)}
            >
              Nýtt vörumerki
              <i className="fas fa-chevron-right" />
            </Button>
            */}
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Titill</th>
                  <th>Ábyrgaðarmaður</th>
                  <th>Mín vörumerki</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{<Trademarklist />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <NewTrademarkModal show={showNew} onClose={() => setShowNew(false)} />
    </React.Fragment>
  );
}

export default Trademarks;
