import React, { useState, useEffect } from "react";
import { Row, Col, Button, Badge, Table } from "react-bootstrap";

import Card from "../App/components/MainCard";
import { useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import { dateFormat } from "./Formatting";
import { hasData } from "../utils/Graphql";
import { useRest } from "../utils/Rest";

function ShelfAnalysisList(props) {
  const { loading, error, data } = useRest("/api/shelf-analysis");

  const history = useHistory();
  if (loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  //if (error)
  //  return (
  //    <tr>
  //      <td>Error...</td>
  //    </tr>
  //  );

  function beDate(date) {
    if (typeof date !== Date) {
      date = new Date(date);
    }
    return date;
  }

  return (data || [])
    .sort((a, b) => {
      let x = beDate(a.dateStart);
      let y = beDate(b.dateStart);

      return y - x;
    })
    .map((item) => (
      <tr
        key={"shelfanalysis-" + item.id}
        data-id={item.id}
        onClick={() => history.push(`/shelf-analysis/${item.id}`)}
      >
        <td
          className="align-middle"
          style={{
            padding: "0 0.75em",
            textAlign: "center",
          }}
        >
          {hasData(data) && item.profileImageUrl && (
            <img
              style={{
                maxHeight: "40px",
                maxWidth: "60px",
              }}
              src={item.profileImageUrl + "?w=60"}
            />
          )}
        </td>
        <td data-test="title" className="align-middle">
          {item.title}
        </td>
        <td data-test="dateStartEnd" className="align-middle">
          {dateFormat(new Date(item.dateStart)) +
            " - " +
            dateFormat(new Date(item.dateEnd))}
        </td>
        <td className="align-middle">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => history.push(`/shelf-analysis/${item.id}`)}
          >
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
        </td>
      </tr>
    ));
}

export default function ShelfAnalysis(props) {
  const history = useHistory();
  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="Hillugreiningar">
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => history.push("/shelf-analysis/new")}
            >
              Ný Hillugreining
              <i className="fas fa-chevron-right" />
            </Button>
            <Table data-test="shelfAnalysisList" responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Titill</th>
                  <th>Tímabil</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{<ShelfAnalysisList />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
