import React, { Component, useState, useRef } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Media,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Badge,
} from "react-bootstrap";
import styled from "styled-components";
import { Loading } from "../utils/Dots";

import hash from "object-hash";
import Pagination from "@material-ui/lab/Pagination";

import Card from "../App/components/MainCard";
import BetterImageViewer from "../components/BetterImageViewer";
import Confirm from "../components/Confirm";
import AngledBanner from "../components/AngledBanner";
import { hasData } from "../utils/Graphql";

import Sugar from "sugar";
Sugar.extend();

import Select from "react-select";

import { fancyDateFormat } from "./Formatting";

const loadingImage = () => ({
  id: Math.random(),
  url: "https://kurvan-io.imgix.net/default_image.jpg",
});

const GET_IMAGES = gql`
  query GetImages {
    allQA {
      id
      title
      supplier {
        id
        title
      }
      category {
        id
        title
      }
      trademark {
        id
        title
      }
      manager {
        id
        name
      }
    }
    campaigns {
      id
      title
      supplier {
        id
        title
      }
      category {
        id
        title
      }
      trademark {
        id
        title
      }
      manager {
        id
        name
      }
    }
    allImages: allDisplayImages {
      id
      date
      url
      comments
      tags
      needsAttention
      storeId
      projectId
      productIds
      trademarkIds
      supplierIds
    }
    allProducts {
      id
      title
      supplierId
      trademarkId
      subCategoryIds
    }
    allSuppliers {
      id
      title
    }
    allTrademarks {
      id
      title
    }
    franchises {
      id
      name
    }
    stores {
      id
      name
      franchiseId
      gps {
        lat
        lng
      }
    }
  }
`;

const REMOVE_IMAGE = gql`
  mutation RemoveImage($id: ID!) {
    deleted: removeImageFromAccount(id: $id) {
      id
    }
  }
`;

const ImageDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 250px;
  overflow: hidden;
  cursor: pointer;
  margin: 2px;

  .backdrop {
    position: absolute;
    top: -50px;
    left: -50px;
    height: calc(100% + 100px);
    width: calc(100% + 100px);
    background-size: cover;
    background-position: center center;
    filter: blur(20px);
    transition: background-image 0.2s ease-in-out;
  }

  .badge-list {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    z-index: 2;
    > * {
      float: left;
      margin-top: 4px;
      margin-left: 4px;
      display: inline-block;
    }
  }

  .trash-button {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 2;
  }

  .delete-button {
    padding: 1px 4px 3px 4px;
    background-color: red;
    border-radius: 2px;
    color: white;
    cursor: pointer;
  }

  .image-wrapper {
    width: 100%;
    height: 100%;

    img {
      box-shadow: 3px 3px 3px 0px #252525;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: calc(100% - 25px);
      max-height: calc(100% - 25px);
      user-select: none;
    }
    .image-info {
      position: absolute;
      bottom: 0;
      width: 100%;
      color: white;
      font-size: 12px;
      text-align: center;
      background: #101b33;
      padding: 5px 0;
    }
  }
`;

const PaginationWrapper = styled.div`
  .MuiPagination-ul {
    justify-content: center;
  }
`;

function beDate(date) {
  if (typeof date !== Date) {
    date = new Date(date);
  }
  return date;
}

function Image({ loading, stores, ...props }) {
  if (loading === undefined) loading = false;
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const image = props.image;
  const onClick = props.onClick || (() => {});

  const imageUrl = image.url + "?max-w=250&h=250";
  var store = {};

  if (image.store) {
    store = image.store;
  } else if (image.storeId && stores) {
    store = stores[image.storeId] ?? {};
  }

  return (
    <React.Fragment>
      <ImageDiv key={image.id} onClick={onClick}>
        <div
          className="backdrop"
          style={{
            backgroundImage: `url(${imageUrl})`,
            position: "relative",
          }}
        />
        {image.needsAttention && (
          <AngledBanner backgroundColor="red" textColor="white">
            Athugasemd
          </AngledBanner>
        )}
        {loading && (
          <AngledBanner backgroundColor="gray" textColor="white">
            ...
          </AngledBanner>
        )}
        <div className="badge-list">
          {(image.tags || []).first(3).map((tag, i) => (
            <Badge key={"badges" + tag + i} variant={"primary"}>
              {tag}
            </Badge>
          ))}
          {image.comment && (
            <i
              title={image.comment}
              className="fas fa-info-circle f-20 mr-1"
              style={{ color: "yellow" }}
            />
          )}
        </div>

        <div className="trash-button">
          {props.remove && (
            <a
              className="delete-button"
              title="Eyða"
              onClick={(e) => {
                e.stopPropagation();
                setShowConfirmDelete(true);
              }}
            >
              <i className="fas fa-trash f-12" />
            </a>
          )}
        </div>
        <div className="image-wrapper">
          <img src={imageUrl} />
          <div className="image-info">
            <b>{store.name}</b>
            <br />
            <i>{fancyDateFormat(image?.date)}</i>
          </div>
        </div>
      </ImageDiv>
      {props.remove && (
        <Confirm
          show={showConfirmDelete}
          onConfirm={() => {
            props.remove({ variables: { id: image.id } });
            setShowConfirmDelete(false);
          }}
          onClose={() => {
            setShowConfirmDelete(false);
          }}
        />
      )}
    </React.Fragment>
  );
}

const download = (path, filename) => {
  const anchor = document.createElement("a");
  anchor.href = path;
  anchor.download = filename;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export function Images({ loading, stores, ...props }) {
  if (loading === undefined) loading = false;

  const [selectedImages, setSelectedImages] = useState([]);
  const [imageIdx, setImageIdx] = useState(null);
  const title = props.title || "Myndir";
  const images = props.images || [];
  const items_per_page = 18 * 2;

  const hashSum = hash(JSON.stringify(props.images));

  const [pagination, setPagination] = useState({ page: 1, hashSum: "-" });

  let currentPage =
    pagination.hashSum === hashSum || pagination.hashSum === "-"
      ? pagination.page
      : 1;
  let totalPages = parseInt(images.length / items_per_page + 1);

  if (totalPages < currentPage) {
    currentPage = totalPages;
  }
  if (currentPage < 1) {
    currentPage = 1;
  }

  const pages = [];
  for (var i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const sliceFrom = (currentPage - 1) * items_per_page;
  const sliceTo = currentPage * items_per_page;

  const [fetchingPdf, setFetchingPdf] = useState(false);
  return (
    <React.Fragment>
      {imageIdx != null && (
        <BetterImageViewer
          show={imageIdx != null}
          onClose={() => setImageIdx(null)}
          startIndex={imageIdx}
          images={images}
        />
      )}

      <Card title={`${title} (${loading ? "..." : images.length})`}>
        <Button
          className="mr-1 d-inline-block"
          size="sm"
          disabled={selectedImages.length < 1}
          onClick={async () => {
            if (selectedImages.length < 1) {
              return;
            }
            //TODO: Give feedback when there is error

            const formData = new FormData();
            selectedImages.map((id) => formData.append(`ids[]`, id));

            setFetchingPdf(true);
            const response = await fetch("/process/export-pdf", {
              method: "POST",
              credentials: "include",
              body: formData,
            });
            setFetchingPdf(false);

            if (response.status == 200) {
              const type = response.type;
              const data = await response.blob();
              const blob = new Blob([data], {
                type: "application/octet-stream",
              });

              const url = URL.createObjectURL(blob);
              download(url, "image_report.pdf");
              URL.revokeObjectURL(url);
              setSelectedImages([]);
            } else {
              console.error(response.statusText);
            }
          }}
        >
          {fetchingPdf ? (
            <div
              className="spinner-border"
              style={{ width: "1rem", height: "1rem" }}
            />
          ) : (
            "Sækja myndaskýrslu"
          )}
        </Button>
        <DropdownButton className="mr-1 d-inline-block" size="sm" title="Velja">
          <Dropdown.Item
            onClick={() => {
              setSelectedImages(images.map((i) => i.id));
            }}
          >
            Allt
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setSelectedImages([]);
            }}
          >
            Ekkert
          </Dropdown.Item>
        </DropdownButton>

        {props.children}
        {totalPages > 1 && (
          <Row>
            <Col xs={12} style={{ marginBottom: 10 }}>
              <PaginationWrapper>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, page) => {
                    setPagination({
                      hashSum,
                      page,
                    });
                  }}
                />
              </PaginationWrapper>
            </Col>
          </Row>
        )}
        <Row>
          {images.slice(sliceFrom, sliceTo).map((image, index) => (
            <Col sm="12" md="6" lg="4" xl="2" key={image.id}>
              <Image
                loading={loading}
                key={image.id}
                image={image}
                stores={stores}
                onClick={() => setImageIdx(index + sliceFrom)}
                remove={props.remove}
              />
              <input
                style={{
                  position: "absolute",
                  top: "0",
                  margin: "10px",
                  width: "18px",
                  height: "18px",
                  zIndex: "5",
                }}
                type="checkbox"
                checked={selectedImages.includes(image.id)}
                onChange={() => {
                  if (selectedImages.includes(image.id)) {
                    const removeIdx = selectedImages.indexOf(image.id);
                    const newList = selectedImages.slice();
                    newList.splice(removeIdx, 1);
                    setSelectedImages(newList);
                  } else {
                    setSelectedImages([...selectedImages, image.id]);
                  }
                }}
              />
            </Col>
          ))}
        </Row>
        {totalPages > 1 && (
          <Row>
            <Col xs={12}>
              <PaginationWrapper>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e, page) => {
                    setPagination({
                      hashSum,
                      page,
                    });
                  }}
                />
              </PaginationWrapper>
            </Col>
          </Row>
        )}
      </Card>
    </React.Fragment>
  );
}

function ImagesWithData(props) {
  const { loading, error, data } = useQuery(GET_IMAGES);

  const [filter, setFilter] = useState({});

  const [remove] = useMutation(REMOVE_IMAGE, {
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      const queryResult = cache.readQuery({
        query: GET_IMAGES,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_IMAGES,
          data: {
            allImages: queryResult.allImages.filter((item) => item.id !== id),
          },
        });
      }
    },
  });

  const projects = {};
  const stores = {};
  const franchises = {};
  const trademarks = {};
  const suppliers = {};
  const products = {};
  const images = {};
  const imageProducts = [];
  const imageTrademarks = [];
  const imageSuppliers = [];
  const categories = {};
  const tags = {};
  const managers = {};
  const dates = {};

  if (data) {
    for (let store of data.stores) {
      if (store && !stores[store.id]) {
        stores[store.id] = store;
        if (store.franchiseId && !franchises[store.franchiseId]) {
          franchises[store.franchiseId] = data.franchises.find(
            (f) => f.id == store.franchiseId
          );
        }
      }
    }
    for (let i of data.campaigns) {
      if (!(i.id in projects)) {
        projects[i.id] = i;
      }
      const category = i.category;
      if (category && !categories[category.id]) {
        categories[category.id] = category;
      }
      const manager = i.manager;
      if (manager && !managers[manager.id]) {
        managers[manager.id] = manager;
      }
    }

    for (let i of data.allQA) {
      if (!(i.id in projects)) {
        projects[i.id] = i;
      }
      const category = i.category;
      if (category && !categories[category.id]) {
        categories[category.id] = category;
      }
      const manager = i.manager;
      if (manager && !managers[manager.id]) {
        managers[manager.id] = manager;
      }
    }

    for (let i of data.allImages) {
      if (!(i.id in images)) {
        images[i.id] = i;
      }

      i.productIds.map((id) => {
        imageProducts.push(id);
      });
      i.supplierIds.map((id) => {
        imageSuppliers.push(id);
      });
      i.trademarkIds.map((id) => {
        imageTrademarks.push(id);
      });

      if (i.tags) {
        i.tags.map((tag) => {
          if (!tags[tag]) {
            tags[tag] = tag;
          }
        });
      }
      const date = beDate(i.date).format("%d-%m-%Y");
      if (date && !dates[date]) {
        dates[date] = date;
      }
    }

    for (let i of data.allTrademarks) {
      if (!(i.id in trademarks)) {
        trademarks[i.id] = i;
      }
    }
    for (let i of data.allSuppliers) {
      if (!(i.id in suppliers)) {
        suppliers[i.id] = i;
      }
    }
    for (let i of data.allProducts) {
      if (!(i.id in products)) {
        products[i.id] = i;
      }
    }
  }

  return (
    <Row className="justify-content-md-center">
      <Col sm="12" lg="12">
        <Col
          xs
          lg="12"
          style={{
            backgroundColor: "#ffffff",
            marginBottom: "20px",
            padding: "20px",
            zIndex: 20,
          }}
        >
          <Row>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"campaingSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      project: e,
                    });
                  }}
                  placeholder="Velja verkefni..."
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(projects)
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((c) => ({
                        value: c.id,
                        label: c.title,
                      })),
                  ]}
                  value={filter.project}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"franchiseSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja keðju..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      franchise: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(franchises)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => ({
                        value: c.id,
                        label: c.name,
                      })),
                  ]}
                  value={filter.franchise}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"storeSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja búð..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      store: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(stores)
                      .filter((store) => {
                        if (filter.franchise && filter.franchise.value) {
                          if (store.franchiseId !== filter.franchise.value)
                            return false;
                        }
                        return true;
                      })
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => ({
                        value: c.id,
                        label: c.name,
                      })),
                  ]}
                  value={filter.store}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"tagSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja tag..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      tag: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(tags)
                      .sort()
                      .map((c) => ({ value: c, label: c })),
                  ]}
                  value={filter.tag}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"storeSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Athyglis flagg"
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      needsAttention: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    { value: true, label: "Þarfnast athygli" },
                    { value: false, label: "Eðlilegt" },
                  ]}
                  value={filter.needsAttention}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"trademarkSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja vörumerki..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      trademark: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(trademarks)
                      .filter((p) => imageTrademarks.includes(p.id))
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((c) => ({
                        value: c.id,
                        label: c.title,
                      })),
                  ]}
                  value={filter.trademark}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"storeSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja birgja..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      supplier: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(suppliers)
                      .filter((p) => imageSuppliers.includes(p.id))
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((c) => ({
                        value: c.id,
                        label: c.title,
                      })),
                  ]}
                  value={filter.supplier}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"storeSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja ábyrgðarmann..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      manager: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allir" },
                    ...Object.values(managers)
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((c) => ({
                        value: c.id,
                        label: c.name,
                      })),
                  ]}
                  value={filter.manager}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"storeSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja vöru..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      product: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(products)
                      .filter((p) => imageProducts.includes(p.id))
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((c) => ({
                        value: c.id,
                        label: c.title,
                      })),
                  ]}
                  value={filter.product}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"categorySelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja flokk..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      category: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(categories)
                      .sort((a, b) => a.title.localeCompare(b.title))
                      .map((c) => ({
                        value: c.id,
                        label: c.title,
                      })),
                  ]}
                  value={filter.category}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <InputGroup>
                <Select
                  name={"dateSelect"}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Velja dagsetningar..."
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      date: e,
                    });
                  }}
                  options={[
                    { value: "", label: "Allt" },
                    ...Object.values(dates)
                      .sort(
                        (a, b) =>
                          new Date(a.split("-").reverse().join("-")) -
                          new Date(b.split("-").reverse().join("-"))
                      )
                      .map((c) => ({
                        value: c,
                        label: c,
                      })),
                  ]}
                  value={filter.date}
                />
              </InputGroup>
            </Col>
            <Col md="6" lg="4" xl="3" className="mt-1">
              <Button
                onClick={() => {
                  let newFilter = {};
                  for (var key in filter) {
                    newFilter[key] = null;
                  }

                  setFilter(newFilter);
                }}
              >
                Hreinsa síu
              </Button>
            </Col>
          </Row>
        </Col>
      </Col>
      <Col xs lg="12">
        <Images
          loading={!hasData(data) && loading}
          stores={stores}
          remove={remove}
          images={(data?.allImages || Array.construct(6, (i) => loadingImage()))
            .filter((i) => {
              if (filter.project && filter.project.value) {
                if (i.projectId !== filter.project.value) return false;
              }

              if (filter.store && filter.store.value) {
                if (i.storeId !== filter.store.value) return false;
              }

              if (filter.franchise && filter.franchise.value) {
                if (!i.storeId) return false;
                if (!(i.storeId in stores)) return false;
                if (stores[i.storeId].franchiseId !== filter.franchise.value)
                  return false;
              }

              if (filter.tag && filter.tag.value) {
                if (!i.tags.includes(filter.tag.value)) return false;
              }

              if (filter.date && filter.date.value) {
                const date = beDate(i.date).format("%d-%m-%Y");
                if (date !== filter.date.value) return false;
              }

              if (filter.needsAttention && filter.needsAttention.value !== "") {
                if (i.needsAttention !== filter.needsAttention.value)
                  return false;
              }

              if (filter.manager && filter.manager.value) {
                if (projects[i.projectId]?.manager) {
                  if (projects[i.projectId].manager.id !== filter.manager.value)
                    return false;
                } else {
                  return false;
                }
              }

              if (filter.category && filter.category.value) {
                if (projects[i.projectId]?.category) {
                  if (
                    projects[i.projectId].category.id !== filter.category.value
                  )
                    return false;
                } else {
                  return false;
                }
              }

              if (filter.supplier && filter.supplier.value) {
                if (images[i.id]?.supplierIds) {
                  if (!images[i.id].supplierIds.includes(filter.supplier.value))
                    return false;
                } else {
                  return false;
                }
              }

              if (filter.product && filter.product.value) {
                if (images[i.id]?.productIds) {
                  if (!images[i.id].productIds.includes(filter.product.value))
                    return false;
                } else {
                  return false;
                }
              }

              if (filter.trademark && filter.trademark.value) {
                if (images[i.id]?.trademarkIds) {
                  if (
                    !images[i.id].trademarkIds.includes(filter.trademark.value)
                  )
                    return false;
                } else {
                  return false;
                }
              }

              return true;
            })
            .sort((a, b) => {
              let x = beDate(a.date);
              let y = beDate(b.date);

              return y - x;
            })}
        />
      </Col>
    </Row>
  );
}

export default ImagesWithData;
