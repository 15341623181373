import React, { useContext, useState, useRef, useEffect } from "react";

export const useRest = (url) => {
  const [data, setData] = useState(undefined);

  const fetchDataFromUrl = (signal) => {
    fetch(url, {
      signal: signal,
    })
      .then((r) => r.json())
      .then(setData);
  };

  const persist = async (newData) => {
    console.log(url);
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(newData),
    })
      .then((response) => {
        setData(newData);
        return response;
      })
      .catch(console.log);
  };

  const remove = async (newData) => {
    return fetch(url, {
      method: "DELETE",
    })
      .then(console.log)
      .catch(console.log);
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    fetchDataFromUrl(signal);
    return () => controller.abort();
  }, [url]);

  return {
    data,
    loading: data === undefined,
    persist,
    remove,
    refetch: fetchDataFromUrl,
  };
};
