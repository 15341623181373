import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";

export const ADD_TRADEMARK = gql`
  mutation AddTrademark($title: String!) {
    addTrademark(title: $title) {
      id
      title
      manager {
        id
        name
      }
    }
  }
`;

export default function NewTrademarkModal(props) {
  const [title, setTitle] = useState("");
  const [muterror, setMuterror] = useState(null);
  const [addTrademark] = useMutation(ADD_TRADEMARK, {
    refetchQueries: ["GetTrademarks"],
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await addTrademark({ variables: { title } });
    if (error) {
      setMuterror("Failed to create User");
      return;
    }
    if (props.onNew) props.onNew(data.addTrademark);
    setTitle("");
    props.onClose();
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Nýtt vörumerki</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
