import React, { Fragment, useContext } from "react";
import { useLocation, NavLink } from "react-router-dom";

import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import * as actionTypes from "../../../../../../store/actions";
import GlobalContext from "../../../../../../GlobalContext";
import { useWindowSize } from "../../../../../../utils/reactHooks";

function NavItem({ item }) {
  const {
    state: { layout, collapseMenu },
    dispatch,
  } = useContext(GlobalContext);
  const { width, height } = useWindowSize();

  const onItemClick = () => dispatch({ type: actionTypes.COLLAPSE_MENU });
  const onItemLeave = () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE });

  const itemTitle = item.icon ? (
    <span className="pcoded-mtext">{item.title}</span>
  ) : (
    item.title
  );

  const subContent = item.external ? (
    <a href={item.url} target="_blank" rel="noopener noreferrer">
      <NavIcon items={item} />
      {itemTitle}
      <NavBadge layout={layout} items={item} />
    </a>
  ) : (
    <NavLink
      to={item.url}
      className="nav-link"
      exact={true}
      target={item.target ? "_blank" : ""}
    >
      <NavIcon items={item} />
      {itemTitle}
      <NavBadge layout={layout} items={item} />
    </NavLink>
  );

  let mainContent = "";
  if (layout === "horizontal") {
    mainContent = <li onClick={onItemLeave}>{subContent}</li>;
  } else {
    mainContent =
      width < 992 ? (
        <li className={item.classes} onClick={onItemClick}>
          {subContent}
        </li>
      ) : (
        <li className={item.classes}>{subContent}</li>
      );
  }

  return <Fragment>{mainContent}</Fragment>;
}

export default NavItem;
