import React, { Component } from "react";
import { Row, Col, Badg, Badge, Card, Table, CardDeck } from "react-bootstrap";
import { Loading } from "../utils/Dots";

import ComingSoon from "../components/ComingSoon";
import { useNumberFormat } from "../utils/reactHooks";
import UserProfileImage from "../components/UserProfileImage";
import { useHistory } from "react-router-dom";

import { GET_USERS } from "../GraphqlQueries/Users";

import { gql, useQuery, useMutation } from "@apollo/client";
import {
  dateFormat,
  dateTimeFormat,
  currencyFormat,
  numberFormat,
} from "./Formatting";
import { hasData } from "../utils/Graphql";
import Sugar from "sugar";

const GET_STATS = gql`
  query GetDashboardCounters {
    account {
      id
      campaignsCount
      qaCount
      imagesCount
      tasksCount
      needsAttentionCount
    }
    stats {
      date
      franchises {
        key
        value {
          title
          photos
          warnings
          tasks
        }
      }
      trademarks {
        key
        value {
          title
          photos
          warnings
          tasks
        }
      }
    }
  }
`;

const GET_HOLIDAYS = gql`
  query GetDashboardHolidays {
    allHolidays {
      id
      name
      date
    }
  }
`;
const GET_IMAGES = gql`
  query GetDashboardImages {
    allImages {
      id
      needsAttention
    }
  }
`;
const GET_QAS = gql`
  query GetDashboardQA {
    allQA {
      id
      title
    }
  }
`;

const GET_CAMPAIGNS = gql`
  query GetCampaigns {
    campaigns {
      id
      title
      category {
        id
        title
      }
      campaignImageUrl
      dateStart
      dateEnd
      locked
      goal
      result
    }
  }
`;

const days = [
  "Sunnudagur",
  "Mánudagur",
  "Þriðjudagur",
  "Miðvikudagur",
  "Fimmtudagur",
  "Föstudagur",
  "Laugardagur",
];

const DemoDataFranchises = [
  {
    id: "1",
    title: "Bónus",
    warnings: 25,
  },
  {
    id: "2",
    title: "Nettó",
    warnings: 15,
  },
  {
    id: "3",
    title: "Hagkaup",
    warnings: 8,
  },
  {
    id: "4",
    title: "Krónan",
    warnings: 23,
  },
  {
    id: "5",
    title: "Fjarðarkaup",
    warnings: 2,
  },
  {
    id: "6",
    title: "10-11",
    warnings: 28,
  },
];
const DemoDataTrademarks = [
  {
    id: "1",
    title: "Egils appelsín",
    warnings: 14,
  },
  {
    id: "2",
    title: "Doritos Guacamole",
    warnings: 18,
  },
  {
    id: "3",
    title: "Sumar Collab",
    warnings: 8,
  },
  {
    id: "4",
    title: "Epla kristall",
    warnings: 3,
  },
  {
    id: "5",
    title: "Hunts tómatsósa",
    warnings: 2,
  },
  {
    id: "6",
    title: "Kókómjólk",
    warnings: 25,
  },
];

const DashCard = ({ title, comingSoon, className, children }) => (
  <Card>
    <Card.Header>
      <h5>{title}</h5>
    </Card.Header>
    <Card.Body bsPrefix={className ?? "dash-card-body"}>
      {children}
      {comingSoon && <ComingSoon />}
    </Card.Body>
  </Card>
);

const CounterCard = ({ style, title, icon, count }) => {
  const formatter = useNumberFormat();
  const countDisplay =
    typeof count == "string"
      ? count
      : React.isValidElement(count)
      ? count
      : formatter.format(count);

  return (
    <Card body={true} style={style}>
      <Row className="align-items-center ml-0">
        <h6 className="text-muted m-b-10">{title}</h6>
      </Row>
      <Row className="align-items-center ml-0">
        <Col className="f-30" xs="auto">
          {icon}
        </Col>
        <Col className="p-b-10">
          <h2 className="m-b-0">{countDisplay}</h2>
        </Col>
      </Row>
    </Card>
  );
};

const Counter = (props) => {
  const count = props.getCount();
  return <CounterCard {...props} count={count} />;
};

const List = ({ style, ...props }) => {
  const { loading, data, error } = useQuery(props.query);
  var list = [];
  if (hasData(data) || (!loading && !error)) {
    list = props.map(data);
  }

  return (
    <div style={style}>
      <Table responsive hover striped size="sm">
        {props.header && (
          <thead>
            <tr>
              {props.header.map((head, index) => (
                <th key={head + index}>{head}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>{list}</tbody>
      </Table>
    </div>
  );
};

function Dashboard() {
  const statsResult = useQuery(GET_STATS);

  var franchises = [];
  var trademarks = [];
  if (hasData(statsResult.data) || !statsResult.loading) {
    const stats = statsResult.data.stats.reduce(
      (all, day) => {
        day.franchises.forEach((kv) => {
          const item = all.franchises[kv.key] || {};
          all.franchises[kv.key] = {
            id: kv.key,
            title: kv.value.title,

            photos: kv.value.photos + (item.photos || 0),
            warnings: kv.value.warnings + (item.warnings || 0),
            tasks: kv.value.tasks + (item.tasks || 0),
          };
        });
        day.trademarks.forEach((kv) => {
          const item = all.trademarks[kv.key] || {};
          all.trademarks[kv.key] = {
            id: kv.key,
            title: kv.value.title,

            photos: kv.value.photos + (item.photos || 0),
            warnings: kv.value.warnings + (item.warnings || 0),
            tasks: kv.value.tasks + (item.tasks || 0),
          };
        });

        return all;
      },
      {
        franchises: {},
        trademarks: {},
      }
    );
    franchises = Object.values(stats.franchises);
    trademarks = Object.values(stats.trademarks);
  }
  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs="12" sm="6" xl="3">
          <CounterCard
            title={"Myndir"}
            count={
              !hasData(statsResult.data) && statsResult.loading ? (
                <Loading size={"0.75rem"} />
              ) : (
                numberFormat(statsResult.data.account.imagesCount)
              )
            }
            icon={
              <span className="text-c-blue">
                <i className="fas fa-file-image"></i>
              </span>
            }
          />
        </Col>
        <Col xs="12" sm="6" xl="3">
          <Counter
            title={"Eftirlit / Herferðir"}
            getCount={(data) => {
              if (!hasData(statsResult.data) && statsResult.loading)
                return <Loading size={"0.75rem"} />;

              let account = statsResult.data.account;
              return account.qaCount + " / " + account.campaignsCount;
            }}
            icon={
              <span className="text-c-green">
                <i className="fas fa-camera"></i>
              </span>
            }
          />
        </Col>
        <Col xs="12" sm="6" xl="3">
          <Counter
            title={"Ábendingar"}
            getCount={(data) =>
              !hasData(statsResult.data) && statsResult.loading ? (
                <Loading size={"0.75rem"} />
              ) : (
                numberFormat(statsResult.data.account.needsAttentionCount)
              )
            }
            icon={
              <span className="text-c-red">
                <i className="fas fa-exclamation-circle"></i>
              </span>
            }
          />
        </Col>
        <Col xs="12" sm="6" xl="3">
          <Counter
            title={"Verkefni"}
            getCount={() =>
              !hasData(statsResult.data) && statsResult.loading ? (
                <Loading size={"0.75rem"} />
              ) : (
                numberFormat(statsResult.data.account.tasksCount)
              )
            }
            icon={
              <span className="text-c-yellow">
                <i className="fas fa-list-alt"></i>
              </span>
            }
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col sm="12" lg="6">
          <DashCard title="Top 5 Keðjur / ábendingar (Uppfært daglega)">
            <Table
              responsive
              hover
              striped
              size="sm"
              style={{
                height: 222,
                overflowY: "auto",
              }}
            >
              <tbody>
                {(franchises.length > 0 ? franchises : [])
                  .sortBy((f) => f.warnings, true)
                  .slice(0, 5)
                  .map((d) => (
                    <tr key={d.id}>
                      <td>{d.title}</td>
                      <td>{d.warnings}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </DashCard>
        </Col>
        <Col sm="12" lg="6">
          <DashCard title="Top 5 Vörumerki með ábendingar (Uppfært daglega)">
            <Table
              responsive
              hover
              striped
              size="sm"
              style={{
                height: 222,
                overflowY: "auto",
              }}
            >
              <tbody>
                {(trademarks.length > 0 ? trademarks : [])
                  .sortBy((f) => f.warnings, true)
                  .slice(0, 5)
                  .map((d) => (
                    <tr key={d.id}>
                      <td>{d.title}</td>
                      <td>{d.warnings}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </DashCard>
        </Col>
        {/*
        <Col sm="12" lg="4">
          <DashCard title="Meðal lokunartími verkefna" comingSoon={true}>
            <h2 style={{padding: "88px 0", textAlign: "center"}} className="ml-3 my-6">2 dagar og 3 klst.</h2>
          </DashCard>
        </Col>
        */}
        <Col lg="12" xl="6">
          <DashCard title="Hátíðis og tyllidagar framundan">
            <List
              query={GET_HOLIDAYS}
              style={{
                height: 304,
                overflowY: "auto",
              }}
              header={["Dagur", "", "", "Dagar til"]}
              map={(data) =>
                data.allHolidays
                  //Add the js Date object to the holiday
                  .map((d) => {
                    const date = Date.create(d.date);
                    return {
                      ...d,
                      genDate: date,
                      daysUntil: -date.daysUntil(),
                    };
                  })
                  // Filter out all past days
                  .filter((a) => a.daysUntil > 0)
                  // Sort by days until
                  .sort((a, b) => a.daysUntil - b.daysUntil)
                  .map((holiday, index) => {
                    return (
                      <tr key={holiday.id}>
                        <td>{holiday.name}</td>
                        <td>{days[holiday.genDate.getDay()]}</td>
                        <td>{holiday.genDate.format("%d-%m-%Y")}</td>
                        <td>{holiday.daysUntil}</td>
                      </tr>
                    );
                  })
              }
            />
          </DashCard>
        </Col>
        <Col lg="12" xl="6">
          <DashCard title="Notendur">
            <List
              title="Notendur"
              query={GET_USERS}
              header={["", "", "Gæðaeftirlit", "Herferðir"]}
              style={{
                height: 304,
                overflowY: "auto",
              }}
              map={(data) => {
                const userCount = data.users.length;
                return data.users
                  .filter(
                    (u) =>
                      userCount < 5 || u.qaCount != 0 || u.campaignCount != 0
                  )
                  .sort((a, b) => (a.qaCount < b.qaCount ? 1 : -1))
                  .map((u) => (
                    <tr key={u.id}>
                      <td className="p-1 px-3" width={30}>
                        <UserProfileImage
                          style={{ margin: 0 }}
                          user={u}
                          size={30}
                        />
                      </td>
                      <td className="px-0">{u.name}</td>
                      <td>{u.qaCount}</td>
                      <td>{u.campaignCount}</td>
                    </tr>
                  ));
              }}
            />
          </DashCard>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Dashboard;
