import React, { Component, useState } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import UserSelect from "./UserSelect";
import NewSupplierModal from "./NewSupplierModal";

import { hasData } from "../utils/Graphql";

const GET_SUPPLIERS = gql`
  query GetSuppliers {
    allSuppliers {
      id
      title
      manager {
        id
        name
      }
    }
  }
`;

const UPDATE_SUPPLIER = gql`
  mutation UpdateSupplier($id: ID!, $title: String, $managerId: ID) {
    updated: updateSupplier(id: $id, title: $title, managerId: $managerId) {
      id
      title
      manager {
        id
        name
      }
    }
  }
`;

const DELETE_SUPPLIER = gql`
  mutation DeleteSupplier($id: ID!) {
    deleted: removeSupplier(id: $id) {
      id
      title
      manager {
        id
        name
      }
    }
  }
`;

function SupplierItem(props) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { item } = props;

  const [remove] = useMutation(DELETE_SUPPLIER, {
    variables: { id: item.id },
    optimisticResponse: {
      __typename: "Mutation",
      deleted: {
        id: item.id,
      },
    },
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      const queryResult = cache.readQuery({
        query: GET_SUPPLIERS,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_SUPPLIERS,
          data: {
            allSuppliers: queryResult.allSuppliers.filter(
              (supplier) => supplier.id !== id
            ),
          },
        });
      }
    },
  });

  return (
    <React.Fragment>
      <tr
        key={"supplier-" + item.id}
        data-id={item.id}
        onClick={() => {
          setShowUpdate(true);
        }}
      >
        <td className="align-middle">{item.title}</td>
        <td className="align-middle">{item.manager?.name}</td>
      </tr>
      {/*
        <td className="align-middle" width={170}>
          <Button size="sm" variant="outline-primary" className="mr-1">
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <UpdateSupplierModal
        item={item}
        show={showUpdate}
        onClose={() => setShowUpdate(false)}
      />
      <Confirm
        show={showConfirmDelete}
        title="Ertu viss?"
        confirm="Já"
        cancel="hætta við"
        onConfirm={() => {
          remove();
          setShowConfirmDelete(false);
        }}
        onClose={() => {
          setShowConfirmDelete(false);
        }}
      />
      */}
    </React.Fragment>
  );
}

function Supplierlist() {
  const { loading, error, data } = useQuery(GET_SUPPLIERS);

  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  return data.allSuppliers
    .filter(() => true)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => <SupplierItem key={item.id} item={item} />);
}

function UpdateSupplierModal(props) {
  const [title, setTitle] = useState(props.item.title);
  const [managerId, setManager] = useState(props.item.manager?.id);
  const [muterror, setMuterror] = useState(null);
  const [update, { loading }] = useMutation(UPDATE_SUPPLIER);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data, error } = await update({
      variables: { id: props.item.id, title, managerId },
    });

    if (error) {
      setError("Failed to update Supplier");
    } else {
      props.onClose();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Uppfæra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Form.Label>Ábyrðaraðili</Form.Label>
          <UserSelect
            handleSelectUpdate={(selected) => {
              setManager(selected.value);
            }}
            value={managerId}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function Suppliers(props) {
  const [showNew, setShowNew] = useState(false);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="Birjar">
            {/*
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => setShowNew(true)}
            >
              Nýr birgi
              <i className="fas fa-chevron-right" />
            </Button>
            */}
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Titill</th>
                  <th>Ábyrgaðarmaður</th>
                </tr>
              </thead>
              <tbody>{<Supplierlist />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <NewSupplierModal show={showNew} onClose={() => setShowNew(false)} />
    </React.Fragment>
  );
}

export default Suppliers;
