import React from "react";
import UserContext from "./UserContext";
import { gql, useQuery } from "@apollo/client";

const defaultUser = {
  id: null,
  name: "Guest",
  email: "guest@kurvan.io",
};

const GET_ME = gql`
  query GetMe {
    me {
      id
      name
      email
      profileImageUrl
    }
  }
`;

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = React.useState(undefined);

  const { data, loading, error } = useQuery(GET_ME, {
    fetchPolicy: "network-only",
  });

  if (!loading && currentUser === undefined) {
    setCurrentUser(data.me);
  }

  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
};

export default AuthProvider;
