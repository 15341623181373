import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import { LineCanvas } from "@nivo/line";
import { BarCanvas } from "@nivo/bar";
import Sugar from "sugar";

export default function Report1(props) {
  //const { data } = GetData();
  const [selectedProduct, setSelectedProduct] = useState();
  let pricesurveyId = props.match.params.id;
  const {
    data: item = {
      products: [],
    },
  } = useRest(`/api/price-surveys/${pricesurveyId}`);
  const { data: pretables = {} } = useRest(
    `/api/reports/price_survey_product_report_store/${pricesurveyId}`
  );

  var storesByProduct = {};
  for (var product_index in item.products) {
    const product = item.products[product_index].title;
    const rows = pretables[product];
    var keys = [];
    for (var date in rows) {
      var stores = rows[date];
      keys = keys.concat(Object.keys(stores));
    }
    storesByProduct[product] = keys.unique();
  }

  var tables = {};
  for (var product_index in item.products) {
    const product = item.products[product_index].title;
    var rows = pretables[product];
    const keys = storesByProduct[product];

    const header = [...keys];
    var body = {};
    for (var date in rows) {
      var stores = rows[date];
      var row = [];
      for (const i in keys) {
        const key = keys[i];
        row.push(stores[key] ?? null);
      }
      body[dateFormat(date)] = row;
    }

    tables[product] = { header, body };
  }

  useEffect(() => {
    var firstProduct = item.products[0];
    if (!selectedProduct && firstProduct) {
      const sp = {
        value: firstProduct.id,
        label: firstProduct.title,
      };
      setSelectedProduct(sp);
    }
  }, [item]);

  const Title = (
    <div>
      <div>Vöruskýrsla - {item.title}</div>
      <span style={{ fontSize: "18px" }}>
        {dateFormat(item.dateStart)} - {dateFormat(item.dateEnd)}
      </span>
    </div>
  );
  const table = tables[selectedProduct?.label];

  const commonProps = {
    width: 400,
    height: 300,
    margin: { top: 20, right: 20, bottom: 60, left: 80 },
    pointSize: 8,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    pointBorderColor: { theme: "background" },
  };
  const lineProps = {
    ...commonProps,
    legends: [
      {
        anchor: "bottom-right",
        direction: "column",
        itemWidth: 80,
        itemHeight: 20,
      },
    ],
  };
  const barProps = {
    ...commonProps,
    keys: ["price"],
    axisBottom: {
      tickRotation: 15,
    },
  };

  const productData = tables[selectedProduct?.label] ?? {};

  const selectedDateIdx = 0;
  const selectedDate = Object.keys(productData.body ?? {})[selectedDateIdx];

  var selectedStoreIdx = 0;
  var selectedStoreName = "";

  var barStoreData = [];
  var barDateData = [];
  var lineData = [];
  if (table) {
    lineData = [];
    for (var i in table.header) {
      var data = [];
      for (var date in table.body) {
        data.push({
          x: date,
          y: table.body[date][i],
        });
      }
      lineData.push({ id: table.header[i], data });

      barDateData.push({
        store: table.header[i],
        price: table.body[selectedDate][i],
      });
    }

    selectedStoreName = table.header[selectedStoreIdx];
    for (var date in table.body) {
      barStoreData.push({
        date,
        price: table.body[date][selectedStoreIdx],
      });
    }
  }

  return (
    <Card title={Title}>
      <Select
        classNamePrefix="select"
        className="basic-single form-control"
        placeholder="Vara"
        options={item.products.map((p) => ({ label: p.title, value: p.id }))}
        value={selectedProduct}
        onChange={(e) => setSelectedProduct(e)}
      />
      <div style={{ display: "inline-block", textAlign: "center" }}>
        <LineCanvas {...lineProps} data={lineData} />
      </div>
      <div style={{ display: "inline-block", textAlign: "center" }}>
        <b>{selectedStoreName}</b>
        <BarCanvas {...barProps} indexBy="date" data={barStoreData} />
      </div>
      <div style={{ display: "inline-block", textAlign: "center" }}>
        <b>{selectedDate}</b>
        <BarCanvas {...barProps} indexBy="store" data={barDateData} />
      </div>
      {table && (
        <>
          <Table responsive size="sm">
            <tbody>
              <tr>
                <th key={-1}>{selectedProduct?.label}</th>
                {table.header.map((name, i) => (
                  <th key={i}>{name}</th>
                ))}
              </tr>
              {Object.entries(table.body).map((rowObj, i) => (
                <tr key={i}>
                  <td key={"date-" + i}>{rowObj[0]}</td>
                  {rowObj[1].map((value, i) => (
                    <td key={i}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Card>
  );
}
