import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Media,
  Table,
  Image,
  Modal,
  Form,
  Badge,
  Dropdown,
  Card,
} from "react-bootstrap";
import UploadProfile from "../components/UploadProfile";

import MainCard from "../App/components/MainCard";
import FailWhale from "../components/FailWhale";
import { useRest } from "../utils/Rest";
import { dateFormat, dateTimeFormat, numberFormat } from "./Formatting";
import Sugar from "sugar";

function BasicInfoCard(props) {
  const history = useHistory();
  const { loading, data, error } = props;
  const pricesurvey = data || {};

  let dateDiffDays = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
  };

  let now = new Date();
  let end = new Date(pricesurvey.dateEnd);

  let buttons = [
    {
      name: "Vörutafla",
      icon: <i className="fas fa-chevron-right" />,
      size: "sm",
      className: "ml-1",
      variant: "primary",
      disabled: !pricesurvey.hasBeenAccepted,
      title: !pricesurvey.hasBeenAccepted
        ? "Verið að bíða eftir staðfestingu!"
        : "",
      onClick: () =>
        history.push(`/price-surveys/product-report/${pricesurvey.id}`),
    },
    {
      name: "Vöruþróun vöru",
      icon: <i className="fas fa-chart-bar" />,
      size: "sm",
      className: "ml-1",
      variant: "primary",
      disabled: !pricesurvey.hasBeenAccepted,
      title: !pricesurvey.hasBeenAccepted
        ? "Verið að bíða eftir staðfestingu!"
        : "",
      onClick: () =>
        history.push(`/price-surveys/date-graph-report/${pricesurvey.id}`),
    },
    {
      name: "Keðjumismunur vöru",
      icon: <i className="fas fa-chart-bar" />,
      size: "sm",
      className: "ml-1",
      variant: "primary",
      disabled: !pricesurvey.hasBeenAccepted,
      title: !pricesurvey.hasBeenAccepted
        ? "Verið að bíða eftir staðfestingu!"
        : "",
      onClick: () =>
        history.push(`/price-surveys/product-graph-report/${pricesurvey.id}`),
    },
    {
      name: "Mismunur vara í keðju",
      icon: <i className="fas fa-chart-bar" />,
      size: "sm",
      className: "ml-1",
      variant: "primary",
      disabled: !pricesurvey.hasBeenAccepted,
      title: !pricesurvey.hasBeenAccepted
        ? "Verið að bíða eftir staðfestingu!"
        : "",
      onClick: () =>
        history.push(`/price-surveys/franchise-graph-report/${pricesurvey.id}`),
    },
  ];

  const dropdown = [
    {
      name: "Breyta",
      icon: <i className="fas fa-chevron-right" />,
      size: "sm",
      className: "ml-1",
      variant: "primary",
      disabled: loading,
      onClick: () => history.push(`/price-surveys/edit/${pricesurvey.id}`),
    },
    <UploadProfile key={"UploadButton"} id={pricesurvey.id} />,
  ];
  return (
    <MainCard
      title={loading ? "..." : pricesurvey.title}
      buttons={buttons}
      dropdownButtons={dropdown}
    >
      <Row>
        {!loading && pricesurvey.thumbImageUrl && (
          <Col xs={12} md={4}>
            <img
              style={{
                display: "block",
                width: "150px",
                margin: "0px auto 10px",
              }}
              src={pricesurvey.thumbImageUrl + "?w=150"}
            />
          </Col>
        )}
        <Col xs={12} md={!loading && pricesurvey.thumbImageUrl ? 8 : 12}>
          <Table responsive size="sm">
            <tbody>
              <tr key="info-pricesurvey-date-start">
                <th>Tímabil hefst</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateFormat(new Date(pricesurvey.dateStart))}
                </td>
              </tr>
              <tr key="info-pricesurvey-date-end">
                <th>Tímabil lýkur</th>
                <td className="text-right">
                  {loading ? "..." : dateFormat(new Date(pricesurvey.dateEnd))}
                </td>
              </tr>
              <tr key="info-pricesurvey-days-total">
                <th>Fjöldi daga í tímabili</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateDiffDays(pricesurvey.dateEnd, pricesurvey.dateStart)}
                </td>
              </tr>
              {/*
              <tr key="info-pricesurvey-franchises">
                <th>Keðjur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : pricesurvey.franchises.map((franchise) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"franchise-" + franchise.id}
                        >
                          {franchise.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-pricesurvey-category">
                <th>Flokkur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : pricesurvey.filter
                        .filter((f) => f.type == "category")
                        .map((t) => (
                          <Badge
                            className="ml-1"
                            style={{
                              display: "inline-block",
                              float: "left",
                              marginBottom: "5px",
                            }}
                            variant="primary"
                            key={"category-" + t.value}
                          >
                            {t.name}
                          </Badge>
                        ))}
                </td>
              </tr>
              <tr key="info-pricesurvey-subcategory">
                <th>Undirflokkur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : pricesurvey.filter
                        .filter((f) => f.type == "sub_category")
                        .map((t) => (
                          <Badge
                            className="ml-1"
                            style={{
                              display: "inline-block",
                              float: "left",
                              marginBottom: "5px",
                            }}
                            variant="primary"
                            key={"subcategory-" + t.value}
                          >
                            {t.name}
                          </Badge>
                        ))}
                </td>
              </tr>
              <tr key="info-pricesurvey-products">
                <th>Vörur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : pricesurvey.products.map((product) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"product-" + product.id}
                        >
                          {product.title}
                        </Badge>
                      ))}
                </td>
              </tr>
              */}
            </tbody>
          </Table>
        </Col>
      </Row>
      {loading ||
        (!pricesurvey.hasBeenAccepted && (
          <div className="text-warning">
            * Verið að bíða eftir staðfestingu!
          </div>
        ))}
    </MainCard>
  );
}

export const findFirst2Differing = (group) => {
  group = [...group];
  group.sort((r) => r.date);
  group.reverse();
  var diff = group.first(2);
  var i = 0;
  while (diff.length > 1) {
    if (diff[0].price != diff[1].price) {
      break;
    }

    diff = [...group].splice(i++, 2);
  }

  return diff;
};

const GetProductAveragesDates = (dates, points) => {
  const [olderDate, newerDate] = dates;
  if (!olderDate || !newerDate) return [];

  return Object.values(points.groupBy("productId"))
    .filter((g) => g.length > 1)
    .reduce((acc, g) => {
      const product = g.first().product;
      const olderPoint = g.find((p) => p.date == olderDate);
      const newerPoint = g.find((p) => p.date == newerDate);
      var lastChangeProc = 0;
      if (newerPoint && olderPoint) {
        lastChangeProc =
          ((newerPoint.price - olderPoint.price) / olderPoint.price) * 100.0;
      }

      acc.push({
        lastChangeProcentage: lastChangeProc,
        product,
        olderPoint,
        newerPoint,
      });

      return acc;
    }, []);
};

const GetProductAverages = (prices) => {
  return Object.values(prices.groupBy("productId"))
    .filter((g) => g.length > 1)
    .reduce((acc, g) => {
      const product = g.first().product;
      const group = g.sortBy("date");

      const first = group.first();
      const latest = group.last();
      const overallProc = ((latest.price - first.price) / first.price) * 100.0;

      const [newer, older] = findFirst2Differing(group);

      const lastChangeProc =
        newer && older
          ? ((newer.price - older.price) / older.price) * 100.0
          : 0;

      if (overallProc === 0 && lastChangeProc === 0) return acc;

      acc.push({
        overallProcentage: overallProc,
        overallDates: [
          first && dateFormat(first.date),
          latest && dateFormat(latest.date),
        ],
        lastChangeProcentage: lastChangeProc,
        lastChangeDates: [
          older && dateFormat(older.date),
          newer && dateFormat(newer.date),
        ],
        product,
      });

      return acc;
    }, []);
};

function ViewPriceSurvey(props) {
  let pricesurveyId = props.match.params.pricesurveyId;
  const { loading, error, data } = useRest(
    `/api/price-surveys/${pricesurveyId}`
  );

  const { data: trademarks } = useRest(`/api/trademarks`);

  const { data: points = [] } = useRest(
    `/api/price_avgs_per_product_by_survey/${pricesurveyId}`
  );

  if (data === null) {
    return <FailWhale />;
  }

  if (!loading && !data) {
    return (
      <Row className="justify-content-md-center">
        <Col xs md="6" xl="4">
          <MainCard title="Villa">
            <h3 className="font-weight-bold text-muted">
              Villa við birtingu vörukönnunar
            </h3>
          </MainCard>
        </Col>
      </Row>
    );
  }

  const accepted = data?.hasBeenAccepted ?? false;

  const currentPeriod = points
    .map((p) => {
      return p.date;
    })
    .unique()
    .sort()
    .last(2);

  //const avgs = GetProductAverages(points);
  const allAveragePoints = GetProductAveragesDates(currentPeriod, points);

  const unchangedAveragePoints = allAveragePoints.filter(
    (avg) => avg.lastChangeProcentage == 0
  );

  const averagePoints = allAveragePoints.filter(
    (avg) => avg.lastChangeProcentage != 0
  );

  const avgs = (averagePoints || []).map((avg) => avg.lastChangeProcentage);
  const rising_avgs = avgs.filter((p) => p > 0);
  const falling_avgs = avgs.filter((p) => p < 0);

  const highestTrademark = Sugar.Array(averagePoints || [])
    .groupBy((avg) => avg.product.trademarkId)
    .map((group, trademarkId) => {
      const trademark = trademarks?.find((f) => f.id == trademarkId);
      return {
        ...group.max((avg) => avg.lastChangeProcentage),
        trademark,
      };
    })
    .values()
    .max((f) => f.lastChangeProcentage).raw;

  const stats = [
    {
      title: "Fjöldi mældra vara",
      value: allAveragePoints.length || 0,
      description: "Vörur sem eru í báðum mælingum",
    },
    {
      title: "Fjöldi óbreyttra vara",
      value: unchangedAveragePoints.length || 0,
      description: "Vörur sem eru í báðum mælingum",
    },
    {
      title: "Fjöldi lækkandi vara",
      value: falling_avgs.length || 0,
      description: "Fjöldi vara sem lækkuðu á milli mælinga",
    },
    {
      title: "Fjöldi hækkandi vara",
      value: rising_avgs.length || 0,
      description: "Fjöldi vara sem hækkuðu á milli mælinga",
    },
    {
      title: "Meðal hækkun",
      value: Math.round(rising_avgs.average() || 0) + "%",
      description: "Meðal hækkun hækkandi vara á milli mælinga",
    },
    {
      title: "Mesta hækkun",
      value: Math.round(rising_avgs.max() || 0) + "%",
      description: "mesta hækkun vöru á milli mælinga",
    },
    {
      title: "Mest hækkandi Vörumerkið",
      value: `${highestTrademark?.trademark?.title || "N/A"}: ${Math.round(
        highestTrademark?.lastChangeProcentage || 0
      )}%`,
      description: "Mesta hækkun vörumerki á milli mælinga",
    },
    {
      title: "Hækkaði mest í búð",
      value: "Í vinnslu",
      description: "Mesta hækkun í búð á milli mælinga",
    },
  ];

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} xl={12} key={"information-pricesurvey-" + pricesurveyId}>
          <BasicInfoCard loading={loading} data={data} />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <div className="card">
            <div className="card-body text-center">
              <h4 className="card-title text-truncate">
                Síðustu tvær mælingar
              </h4>
              <h4 className="card-title text-truncate">
                {`Frá ${dateFormat(currentPeriod[0])} til ${dateFormat(
                  currentPeriod[1]
                )}`}
              </h4>
            </div>
          </div>
        </Col>
        {stats.map((stat, index) => (
          <Col xs={6} lg={3} key={index}>
            <div className="card" title={stat.description}>
              <div className="card-body text-center">
                <h5 className="card-title text-truncate">{stat.title}</h5>
                <h4>{stat.value}</h4>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row>
        {accepted &&
          (averagePoints || [])
            .sort((a, b) => b.lastChangeProcentage - a.lastChangeProcentage)
            .map((avg) => (
              <Col key={"avg-" + avg.product.id} xs={12} md={12} lg={6} xl={4}>
                <ProductCard2 avg={avg} pricesurveyId={pricesurveyId} />
              </Col>
            ))}
      </Row>
    </React.Fragment>
  );
}

function ProductCard2({ avg, pricesurveyId }) {
  const [isHovering, setHover] = useState(false);
  const cardStyle = {
    cursor: "pointer",
    background: !isHovering ? "white" : "#efefef",
  };
  return (
    <div
      className="card "
      style={cardStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        window.location = `/price-surveys/date-graph-report/${pricesurveyId}?product_id=${avg.product.id}`;
      }}
    >
      <div className="row no-gutters">
        <div className="col-md-5">
          <img
            className="card-img"
            src={
              avg.product?.logoUrl
                ? avg.product.logoUrl + "?fit=fill&fill=solid&w=150&h=150"
                : "https://kurvan-io.imgix.net/default_image.jpg?fit=crop&fill=solid&w=150&h=150"
            }
          />
        </div>
        <div className={"col-md-7"}>
          <div className="card-body text-center p-2 h-100 position-relative">
            <h5
              className="card-title text-truncate font-weight-bold"
              title={avg.product.title}
            >
              <u>{avg.product.title}</u>
            </h5>
            <h4
              className="mt-4"
              style={{
                fontSize: "20px",
                color:
                  0 < avg.lastChangeProcentage
                    ? "hsl(10deg 95% 48%)"
                    : 0 > avg.lastChangeProcentage
                    ? "hsl(88deg 60% 40%)"
                    : "black",
              }}
            >
              {0 < avg.lastChangeProcentage
                ? `${avg.lastChangeProcentage.toFixed(1)}% Verðhækkun`
                : 0 > avg.lastChangeProcentage
                ? `${avg.lastChangeProcentage.toFixed(1)}% Verðlækkun`
                : ""}
            </h4>
            <div className="row position-absolute fixed-bottom p-1">
              <div className="col-8 text-left">
                <span className="font-weight-bold">Tímabil</span>
                <br />
                {dateFormat(avg.olderPoint.date)}: {avg.olderPoint.price} kr
                <br />
                {dateFormat(avg.newerPoint.date)}: {avg.newerPoint.price} kr
                <br />
              </div>
              <div
                className="col-4"
                style={{
                  fontSize: "50px",
                  color:
                    0 < avg.lastChangeProcentage
                      ? "hsl(10deg 95% 48%)"
                      : 0 > avg.lastChangeProcentage
                      ? "hsl(88deg 60% 40%)"
                      : "black",
                }}
              >
                {0 < avg.lastChangeProcentage ? (
                  <i className="fa fa-arrow-circle-up"></i>
                ) : 0 > avg.lastChangeProcentage ? (
                  <i className="fa fa-arrow-circle-down"></i>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function ProductCard({ avg }) {
  return (
    <div className="card">
      <div className="row no-gutters">
        <div className="col-md-5">
          <img
            className="card-img"
            src={
              avg.product?.logoUrl
                ? avg.product.logoUrl + "?fit=fill&fill=solid&w=150&h=150"
                : "https://kurvan-io.imgix.net/default_image.jpg?fit=crop&fill=solid&w=150&h=150"
            }
          />
        </div>
        <div className={"col-md-7"}>
          <div className="card-body text-center">
            <h5
              className="card-title text-truncate text-underscore text-bold"
              title={avg.product.title}
            >
              {avg.product.title}
            </h5>
            <h6
              style={{
                color:
                  0 < avg.lastChangeProcentage
                    ? "hsl(10deg 95% 48%)"
                    : 0 > avg.lastChangeProcentage
                    ? "hsl(88deg 60% 40%)"
                    : "black",
              }}
              title={
                avg.lastChangeDates &&
                avg.lastChangeDates[0] + " - " + avg.lastChangeDates[1]
              }
            >
              {0 < avg.lastChangeProcentage
                ? `Seinasta hækkun var ${avg.lastChangeProcentage.toFixed(1)}%`
                : 0 > avg.lastChangeProcentage
                ? `Seinasta lækkun var ${avg.lastChangeProcentage.toFixed(1)}%`
                : ""}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewPriceSurvey;
