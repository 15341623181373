import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import { LineCanvas } from "@nivo/line";
import { BarCanvas } from "@nivo/bar";
import Sugar from "sugar";

export default function ProductReportPerFranchise(props) {
  //const { data } = GetData();
  const [selectedProduct, setSelectedProduct] = useState();
  let id = props.match.params.id;
  const { data: item } = useRest(`/api/shelf-analysis/${id}`);
  const { data: points } = useRest(`/api/reports/marketshare_report/${id}`);
  const { data: pointsWithDates } = useRest(
    `/api/reports/marketshare_date_report/${id}`
  );

  const Title = (
    <div>
      <div>Markaðshlutfalls skýrsla - {item?.title}</div>
      <div>Flokkur - {item?.category.title}</div>
      <span style={{ fontSize: "18px" }}>
        {dateFormat(item?.dateStart)} - {dateFormat(item?.dateEnd)}
      </span>
    </div>
  );

  var franchises = (points || []).map((p) => p.franchise).unique();
  var suppliers = (points || []).map((p) => p.supplier).unique();

  return (
    <div className="w-100 d-flex">
      <div className="mx-2" style={{ width: "440px" }}>
        <Card title={Title}>
          {/*
          <Select
            classNamePrefix="select"
            className="basic-single form-control"
            placeholder="Vara"
            options={item.products.map((p) => ({ label: p.title, value: p.id }))}
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e)}
          />
          <div style={{ display: "inline-block", textAlign: "center" }}>
            <LineCanvas {...lineProps} data={lineData} />
          </div>
          <div style={{ display: "inline-block", textAlign: "center" }}>
            <b>{selectedStoreName}</b>
            <BarCanvas {...barProps} indexBy="date" data={barStoreData} />
          </div>
          <div style={{ display: "inline-block", textAlign: "center" }}>
            <b>{selectedDate}</b>
            <BarCanvas {...barProps} indexBy="store" data={barDateData} />
          </div>
          */}
        </Card>
      </div>
      <div className="w-100 flex-grow-2 overflow-hidden">
        <div className="rounded-sm mx-2 my-1 bg-white overflow-hidden">
          <Table responsive size="sm" className="p-1">
            <thead>
              <tr>
                <th></th>
                {suppliers.map((s) => (
                  <th>{s.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                Sugar.Object((points || []).groupBy("franchise_id"))
                  .map((val, key) => {
                    const franchiseName = franchises.find(
                      (a) => a.id == key
                    )?.name;
                    return (
                      <tr key={key}>
                        <td>{franchiseName}</td>
                        {
                          Sugar.Object(val.groupBy("supplier_id"))
                            .map((val, key) => {
                              return <td>{val[0].count}</td>;
                            })
                            .values().raw
                        }
                      </tr>
                    );
                  })
                  .values().raw
              }
            </tbody>
          </Table>
        </div>

        {
          Sugar.Object((pointsWithDates || []).groupBy((p) => p.franchise_id))
            .map((val, key) => {
              const name = franchises.find((a) => a.id == key)?.name;
              const supplierGroups = val.groupBy("supplier_id");
              return (
                <div className="rounded-sm mx-2 my-1 bg-white overflow-hidden">
                  <Table responsive size="sm" className="p-1">
                    <thead>
                      <tr>
                        <th colSpan="100%">{name}</th>
                      </tr>
                      <tr>
                        <th></th>
                        {suppliers.map((s) => (
                          <th>{s.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        Sugar.Object(val.groupBy("date"))
                          .map((val, date) => {
                            return (
                              <tr key={key + date}>
                                <td>{date}</td>
                                {
                                  Sugar.Object(val.groupBy("supplier_id"))
                                    .map((val, key) => {
                                      return <td>{val[0].count}</td>;
                                    })
                                    .values().raw
                                }
                              </tr>
                            );
                          })
                          .values().raw
                      }
                    </tbody>
                  </Table>
                </div>
              );
            })
            .values().raw
        }
      </div>
    </div>
  );
}
