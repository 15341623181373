import React, { Component } from "react";
import Select from "react-select";

import { gql, useQuery } from "@apollo/client";
import { GET_USERS } from "../GraphqlQueries/Users";

function UserSelect(props) {
  const { loading, error, data } = useQuery(GET_USERS, {
    fetchPolicy: "cache-first",
  });
  if (error) return <p>Error...</p>;

  const getOptions = (data) => {
    var options = [];
    if (data && data.users) {
      options = data.users.map((cc) => {
        return { value: cc.id, label: cc.name };
      });
    }
    if (props.extraOptions) {
      options = [...props.extraOptions, ...options];
    }
    return options;
  };

  const getValue = (data, value) => {
    const val = getOptions(data).find((v) => v.value === value);
    return val;
  };

  return (
    <Select
      id={props.id}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "0px",
        }),
      }}
      className="basic-single form-control"
      classNamePrefix="select"
      placeholder={props.placeholder || ""}
      isLoading={loading}
      value={getValue(data, props.value)}
      onChange={(e) => props.handleSelectUpdate(e, props.name)}
      name={props.name}
      options={getOptions(data)}
    />
  );
}

export default UserSelect;
