import React, { Fragment, useState } from "react";

function NavSearch() {
  const [searching, setSearching] = useState(false);

  return (
    <Fragment>
      <a
        href="#"
        className="pop-search"
        onClick={() => setSearching(!searching)}
      >
        <i className="feather icon-search" />
      </a>
      <div
        className="search-bar"
        style={{
          display: searching ? "block" : "none",
        }}
      >
        <input
          type="text"
          className="form-control border-0 shadow-none"
          placeholder="Search hear"
        />
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => setSearching(!searching)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </Fragment>
  );
}

export default NavSearch;
