import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import Sugar from "sugar";
Sugar.extend();

import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import Button from "../components/Button";
import UserSelect from "./UserSelect";

import SelectWithData from "./SelectWithData";
import CategorySelect, { GET_CATEGORIES } from "./CategorySelect";
import TrademarkSelect, { GET_TRADEMARKS } from "./TrademarkSelect";
import ProductSelect, { GET_PRODUCTS } from "./ProductSelect";
import SupplierSelect, { GET_SUPPLIERS } from "./SupplierSelect";
import NewCategoryModal from "./Categories/NewCategoryModal";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import { GET_CAMPAIGNS } from "./Campaigns";

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      id
      value: id
      label: name
    }
  }
`;

export const GET_FRANCHISES = gql`
  query GetFranchises {
    options: franchises {
      id
      value: id
      label: name
    }
  }
`;

const CampaignFragment = gql`
  fragment CampaignFields on Campaign {
    id
    title
    categoryId
    productId
    trademarkId
    supplierId
    dateStart
    dateEnd
    goal
    minimumGoal
    result
    goalUnit
    franchises {
      id
      value: id
      label: name
    }
    stores {
      id
      value: id
      label: name
    }
    manager {
      id
      name
    }
    costsExpected {
      production
      radio
      television
      internet
      print
      franchise
      other
    }
    costsReal {
      production
      radio
      television
      internet
      print
      franchise
      other
    }
  }
`;
const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      ...CampaignFields
    }
    stores {
      type: __typename
      value: id
      label: name
    }
    franchises {
      type: __typename
      value: id
      label: name
    }
  }
  ${CampaignFragment}
`;

const DELETE_CAMPAIGN = gql`
  mutation RemoveCampaign($id: ID!) {
    removeCampaign(id: $id)
  }
`;

const ADD_CATEGORIES = gql`
  mutation AddCategory($title: String!) {
    addCategory(title: $title) {
      id
      title
    }
  }
`;

const GoalUnits = [
  { value: "unit", label: "stk" },
  { value: "isk", label: "kr-" },
  { value: "litre", label: "lítrar" },
];

const onChangeHandler =
  (client, campaignId, mutationQuery, name, getFunc, setFunc) => async (e) => {
    var value;
    if (getFunc) {
      value = getFunc(e);
    } else {
      if (e.target === undefined) return;
      value = e.target.value;
    }

    if (value === undefined) return;

    let data = client.readQuery({
      query: GET_CAMPAIGN,
      variables: { id: campaignId },
    });
    let mutData = JSON.parse(JSON.stringify(data));
    if (setFunc) {
      Object.set(mutData, `campaign.${name}`, setFunc(value, e));
    } else {
      Object.set(mutData, `campaign.${name}`, value);
    }
    client.writeQuery({ query: GET_CAMPAIGN, data: mutData });

    await client.mutate({
      mutation: mutationQuery,
      variables: { id: campaignId, value },
      update(cache, data) {},
    });
  };

const actionDeleteCampaign =
  (client, campaignId, setDeleteState) => async () => {
    await client.mutate({
      mutation: DELETE_CAMPAIGN,
      variables: { id: campaignId },
      refetchQueries: [GET_CAMPAIGNS],
    });
    setDeleteState(true);
    window.location = "/campaigns";
  };

const handleFranchisesAndStores =
  (data, client, campaignId) => (storesAndFranchises) => {
    const stores = storesAndFranchises.filter((sf) => sf.type === "Store");
    const franchises = storesAndFranchises.filter(
      (sf) => sf.type === "Franchise"
    );

    handleStores(stores);
    handleFranchises(franchises);
  };

function EditCampaign(props) {
  const { loading, data, campaignId } = props;
  const client = useApolloClient();
  const [campaign, setCampaign] = useState({});
  const [deleteState, setDeleteState] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    if (data) setCampaign(data.campaign);
  }, [data]);

  const onChangeHandler = (name, getFunc, setFunc) => (e) => {
    var value;
    if (getFunc) {
      value = getFunc(e);
    } else {
      if (e.target === undefined) return;
      value = e.target.value;
    }

    if (value === undefined) return;

    let mutData = JSON.parse(JSON.stringify(campaign));
    if (setFunc) {
      Object.set(mutData, `${name}`, setFunc(value, e));
    } else {
      Object.set(mutData, `${name}`, value);
    }

    setCampaign(mutData);
  };

  const onSubmit = async () => {
    var data = {
      ...campaign,
      franchises: campaign.franchises.map(f=> f.id),
      stores: campaign.stores.map(f=> f.id),
    }
    await axios.post(`/api/campaigns/${campaignId}`, data);
  };

  let costExpect = 0;
  let costReal = 0;
  if (!loading) {
    costExpect = Object.sum(campaign.costsExpected, function (i) {
      if (!Object.isNumber(i)) return 0;
      return i;
    });

    costReal = Object.sum(campaign.costsReal, function (i) {
      if (!Object.isNumber(i)) return 0;
      return i;
    });
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 5, offset: 1 }}>
          <Card title="Herferð (breyta)">
            <Button
              style={{
                position: "absolute",
                top: "20px",
                right: "20px",
              }}
              size="sm"
              variant="primary"
              disabled={loading}
              href={"/campaigns/" + props.campaignId}
            >
              Til baka
              <i className="fas fa-chevron-right" />
            </Button>
            <Form.Group>
              <Form.Row key={"campaign-form-title"}>
                <Form.Label column lg={5}>
                  Heiti herferðar
                </Form.Label>
                <Col>
                  {loading ? (
                    <Form.Control type={"text"} disabled={true} value={"..."} />
                  ) : (
                    <Form.Control
                      name={"title"}
                      type={"text"}
                      value={campaign.title}
                      onChange={onChangeHandler("title")}
                    />
                  )}
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-supplier"}>
                <Form.Label column lg={5}>
                  Birgi
                </Form.Label>
                <Col>
                  <InputGroup>
                    <SupplierSelect
                      handleSelectUpdate={onChangeHandler(
                        "supplierId",
                        (e) => e.value
                      )}
                      value={loading ? null : campaign.supplierId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-trademark"}>
                <Form.Label column lg={5}>
                  Vörumerki
                </Form.Label>
                <Col>
                  <InputGroup>
                    <TrademarkSelect
                      handleSelectUpdate={onChangeHandler(
                        "trademarkId",
                        (e) => e.value
                      )}
                      value={loading ? null : campaign.trademarkId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-product"}>
                <Form.Label column lg={5}>
                  Vara
                </Form.Label>
                <Col>
                  <InputGroup>
                    <ProductSelect
                      handleSelectUpdate={onChangeHandler(
                        "productId",
                        (e) => e.value
                      )}
                      value={loading ? null : campaign.productId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-category"}>
                <Form.Label column lg={5}>
                  Flokkur
                </Form.Label>
                <Col>
                  <InputGroup>
                    <CategorySelect
                      handleSelectUpdate={onChangeHandler(
                        "categoryId",
                        (e) => e.value
                      )}
                      value={loading ? null : campaign.categoryId}
                    />
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-dateStart"}>
                <Form.Label column lg={5}>
                  Herferð hefst
                </Form.Label>
                <Col>
                  <DatePicker
                    selected={
                      loading ? undefined : new Date(campaign.dateStart)
                    }
                    wrapperClassName="w-100"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={onChangeHandler("dateStart", (e) =>
                      e.toISOString()
                    )}
                    className="form-control"
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-dateEnd"}>
                <Form.Label column lg={5}>
                  Herferð lýkur
                </Form.Label>
                <Col>
                  <DatePicker
                    selected={loading ? undefined : new Date(campaign.dateEnd)}
                    wrapperClassName="w-100"
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    onChange={onChangeHandler("dateEnd", (e) =>
                      e.toISOString()
                    )}
                    className="form-control"
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-goal"}>
                <Form.Label column lg={5}>
                  Sölumarkmið
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? 0 : campaign.goal}
                      onValueChange={onChangeHandler(
                        "goal",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: "90px",
                            borderRadius: "0px",
                          }),
                        }}
                        name={"goalUnit"}
                        className="basic-single form-control"
                        classNamePrefix="select"
                        value={
                          loading
                            ? undefined
                            : GoalUnits.find(
                                (i) => i.value == campaign.goalUnit
                              )
                        }
                        options={GoalUnits}
                        onChange={onChangeHandler("goalUnit", (e) => e.value)}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-minimum-goal"}>
                <Form.Label column lg={5}>
                  Lámarks sölumarkmið
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? 0 : campaign.minimumGoal}
                      onValueChange={onChangeHandler(
                        "minimumGoal",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: "90px",
                            borderRadius: "0px",
                          }),
                        }}
                        name={"goalUnit"}
                        className="basic-single form-control"
                        classNamePrefix="select"
                        value={
                          loading
                            ? undefined
                            : GoalUnits.find(
                                (i) => i.value == campaign.goalUnit
                              )
                        }
                        options={GoalUnits}
                        onChange={onChangeHandler("goalUnit", (e) => e.value)}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-result"}>
                <Form.Label column lg={5}>
                  Árangur
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? 0 : campaign.result}
                      onValueChange={onChangeHandler(
                        "result",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <Select
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: "90px",
                            borderRadius: "0px",
                          }),
                        }}
                        name={"goalUnit"}
                        className="basic-single form-control"
                        classNamePrefix="select"
                        value={
                          loading
                            ? undefined
                            : GoalUnits.find(
                                (i) => i.value == campaign.goalUnit
                              )
                        }
                        options={GoalUnits}
                        onChange={onChangeHandler("goalUnit", (e) => e.value)}
                      />
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-manager"}>
                <Form.Label column lg={5}>
                  Ábyrgðarmaður
                </Form.Label>
                <Col>
                  <UserSelect
                    handleSelectUpdate={onChangeHandler(
                      "managerId",
                      (e) => e.value
                    )}
                    value={loading ? null : campaign.manager?.id}
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-franchises"}>
                <Form.Label column lg={5}>
                  Keðjur
                </Form.Label>
                <Col>
                  {(loading ? [] : campaign?.franchises || []).map((s) => (
                    <ButtonGroup size="sm" className="mr-1 mb-1">
                      <Button
                        variant="primary"
                        key={"franchise-" + s.value}
                        onClick={() => {
                          const update = onChangeHandler(
                            "franchises",
                            (result) =>
                              (result || []).map((option) => option.value),
                            (val, e) => e || []
                          );
                          const newFranchises = campaign.franchises.filter(
                            (o) => o.value != s.value
                          );
                          update(newFranchises);
                        }}
                      >
                        {s.label} <i className="fas fa-times" />
                      </Button>
                    </ButtonGroup>
                  ))}
                  <SelectWithData
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    isMulti
                    query={GET_FRANCHISES}
                    placeholder={""}
                    onChange={onChangeHandler(
                      "franchises",
                      (result) => (result || []).map((option) => option.value),
                      (val, e) => e || []
                    )}
                    value={loading ? undefined : campaign.franchises}
                  />
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-stores"}>
                <Form.Label column lg={5}>
                  Verslanir
                </Form.Label>
                <Col>
                  {(loading ? [] : campaign?.stores || []).map((s) => (
                    <ButtonGroup size="sm" className="mr-1 mb-1">
                      <Button
                        variant="primary"
                        key={"store-" + s.value}
                        onClick={() => {
                          const update = onChangeHandler(
                            "stores",
                            (result) =>
                              (result || []).map((option) => option.value),
                            (val, e) => e || []
                          );
                          const newStores = campaign.stores.filter(
                            (o) => o.value != s.value
                          );
                          update(newStores);
                        }}
                      >
                        {s.label} <i className="fas fa-times" />
                      </Button>
                    </ButtonGroup>
                  ))}
                  <SelectWithData
                    closeMenuOnSelect={false}
                    controlShouldRenderValue={false}
                    isMulti
                    query={GET_STORES}
                    placeholder={""}
                    onChange={onChangeHandler(
                      "stores",
                      (result) => (result || []).map((option) => option.value),
                      (val, e) => e || []
                    )}
                    value={loading ? undefined : campaign.stores}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
          </Card>
        </Col>
        <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 5, offset: 0 }}>
          <Card title="Áætlaður kostnaður">
            <Form.Group>
              <Form.Row key={"campaign-form-cc-production"}>
                <Form.Label column lg={5}>
                  Framleiðsla efnis
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading
                          ? undefined
                          : campaign?.costsExpected?.production
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.production",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-radio"}>
                <Form.Label column lg={5}>
                  Útvarp
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      onValueChange={onChangeHandler(
                        "costsExpected.radio",
                        (e) => e.floatValue
                      )}
                      value={
                        loading ? undefined : campaign?.costsExpected?.radio
                      }
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-television"}>
                <Form.Label column lg={5}>
                  Sjónvarp
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading
                          ? undefined
                          : campaign?.costsExpected?.television
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.television",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-internet"}>
                <Form.Label column lg={5}>
                  Netmiðlar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsExpected?.internet
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.internet",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-print"}>
                <Form.Label column lg={5}>
                  Prentmiðlar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsExpected?.print
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.print",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-franchise"}>
                <Form.Label column lg={5}>
                  Markaðsfé til verslunar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsExpected?.franchise
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.franchise",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cc-other"}>
                <Form.Label column lg={5}>
                  Annar kostnaður
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsExpected?.other
                      }
                      onValueChange={onChangeHandler(
                        "costsExpected.other",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key="campaign-form-cost-total">
                <Form.Label column lg={5}>
                  Heildarkostnaður
                </Form.Label>
                <Form.Label className="col-form-label">
                  {currencyFormat(costExpect)}
                </Form.Label>
              </Form.Row>
            </Form.Group>
          </Card>
          <Card title="Raun kostnaður">
            <Form.Group>
              <Form.Row key={"campaign-form-cr-production"}>
                <Form.Label column lg={5}>
                  Framleiðsla efnis
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsReal?.production
                      }
                      onValueChange={onChangeHandler(
                        "costsReal.production",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row key={"campaign-form-cr-radio"}>
                <Form.Label column lg={5}>
                  Útvarp
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? undefined : campaign?.costsReal?.radio}
                      onValueChange={onChangeHandler(
                        "costsReal.radio",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row key={"campaign-form-cr-television"}>
                <Form.Label column lg={5}>
                  Sjónvarp
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsReal?.television
                      }
                      onValueChange={onChangeHandler(
                        "costsReal.television",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row key={"campaign-form-cr-internet"}>
                <Form.Label column lg={5}>
                  Netmiðlar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsReal?.internet
                      }
                      onValueChange={onChangeHandler(
                        "costsReal.internet",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row key={"campaign-form-cr-print"}>
                <Form.Label column lg={5}>
                  Prentmiðlar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? undefined : campaign?.costsReal?.print}
                      onValueChange={onChangeHandler(
                        "costsReal.print",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>
              <Form.Row key={"campaign-form-cr-franchise"}>
                <Form.Label column lg={5}>
                  Markaðsfé til verslunar
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={
                        loading ? undefined : campaign?.costsReal?.franchise
                      }
                      onValueChange={onChangeHandler(
                        "costsReal.franchise",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key={"campaign-form-cr-other"}>
                <Form.Label column lg={5}>
                  Annar kostnaður
                </Form.Label>
                <Col>
                  <InputGroup>
                    <NumberFormat
                      value={loading ? undefined : campaign?.costsReal?.other}
                      onValueChange={onChangeHandler(
                        "costsReal.other",
                        (e) => e.floatValue
                      )}
                      thousandSeparator="."
                      decimalSeparator=","
                      className="form-control"
                      allowNegative={false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>ISK</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Form.Row>

              <Form.Row key="campaign-form-cost-total">
                <Form.Label column lg={5}>
                  Heildarkostnaður
                </Form.Label>
                <Form.Label className="col-form-label">
                  {currencyFormat(costReal)}
                </Form.Label>
              </Form.Row>
            </Form.Group>
          </Card>
        </Col>
        <Col xs={12} xl={{ span: 10, offset: 1 }}>
          <Card noHeader>
            <div className="text-right">
              {!loading && (
                <button
                  data-test="remove"
                  type="button"
                  className="btn btn-danger mr-1"
                  onClick={() => {
                    setShowConfirmDelete(true);
                  }}
                >
                  Eyða
                </button>
              )}
              <button
                data-test="submit"
                type="submit"
                disabled={submitting}
                className="btn btn-success"
                onClick={onSubmit}
              >
                Vista
              </button>
            </div>
          </Card>
        </Col>
        <Confirm
          show={showConfirmDelete}
          title="Ertu viss?"
          onConfirm={async () => {
            actionDeleteCampaign(client, props.campaignId, setDeleteState)();
            setShowConfirmDelete(false);
          }}
          onClose={() => {
            setShowConfirmDelete(false);
          }}
        />
      </Row>
      <Row></Row>
    </React.Fragment>
  );
}

function EditCampaignWithData(props) {
  const campaignId = props.match.params.campaignId;
  const client = useApolloClient();
  const { loading, error, data } = useQuery(GET_CAMPAIGN, {
    variables: { id: campaignId },
    options: {
      fetchPolicy: "cache-first",
    },
  });

  if (error) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Grunn upplýsingar">
            <h3>{error.message}</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  if (data && data.campaign == null) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Villa">
            <h3>Herferð fannst ekki</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <EditCampaign
      campaignId={campaignId}
      loading={loading}
      data={data}
    />
  );
}
export default EditCampaignWithData;
