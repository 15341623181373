import { useState, useEffect } from "react";

export function useBrowserLanguage() {
  return window.navigator.userLanguage || window.navigator.language || "en-US";
}

export function useNumberFormat(props) {
  const lang = useBrowserLanguage();
  return new Intl.NumberFormat(lang, props);
}

export function useCurrencyFormat(props) {
  if (typeof myVar === "string" || myVar instanceof String) {
    props = { currency: props };
  }
  const lang = useBrowserLanguage();

  return new Intl.NumberFormat(lang, {
    style: "currency",
    currency: "ISK",
    ...props,
  });
}

// From https://usehooks.com/useWindowSize/
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

//From: https://usehooks.com/useAsync/
export function useAsync(asyncFunction, immediate = true) {
  const [status, setStatus] = useState("idle");
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(() => {
    setStatus("pending");
    setValue(null);
    setError(null);
    return asyncFunction()
      .then((response) => {
        setValue(response);
        setStatus("success");
      })
      .catch((error) => {
        setError(error);
        setStatus("error");
      });
  }, [asyncFunction]);
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, error };
}

export default {
  useBrowserLanguage,
  useNumberFormat,
  useCurrencyFormat,
  useWindowSize,
  useAsync,
};
