import React, { Component } from "react";
import Select from "react-select";

import { gql, useQuery } from "@apollo/client";

function SelectWithData({ query, onChange, value, ...props }) {
  const { loading, error, data } = useQuery(query);
  if (error) return <p>Error...</p>;

  const getOptions = (data) => {
    if (data && data.options) {
      return data.options;
    }
    return [];
  };

  const getValue = (data, value) => {
    if (data && data.options) {
      if (props.isMulti && value && value.constructor.name == "Array") {
        return value.map((v) => data.options.find((o) => o.value === v.value));
      } else {
        const val = data.options.find((v) => v.value === value);
        return val;
      }
    }
    return null;
  };

  return (
    <Select
      {...props}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: "0px",
        }),
      }}
      className="basic-single form-control"
      classNamePrefix="select"
      isLoading={loading}
      value={getValue(data, value)}
      onChange={(e) => {
        onChange(e);
      }}
      options={getOptions(data)}
    />
  );
}

export default SelectWithData;
