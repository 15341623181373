import React, { Fragment, useContext, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";

import NavContent from "./NavContent";
import OutsideClick from "./OutsideClick";
import * as actionTypes from "./../../../../store/actions";
import navigation from "../../../../menu-items";
import GlobalContext from "../../../../GlobalContext";
import { useWindowSize } from "../../../../utils/reactHooks";

function Navigation(props) {
  const {
    state: {
      layout,
      subLayout,
      collapseMenu,
      layoutType,
      navFixedLayout,
      headerFixedLayout,
      boxLayout,
    },
    dispatch,
  } = useContext(GlobalContext);
  const windowSize = useWindowSize();
  const location = useLocation();

  const onToggleNavigation = () =>
    dispatch({ type: actionTypes.COLLAPSE_MENU });
  const onChangeLayout = (layout) =>
    dispatch({ type: actionTypes.CHANGE_LAYOUT, layout: layout });

  // On Resize
  // TODO (andri): We probably don't need this?
  useEffect(() => {
    const contentWidth = document.getElementById("root").clientWidth;

    if (layout === "horizontal" && contentWidth < 992) {
      onChangeLayout("vertical");
    }
  }, [windowSize]);

  const scroll = () => {
    if (navFixedLayout && headerFixedLayout === false) {
      const el = document.querySelector(".pcoded-navbar.menupos-fixed");
      const scrollPosition = window.pageYOffset;
      if (scrollPosition > 60) {
        el.style.position = "fixed";
        el.style.transition = "none";
        el.style.marginTop = "0";
      } else {
        el.style.position = "absolute";
        el.style.marginTop = "60px";
      }
    } else {
      document.querySelector(".pcoded-navbar").removeAttribute("style");
    }
  };

  let navClass = ["pcoded-navbar", layoutType];

  if (layout === "horizontal") {
    navClass = [...navClass, "theme-horizontal"];
  } else {
    if (navFixedLayout) {
      navClass = [...navClass, "menupos-fixed"];
    }

    if (navFixedLayout && !headerFixedLayout) {
      window.addEventListener("scroll", scroll, true);
      window.scrollTo(0, 0);
    } else {
      window.removeEventListener("scroll", scroll, false);
    }
  }

  if (windowSize.width < 992 && collapseMenu) {
    navClass = [...navClass, "mob-open"];
  } else if (collapseMenu) {
    navClass = [...navClass, "navbar-collapsed"];
  }

  if (boxLayout) {
    document.body.classList.add("container");
    document.body.classList.add("box-layout");
  } else {
    document.body.classList.remove("container");
    document.body.classList.remove("box-layout");
  }

  let navBarClass = ["navbar-wrapper"];
  if (layout === "horizontal" && subLayout === "horizontal-2") {
    navBarClass = [...navBarClass, "container"];
  }

  let navContent = (
    <div className={navBarClass.join(" ")}>
      <NavContent navigation={navigation.items} />
    </div>
  );
  if (windowSize.width < 992) {
    navContent = (
      <OutsideClick>
        <div className="navbar-wrapper">
          <NavContent navigation={navigation.items} />
        </div>
      </OutsideClick>
    );
  }

  return (
    <Fragment>
      <nav className={navClass.join(" ")}>{navContent}</nav>
    </Fragment>
  );
}

export default Navigation;
