import React from "react";

import { initialState } from "./store/reducer";

export const GlobalContext = React.createContext({
  state: null,
  dispatch: null,
});

export default GlobalContext;
