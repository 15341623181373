import React, { Fragment, useContext, useEffect } from "react";

import * as actionTypes from "../../../../../../store/actions";
import NavIcon from "./../NavIcon";
import NavBadge from "./../NavBadge";
import NavItem from "../NavItem";
import LoopNavCollapse from "./index";
import GlobalContext from "../../../../../../GlobalContext";
import { useLocation } from "react-router-dom";

function NavCollapse({ collapse, type }) {
  const {
    state: { layout, isOpen, isTrigger },
    dispatch,
  } = useContext(GlobalContext);
  const location = useLocation();

  const onCollapseToggle = (id, type) =>
    dispatch({
      type: actionTypes.COLLAPSE_TOGGLE,
      menu: { id: id, type: type },
    });
  const onNavCollapseLeave = (id, type) =>
    dispatch({
      type: actionTypes.NAV_COLLAPSE_LEAVE,
      menu: { id: id, type: type },
    });

  useEffect(() => {
    const currentIndex = location.pathname
      .toString()
      .split("/")
      .findIndex((id) => id === collapse.id);
    if (currentIndex > -1) {
      onCollapseToggle(collapse.id, type);
    }
  }, []);

  let navItems = "";
  if (collapse.children) {
    const collapses = collapse.children;
    navItems = Object.keys(collapses).map((item) => {
      item = collapses[item];
      switch (item.type) {
        case "collapse":
          return <LoopNavCollapse key={item.id} collapse={item} type="sub" />;
        case "item":
          return <NavItem layout={layout} key={item.id} item={item} />;
        default:
          return false;
      }
    });
  }

  let itemTitle = collapse.title;
  if (collapse.icon) {
    itemTitle = <span className="pcoded-mtext">{collapse.title}</span>;
  }

  let navLinkClass = ["nav-link"];

  let navItemClass = ["nav-item", "pcoded-hasmenu"];
  const openIndex = isOpen.findIndex((id) => id === collapse.id);
  if (openIndex > -1) {
    navItemClass = [...navItemClass, "active"];
    if (layout !== "horizontal") {
      navLinkClass = [...navLinkClass, "active"];
    }
  }

  const triggerIndex = isTrigger.findIndex((id) => id === collapse.id);
  if (triggerIndex > -1) {
    navItemClass = [...navItemClass, "pcoded-trigger"];
  }

  const currentIndex = location.pathname
    .toString()
    .split("/")
    .findIndex((id) => id === collapse.id);
  if (currentIndex > -1) {
    navItemClass = [...navItemClass, "active"];
    if (layout !== "horizontal") {
      navLinkClass = [...navLinkClass, "active"];
    }
  }

  if (collapse.classes) {
    navItemClass = [...navItemClass, collapse.classes];
  }

  const subContent = (
    <Fragment>
      <a
        href="#"
        className={navLinkClass.join(" ")}
        onClick={() => onCollapseToggle(collapse.id, type)}
      >
        <NavIcon items={collapse} />
        {itemTitle}
        <NavBadge layout={layout} items={collapse} />
      </a>
      <ul className="pcoded-submenu">{navItems}</ul>
    </Fragment>
  );
  let mainContent = "";
  if (layout === "horizontal") {
    mainContent = (
      <li
        className={navItemClass.join(" ")}
        onMouseLeave={() => onNavCollapseLeave(collapse.id, type)}
        onMouseEnter={() => onCollapseToggle(collapse.id, type)}
      >
        {subContent}
      </li>
    );
  } else {
    mainContent = <li className={navItemClass.join(" ")}>{subContent}</li>;
  }

  return <Fragment>{mainContent}</Fragment>;
}

export default NavCollapse;
