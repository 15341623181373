import React, { Suspense, Fragment } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { reporter } from "../../../logging";

import Navigation from "./Navigation";
import NavBar from "./NavBar";
import Breadcrumb from "./Breadcrumb";
import Loader from "../Loader";
import FailWhale from "../../../components/FailWhale";
import routes from "../../../routes";

export const GET_VERSION = gql`
  query SystemVersion {
    version
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    const logger = reporter(this.props.version);
    const res = logger.report(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      //return <h1>Something went wrong.</h1>;
      return <FailWhale />;
    }
    return this.props.children;
  }
}

function AdminLayout() {
  const { data } = useQuery(GET_VERSION);

  function mobileOutClickHandler() {
    //This was supposed to trigger the closing of the menu
    //dispatch({type: actionTypes.COLLAPSE_MENU})
  }

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => (
          <ErrorBoundary
            key={"error-" + index}
            version={data?.version ?? "-prefetch"}
          >
            <route.component {...props} />
          </ErrorBoundary>
        )}
      />
    ) : null;
  });

  return (
    <Fragment>
      <Navigation />
      <NavBar />
      <div
        className="pcoded-main-container"
        onClick={() => mobileOutClickHandler}
      >
        <div className="pcoded-wrapper">
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <Breadcrumb />
              <div className="main-body">
                <div className="page-wrapper">
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      {menu}
                      <Redirect from="/" to="/" />
                    </Switch>
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="SystemVersionNumber">Útgáfa: {data?.version ?? ""}</div>
    </Fragment>
  );
}

export default AdminLayout;
