import React, { Component, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Card, Row, Col, Button, Form, Table, Modal } from "react-bootstrap";

import MainCard from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import UserProfileImage from "../components/UserProfileImage";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import UserSelect from "./UserSelect";

import { gql, useQuery, useMutation } from "@apollo/client";

import CreateTaskModal from "./CreateTaskModal";
import { hasData } from "../utils/Graphql";

export const GET_TASKS = gql`
  query GetTasks {
    allTasks {
      id
      title
      dueDate
      closed
      assignee {
        id
        name
        profileImageUrl
      }
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($id: ID!, $title: String!) {
    updated: updateTask(id: $id, title: $title) {
      id
      title
      dueDate
      closed
      assignee {
        id
        name
        profileImageUrl
      }
    }
  }
`;

const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleted: deleteTask(id: $id) {
      id
      title
      dueDate
      closed
      assignee {
        id
        name
        profileImageUrl
      }
    }
  }
`;

const CLOSE_TASK = gql`
  mutation CloseTask($id: ID!, $closed: Boolean!) {
    closed: updateTask(id: $id, closed: $closed) {
      id
      title
      dueDate
      closed
      assignee {
        id
        name
        profileImageUrl
      }
    }
  }
`;

export function CloseTask({ item, style }) {
  const [updating, setUpdating] = useState(false);

  const [closeTaskMut] = useMutation(CLOSE_TASK, {
    onCompleted: () => {
      console.log("update done");
      setUpdating(false);
    },
  });

  const toggleClosedStatus = (task) => {
    setUpdating(true);
    closeTaskMut({
      variables: { id: task.id, closed: !task.closed },
    });
  };

  return (
    <div
      style={{
        cursor: "pointer",
        ...style,
      }}
      className={"task-checkbox " + (item?.closed ? "closed" : "not-closed")}
      onClick={(e) => {
        e.stopPropagation();
        toggleClosedStatus(item);
      }}
    >
      {updating ? (
        <i className="fa fa-hourglass" title="Loading..." />
      ) : (
        <i className="fa fa-check-circle" />
      )}
    </div>
  );
}

function TaskItem(props) {
  const history = useHistory();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { item } = props;
  return (
    <React.Fragment>
      <tr
        key={"task-" + item.id}
        id={"task-" + item.id}
        data-id={item.id}
        onClick={() => history.push(`/tasks/${item.id}`)}
      >
        <td className="align-middle p-2">
          <CloseTask item={item} />
        </td>
        <td className="align-middle p-2">{item.title}</td>
        <td className="align-middle p-2">
          <UserProfileImage size={40} user={item.assignee} />
          {item.assignee?.name}
        </td>
        <td className="align-middle p-2">
          {item.dueDate ? dateFormat(new Date(item.dueDate)) : ""}
        </td>
        <td className="align-middle p-2">
          <Button
            size="sm"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <Confirm
        show={showConfirmDelete}
        title="Ertu viss?"
        onConfirm={async () => {
          props.removeTask(item);
          setShowConfirmDelete(false);
        }}
        onClose={() => {
          setShowConfirmDelete(false);
        }}
      />
    </React.Fragment>
  );
}
function Tasklist(props) {
  const { loading, error, data } = useQuery(GET_TASKS);

  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  return data.allTasks
    .filter((item) => {
      if (props.filter.user && props.filter.user.value !== "") {
        if (item.assignee?.id != props.filter.user.value) return false;
      }
      if (props.filter.complete && props.filter.complete.value !== "") {
        if (item.closed != props.filter.complete.value) return false;
      }

      return true;
    })
    .map((item) => <TaskItem {...props} key={item.id} item={item} />);
}
function Tasks(props) {
  const [filter, setFilter] = useState({
    complete: { value: false, label: "Ólokið" },
    user: { value: "", label: "Allir ábyrðaraðilar" },
  });

  const [deleteTask] = useMutation(DELETE_TASK, {
    update(cache, { data: { deleted } }) {
      const queryResult = cache.readQuery({
        query: GET_TASKS,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_TASKS,
          data: {
            allTasks: queryResult.allTasks.filter(
              (task) => task.id !== deleted.id
            ),
          },
        });
      }
    },
  });

  const removeTask = (task) => {
    deleteTask({
      optimisticResponse: { deleted: { id: task.id } },
      variables: { id: task.id },
    });
  };

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <MainCard noHeader>
            <Row>
              <Col xs={12} md={12}>
                <h5>Leit</h5>
              </Col>
              <Col xs={12} md={3} key="search-user">
                <UserSelect
                  placeholder="Ábyrðaraðili"
                  extraOptions={[
                    { value: "", label: "Allir ábyrðaraðilar" },
                    { value: null, label: "Enginn" },
                  ]}
                  value={filter.user.value}
                  handleSelectUpdate={(e) => {
                    setFilter({
                      ...filter,
                      user: e,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={3} key="search-complete">
                <Select
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder="Lokið"
                  options={[
                    { value: "", label: "Allt" },
                    { value: false, label: "Ólokið" },
                    { value: true, label: "Lokið" },
                  ]}
                  value={filter.complete}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      complete: e,
                    });
                  }}
                />
              </Col>
            </Row>
          </MainCard>
          <MainCard title="Verkefni">
            <CreateTaskModal
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
            >
              {(props) => <Button {...props}>Nýtt verkefni</Button>}
            </CreateTaskModal>
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Titill</th>
                  <th>Ábyrgð</th>
                  <th>Þarf að klárast fyrir</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <Tasklist filter={filter} removeTask={removeTask} />
              </tbody>
            </Table>
          </MainCard>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Tasks;
