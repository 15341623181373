import React, { Component, useState, useEffect } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import Select from "react-select";
import styled from "styled-components";

import Card from "../App/components/MainCard";
import NoResults from "../App/components/NoResults";
import Loader from "../App/layout/Loader";
import AngledBanner from "../components/AngledBanner";
import UserProfileImage from "../components/UserProfileImage";
import YearlyHeatmap from "../components/YearlyHeatmap";

import { gql, useQuery, useMutation } from "@apollo/client";

import { GET_USERS } from "../GraphqlQueries/Users";

const GET_STATS = gql`
  query {
    stats {
      date
      users {
        key
        value {
          projects {
            active
            newPhotos
            newWarning
            rated
          }
        }
      }
    }
  }
`;

function UserCampaignHeatmap(props) {
  const { loading, error, data } = useQuery(GET_USERS);
  const [statList, setStatList] = useState({});
  const { data: statData } = useQuery(GET_STATS);

  useEffect(() => {
    const buffer = {};
    (statData?.stats || []).map((item) => {
      buffer[new Date(item.date).format("%F")] = item;
    });
    setStatList(buffer);
  }, [statData]);

  const users = (data || {}).users;

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        {users?.map((u) => (
          <Col key={u.id} xs={12} md={12} lg={12} xl={6}>
            <div
              style={{
                marginBottom: 25,
                background: "white",
                position: "relative",
              }}
            >
              <div
                style={{
                  padding: 25,
                }}
              >
                <UserProfileImage
                  size={32}
                  user={u}
                  style={{ float: "left", marginRight: 10 }}
                />
                <span style={{ fontSize: 32, lineHeight: "32px" }}>
                  {u.name}
                </span>
              </div>
              <YearlyHeatmap
                key={u.id}
                // Defaults to current year
                //year={1986}
                onDay={(day) => {
                  const formattedDay = day.format("%F");
                  const dayStats = statList[formattedDay] || undefined;

                  if (!dayStats)
                    return {
                      color: "#dddddd",
                      title: formattedDay,
                    };

                  const userList =
                    dayStats.users.find((i) => i.key === u.id) || [];

                  if (userList.length < 1)
                    return {
                      color: "#dddddd",
                      title: formattedDay,
                    };

                  const stats = userList.value;

                  const {
                    active,
                    newPhotos,
                    newWarning,
                    rated,
                  } = stats.projects;

                  // We should probably do something better here
                  const score = active;
                  let bgColor = "#dddddd";
                  if (score >= 9) bgColor = "#6e3807";
                  if (score == 8) bgColor = "#8e480a";
                  if (score == 7) bgColor = "#ad580c";
                  if (score == 6) bgColor = "#cd680e";
                  if (score == 5) bgColor = "#f0811e";
                  if (score == 4) bgColor = "#f2933e";
                  if (score == 3) bgColor = "#f4a55e";
                  if (score == 2) bgColor = "#f6b67e";
                  if (score == 1) bgColor = "#f8c89d";

                  return {
                    color: bgColor,
                    title: JSON.stringify({
                      projects: { active, newPhotos, newWarning, rated },
                      day: formattedDay,
                    }),
                  };
                }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
}

export default UserCampaignHeatmap;
