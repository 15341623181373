import React, { useState, useContext, useRef, useEffect } from "react";

import GlobalContext from "../../../../../GlobalContext";
import * as actionTypes from "../../../../../store/actions";

import { useWindowSize } from "../../../../../utils/reactHooks";

function OutsideClick({ children }) {
  const wrapperRef = useRef();
  const { height, width: windowWidth } = useWindowSize();
  const {
    state: { collapseMenu },
    dispatch,
  } = useContext(GlobalContext);
  const onToggleNavigation = () =>
    dispatch({ type: actionTypes.COLLAPSE_MENU });

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  /**
   * close menu if clicked on outside of element
   */
  function handleOutsideClick(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      if (windowWidth < 992 && collapseMenu) {
        onToggleNavigation();
      }
    }
  }

  return (
    <div className="nav-outside" ref={wrapperRef}>
      {children}
    </div>
  );
}

export default OutsideClick;
