import React, { Component } from "react";

import NavSearch from "./NavSearch";

export function NavLeft() {
  return (
    <ul className="navbar-nav mr-auto">
      {/*<li className="nav-item"><NavSearch/></li>*/}
    </ul>
  );
}

export default NavLeft;
