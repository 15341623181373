import React from "react";

import RNG from "../utils/RNG";
import { invertColor } from "../utils/Color";

export default function UserProfileImage({
  user,
  size,
  style,
  title,
  ...props
}) {
  if (size === undefined) size = 40;
  if (user === undefined || user === null) user = {}; //

  const name = user.name || "";

  if (!user.profileImageUrl) {
    const color = RNG(name.trim()).nextHexColor();
    const iColor = invertColor(color, true /* Black and White */);
    return (
      <svg
        style={{
          height: size,
          width: size,
          marginRight: size / 4,
          cursor: "default",
          ...style,
        }}
        {...props}
        viewBox="0 0 20 20"
      >
        <g id="UrTavla">
          <circle cx="50%" cy="50%" r="10" fill={color}>
            {title && <title>{title}</title>}
          </circle>
          <text x="50%" y="50%" textAnchor="middle" fill={iColor} dy=".35em">
            {name.length > 0 ? name[0].toUpperCase() : "?"}
            {title && <title>{title}</title>}
          </text>
        </g>
      </svg>
    );
  }

  return (
    <img
      {...{
        title,
        style: {
          borderRadius: "50%",
          cursor: "default",
          ...style,
        },
        height: size,
        width: size,
        src: user.profileImageUrl,
        ...props,
      }}
    />
  );
}
