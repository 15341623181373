import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { UserContext } from "./../UserContext";

import Login from "../Pages/Login";
import Loader from "../App/layout/Loader";

const PrivateRoute = ({ component, ...rest }) => {
  const currentUser = useContext(UserContext);
  const RenderComponent = !!currentUser
    ? component
    : currentUser === undefined
    ? Loader
    : Login;

  return <Route {...rest} component={RenderComponent} />;
};

export default PrivateRoute;
