import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import FailWhale from "../../components/FailWhale";
import { dateFormat, numberFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import { LineCanvas } from "@nivo/line";
import { BarCanvas } from "@nivo/bar";
import Sugar from "sugar";
import * as XLSX from "xlsx-js-style";

const makeTables = (item, points = [], dateCount = 4, subCategoryId) => {
  var tables = [];

  const franchiseNames = (item.franchises || []).map((r) => r.name).sort();
  const productMap = (item.products || []).groupBy("title");
  const productNames = (item.products || [])
    //.filter((p) => {
    //  if (subCategoryId == null || subCategoryId == "") {
    //    return true;
    //  }
    //  return p.primarySubCategoryId === subCategoryId;
    //})
    .sort((a, b) => {
      return (
        //b.primarySubCategoryId.localeCompare(a.primarySubCategoryId) ||
        b.title.localeCompare(a.title)
      );
    })
    .map((r) => r.title);

  for (const tableName of franchiseNames) {
    const data = (points || []).filter((r) => r["franchise"] == tableName);
    const all_dates = data
      .map((r) => r["date"])
      .unique()
      .sort();
    const col_keys = all_dates.last(dateCount);

    var product_data = {};
    for (const productName of productNames) {
      product_data[productName] = data
        .filter((r) => r["product"] == productName)
        .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
    }
    //Row
    var tableRows = {};
    var row_count = 0;
    for (const productName of productNames) {
      var row_data = product_data[productName];
      if (row_data.length === 0) continue;
      var body_row = [];
      //Column
      for (const date of col_keys) {
        const found = row_data.find((r) => r["date"] == date);
        if (found && found.avg_price !== 0) {
          body_row.push({
            formattedValue: numberFormat(found.avg_price),
            value: found.avg_price,
          });
        } else {
          body_row.push({ formattedValue: "", value: null });
        }
      }
      tableRows[productName] = body_row;
      row_count += 1;
    }

    for (const productName of Object.keys(tableRows)) {
      const row = tableRows[productName];
      for (const index in row) {
        if (index - 1 >= 0) {
          const d1 = row[index - 1];
          const d2 = row[index];
          if (d1.value == null || d2.value == null) continue;
          if (d1.value > d2.value) {
            d2.arrows = -1;
          } else if (d1.value < d2.value) {
            d2.arrows = 1;
          }
        }
      }
    }

    let header = [...col_keys.map(dateFormat)];

    if (row_count >= 1) {
      header.push("Max verð");
      for (const productName of Object.keys(tableRows)) {
        const point = product_data[productName]
          .filter((r) => r.avg_price != 0)
          .max((r) => r.max_price);

        tableRows[productName].push({
          formattedValue: numberFormat(point?.max_price),
          value: point?.max_price,
        });
      }

      header.push("Min verð");
      for (const productName of Object.keys(tableRows)) {
        const point = product_data[productName]
          .filter((r) => r.avg_price != 0)
          .min((r) => r.min_price);
        tableRows[productName].push({
          formattedValue: numberFormat(point?.min_price),
          value: point?.min_price,
        });
      }

      header.push("Einingarverð");
      for (const productName of Object.keys(tableRows)) {
        const product = productMap[productName][0];

        if (!product?.quantity) {
          tableRows[productName].push({ formattedValue: "", value: 0 });
        } else {
          const point = product_data[productName].last();
          const value = point.avg_price / product.quantity;
          tableRows[productName].push({
            formattedValue: `${numberFormat(Math.round(value))} kr/${
              product?.unit
            }`,
            value,
          });
        }
      }

      header.push("Mism. síðustu mælinga");
      for (const productName of Object.keys(tableRows)) {
        const pair = product_data[productName].last(2);
        let lastChange = "0.0%";
        let value = 0;
        if (pair.length == 2) {
          const before = pair[0].avg_price;
          const now = pair[1].avg_price;
          if (before > 0 && now > 0)
            value = ((now / before - 1) * 100).toFixed(1);
          lastChange = String(value) + "%";
        }

        tableRows[productName].push({
          formattedValue: lastChange,
          value: value,
          arrows: value < 0 ? -1 : 1,
        });
      }

      const findLastYearDay = () => {
        const lastDay = all_dates.last();
        if (!lastDay) return null;

        const lastDate = new Date(lastDay);
        lastDate.setDate(1);
        const dates = all_dates.map((d) => {
          const date = new Date(d);
          date.setDate(1);
          const diff = date.monthsAgo(lastDate);
          return [d, diff];
        });
        var lastYearDate = dates.find((d) => d[1] == 12);
        if (lastYearDate) return lastYearDate[0];
        lastYearDate = dates.find((d) => 11 <= d[1] && d[1] <= 13);
        if (lastYearDate) return lastYearDate[0];
        lastYearDate = dates.first();
        return lastYearDate[0];
      };

      const calcProcentage = (all, lastDate) => {
        if (!lastDate) return null;

        const lastYear = all
          .filter((r) => r.avg_price > 0)
          .find((r) => r.date == lastDate);
        const now = all.last();
        if (lastYear && now) {
          const procentage = (now.avg_price / lastYear.avg_price - 1) * 100;
          return procentage;
        }
        return null;
      };

      header.push("YoY");
      const lastYearDate = findLastYearDay();
      for (const productName of Object.keys(tableRows)) {
        const all = product_data[productName];
        const procentage = calcProcentage(all, lastYearDate);
        if (procentage != null) {
          tableRows[productName].push({
            formattedValue: String(procentage.toFixed(1)) + "%",
            value: procentage,
            arrows: procentage < 0 ? -1 : 1,
          });
        } else {
          tableRows[productName].push({
            formattedValue: "0.0%",
            value: 0,
            arrows: procentage < 0 ? -1 : 1,
          });
        }
      }
      if (dateCount == 4) {
        header.unshift("YnY " + dateFormat(lastYearDate));
        for (const productName of Object.keys(tableRows)) {
          const lastYear = product_data[productName]
            .filter((r) => r.avg_price > 0)
            .find((r) => r.date == lastYearDate);
          tableRows[productName].unshift({
            formattedValue: lastYear ? String(lastYear.avg_price) : "N/A",
            value: lastYear?.avg_price,
          });
        }
      }

      header.unshift("einingar 1");
      for (const productName of Object.keys(tableRows)) {
        const product = productMap[productName][0];

        const formattedValue = product?.unit
          ? `${numberFormat(product?.quantity)} ${product?.unit}`
          : "";
        tableRows[productName].unshift({ formattedValue });
      }
    }

    tables.push({ name: tableName, row_count, header, body: tableRows });
  }

  return tables;
};

export default function ProductReportPerFranchise(props) {
  const history = useHistory();
  let pricesurveyId = props.match.params.id;
  const {
    data: item = {
      products: [],
      subcategories: [],
    },
  } = useRest(`/api/price-surveys/${pricesurveyId}`);

  const { data: points } = useRest(
    `/api/reports/price_survey_product_report/${pricesurveyId}`
  );
  //const [subcategoryId, setSubcategoryId] = useState("");

  if (item === null) {
    return <FailWhale />;
  }

  const Title = (
    <div>
      <div>Vöruskýrsla - {item.title}</div>
      <span style={{ fontSize: "18px" }}>
        {dateFormat(item.dateStart)} - {dateFormat(item.dateEnd)}
      </span>
    </div>
  );

  const tables = makeTables(item, points, 4);

  const exportExcel = useCallback(
    (e) => {
      const alignment = {
        vertical: "center",
        horizontal: "center",
      };
      const header_style = {
        font: { bold: true, sz: "10" },
        fill: { patternType: "solid", fgColor: { rgb: "FFF2CC" } },
        border: {
          top: { style: "thick" },
          bottom: { style: "thick" },
          right: { style: "thick" },
          left: { style: "thick" },
        },
      };
      const styleValue = (v, s) => ({ v, t: "s", s });

      const formatSheetData = (table) => [
        [
          styleValue(table.name, header_style),
          ...table.header.map((v) =>
            styleValue(v, { ...header_style, alignment })
          ),
        ],
        ...Object.entries(table.body).map((rowObj, i) => [
          rowObj[0],
          ...rowObj[1].map((v) => {
            var fill = null;
            if (v.arrows) {
              if (v.arrows < 0)
                fill = { patternType: "solid", fgColor: { rgb: "63BE7B" } };
              else if (0 < v.arrows)
                fill = { patternType: "solid", fgColor: { rgb: "F8696B" } };
            }

            return styleValue(v.formattedValue, { alignment, fill });
          }),
        ]),
        [],
      ];

      const wb = XLSX.utils.book_new();

      const makeSheet = (id) => {
        const data = makeTables(item, points, 30, id)
          .map(formatSheetData)
          .flatten(1);

        const ws = XLSX.utils.aoa_to_sheet(data);
        const cols_settings = data[0].map((a, i) => {
          const max_width = data.reduce(
            (w, r) => Math.max(w, r[i]?.length || 0),
            10
          );
          return { wch: max_width };
        });
        ws["!cols"] = cols_settings;
        ws["!rows"] = data.map(() => ({ hpt: 16 }));

        return ws;
      };

      //if (item.subcategories) {
      //  item.subcategories.map((sub) => {
      //    const ws = makeSheet(sub.id);
      //    XLSX.utils.book_append_sheet(wb, ws, sub.title);
      //  });
      //} else {
        const ws = makeSheet();
        XLSX.utils.book_append_sheet(wb, ws, "Allt");
      //}

      XLSX.writeFile(wb, item.title + ".xlsx");
    },
    [item, points]
  );

  const buttons = [
    {
      name: "Sækja",
      icon: <i className="fas fa-download"></i>,
      onClick: exportExcel,
      size: "sm",
      className: "mr-1",
    },
    {
      name: "Til baka",
      onClick: () => history.push(`/price-surveys/${item.id}`),
      size: "sm",
    },
  ];

  return (
    <div className="">
      <Card title={Title} buttons={buttons}>
        {/*
        <select
          className="form-control form-control-sm ml-1"
          style={{ display: "inline-block", width: "150px" }}
          value={subcategoryId}
          onChange={(e) => {
            const val = e.target.value;
            setSubcategoryId(val);
          }}
        >
          <option value={""}>Allir undirflokkar</option>
          {item.subcategories.map((s, i) => (
            <option key={s.id} value={s.id}>
              {s.title}
            </option>
          ))}
        </select>
          */}
        {tables.map((table, i) => (
          <Table key={"table-" + i} responsive size="sm" className="my-2">
            <thead>
              <tr>
                <th>{table.name}</th>
                {table &&
                  table.header.map((name, i) => (
                    <th className="px-2 text-center" key={i}>
                      {name}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {table.row_count < 1 && (
                <tr>
                  <td colSpan="100%">Engar upplýsingar fyrir þessa keðju</td>
                </tr>
              )}
              {Object.entries(table.body).map((rowObj, i) => (
                <tr key={"row-" + i}>
                  <td key={"date-" + i}>{rowObj[0]}</td>
                  {rowObj[1].map((v, i) => {
                    if (!Object.isObject(v)) {
                      console.log("Not object???", rowObj[0], i);
                    }

                    var arrow = null;
                    if (v.arrows) {
                      var color = undefined;
                      if (v.arrows < 0) color = "#63BE7B";
                      else color = "#F8696B";

                      if (v.arrows < 0) {
                        arrow = (
                          <i
                            className="fas fa-arrow-down"
                            style={{ color, textShadow: "0px 0px 1.5px black" }}
                          ></i>
                        );
                      } else if (0 < v.value) {
                        arrow = (
                          <i
                            className="fas fa-arrow-up"
                            style={{ color, textShadow: "0px 0px 1.5px black" }}
                          ></i>
                        );
                      } else {
                        arrow = (
                          <i
                            className="wid-10"
                            style={{ color, textShadow: "0px 0px 1.5px black" }}
                          ></i>
                        );
                      }
                    }

                    return (
                      <td className="px-2 text-center" key={i}>
                        {v.formattedValue}
                        {arrow && <>&nbsp;{arrow}</>}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
        ))}
      </Card>
    </div>
  );
}
