import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function LinkButton({ href, ...rest }) {
  var button = <Button {...rest}></Button>;
  if (href) {
    return <Link to={href}>{button}</Link>;
  }
  return button;
}
