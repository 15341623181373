const pad = (num) => {
  return ("0" + num).slice(-2);
};

export const fancyDateFormatShort = (date) => {
  if (typeof date !== Date) {
    date = new Date(date);
  }

  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maí",
    "jún",
    "júl",
    "ágú",
    "sep",
    "okt",
    "nóv",
    "des",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};
export const fancyDateFormat = (date) => {
  if (typeof date !== Date) {
    date = new Date(date);
  }

  const months = [
    "Janúar",
    "Febrúar",
    "Mars",
    "Apríl",
    "Maí",
    "Júní",
    "Júlí",
    "Ágúst",
    "September",
    "Október",
    "Nóvember",
    "Desember",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const dateFormat = (date) => {
  if (date === undefined || date === null) {
    return "--/--/----";
  }
  if (typeof date !== Date) {
    date = new Date(date);
  }
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const dateTimeFormat = (date) => {
  if (date === undefined || date === null) {
    return "--/--/---- --:--";
  }
  if (typeof date !== Date) {
    date = new Date(date);
  }
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = pad(date.getHours());
  const mins = pad(date.getMinutes());
  return `${day}/${month}/${year} ${hours}:${mins}`;
};

const currencyFormatter = new Intl.NumberFormat("de", {
  style: "currency",
  currency: "ISK",
});

export const currencyFormat = (number) => currencyFormatter.format(number);

const numberFormatter = new Intl.NumberFormat("de");

export const numberFormat = (number) => numberFormatter.format(number);
