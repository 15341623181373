import React, { Component, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";

export const ADD_CATEGORY = gql`
  mutation AddCategory($title: String!) {
    addCategory(title: $title) {
      id
      title
    }
  }
`;

export default function NewCategoryModal(props) {
  const [title, setTitle] = useState("");
  const [muterror, setMuterror] = useState(null);
  const [addCategory, { loading }] = useMutation(ADD_CATEGORY, {
    refetchQueries: ["GetCategories"],
    update(cache, { data: { addCategory } }) {
      if (props.onAdd) {
        props.onAdd(addCategory, cache);
      }
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { data, error } = await addCategory({ variables: { title } });
    if (error) {
      setMuterror("Failed to create User");
      return;
    }
    if (props.onNew) props.onNew(data.addCategory);
    setTitle("");
    props.onClose();
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Nýr flokkur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
