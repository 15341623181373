import React, { Component, useState } from "react";
import { Button, Modal } from "react-bootstrap";

function Confirm(props) {
  const onConfirm = props.onConfirm || (() => {});
  const confirm = props.confirm || "Já";
  const cancel = props.cancel || "hætta við";
  const title = props.title || "Ertu viss?";

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button onClick={onConfirm}>{confirm}</Button>
        <Button onClick={props.onClose}>{cancel}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default Confirm;
