import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { gql, useMutation } from "@apollo/client";
import AppLogoKurvan from "../assets/white_logo_transparent_background.png";
import AppLogoHG from "../assets/hg-logo-1.png";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
    }
  }
`;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [login, { loading: loggingIn, error }] = useMutation(LOGIN, {
    errorPolicy: "all",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    await login({
      variables: {
        email,
        password,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data?.login != null) {
          location.reload(true);
        }
      })
      .catch((error) => {
        console.log("Errors:", error);
      });
  };
  return (
    <Row
      className="justify-content-center align-items-center text-center"
      style={{
        height: 700,
      }}
    >
      <Col
        xs={12}
        style={{
          maxWidth: "400px",
        }}
      >
        <img
          onClick={(e) => {
            window.location = "/";
          }}
          src={AppLogoHG}
          alt="Herferðagreining"
          style={{
            width: "70%",
            marginBottom: "25px",
          }}
        />
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-4" controlId="loginEmail">
            <Form.Control
              required
              className="bg-light"
              type="email"
              placeholder="Netfang"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="loginPassword">
            <Form.Control
              required
              className="bg-light"
              type="password"
              placeholder="Lykilorð"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="Submit">
            <Button className="w-75 f-w-600" variant="primary" type="submit">
              {loggingIn ? "Vinna..." : "Innskrá"}
            </Button>
            {(error?.graphQLErrors || []).map((e) => (
              <small className="form-text text-warning">{e.message}</small>
            ))}
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

export default Login;
