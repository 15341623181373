import React, { Fragment, useState, useContext } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import NavGroup from "./NavGroup";
import * as actionTypes from "../../../../../store/actions";

import AppLogoKurvan from "../../../../../assets/white_logo_transparent_background.png";
import AppLogoHG from "../../../../../assets/hg-logo-1.png";

import GlobalContext from "../../../../../GlobalContext";
import { useWindowSize } from "../../../../../utils/reactHooks";

function NavContent({ navigation, ...props }) {
  const [{ scrollWidth, prevDisable, nextDisable }, setState] = useState({
    scrollWidth: 0,
    prevDisable: true,
    nextDisable: false,
  });

  const {
    state: { layout, rtlLayout, collapseMenu },
    dispatch,
  } = useContext(GlobalContext);
  const windowSize = useWindowSize();
  const onNavContentLeave = () =>
    dispatch({ type: actionTypes.NAV_CONTENT_LEAVE });

  const scrollPrevHandler = () => {
    const wrapperWidth = document.getElementById("sidenav-wrapper").clientWidth;

    let sWidth = scrollWidth - wrapperWidth;
    if (sWidth < 0) {
      setState({
        scrollWidth: 0,
        prevDisable: true,
        nextDisable: false,
      });
    } else {
      setState({ ...state, scrollWidth: sWidth, prevDisable: false });
    }
  };

  const scrollNextHandler = () => {
    const wrapperWidth = document.getElementById("sidenav-wrapper").clientWidth;
    const contentWidth = document.getElementById("sidenav-horizontal")
      .clientWidth;

    let scrollWidth = scrollWidth + (wrapperWidth - 80);
    if (scrollWidth > contentWidth - wrapperWidth) {
      scrollWidth = contentWidth - wrapperWidth + 80;
      setState({
        scrollWidth: scrollWidth,
        prevDisable: false,
        nextDisable: true,
      });
    } else {
      setState({ scrollWidth: scrollWidth, prevDisable: false });
    }
  };

  const navItems = navigation.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup layout={layout} key={item.id} group={item} />;
      default:
        return false;
    }
  });

  const scrollStyle =
    layout === "horizontal" && rtlLayout
      ? { marginRight: "-" + scrollWidth + "px" }
      : { marginLeft: "-" + scrollWidth + "px" };

  let mainContent = "";
  if (layout === "horizontal") {
    let prevClass = ["sidenav-horizontal-prev"];
    if (prevDisable) {
      prevClass = [...prevClass, "disabled"];
    }
    let nextClass = ["sidenav-horizontal-next"];
    if (nextDisable) {
      nextClass = [...nextClass, "disabled"];
    }

    mainContent = (
      <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
        <a href="#" className={prevClass.join(" ")} onClick={scrollPrevHandler}>
          <span />
        </a>
        <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
          <ul
            id="sidenav-horizontal"
            className="nav pcoded-inner-navbar sidenav-inner"
            onMouseLeave={onNavContentLeave}
            style={scrollStyle}
          >
            {navItems}
          </ul>
        </div>
        <a href="#" className={nextClass.join(" ")} onClick={scrollNextHandler}>
          <span />
        </a>
      </div>
    );
  } else {
    mainContent = (
      <div className="navbar-content next-scroll">
        <PerfectScrollbar>
          <img
            onClick={(e) => {
              window.location = "/";
            }}
            src={AppLogoHG}
            alt="Herferðagreining"
            style={{
              width: "80%",
              marginTop: "10px",
              marginBottom: "10px",
              marginLeft: "10%",
            }}
          />
          <ul className="nav pcoded-inner-navbar" id="nav-ps-next">
            {navItems}
          </ul>
        </PerfectScrollbar>
      </div>
    );
  }

  return <Fragment>{mainContent}</Fragment>;
}

export default NavContent;
