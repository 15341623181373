import React from "react";

function YearlyHeatmap({ year, onDay }) {
  if (year === undefined) year = parseInt(new Date().format("%Y")); //Todo (andri): Make this default to the current year

  const firstOfJan = new Date(`${year}/01/01`);

  // Consts
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ];
  const weekdays = ["M", "T", "W", "T", "F", "S", "S"];
  const cellSize = 17;
  const cellSpacing = 0.5;
  const weeks = 53;
  const width = (cellSize + cellSpacing) * weeks + 50;
  const height = 34 + (cellSize + cellSpacing) * weekdays.length;
  const monthDisplayOffset = 28;

  const paddedYear = weeks * 7;
  const dayOffset = parseInt(firstOfJan.format("{e}")) - 1;

  return (
    <svg viewBox={`0, 0, ${width}, ${height}`} style={{ width: "100%" }}>
      <g transform="translate(40.5,25.5)">
        <text x="-5" y="-5" fontWeight="bold" textAnchor="end">
          {year}
        </text>
        <g textAnchor="end">
          {weekdays.map((day, index) => (
            <text
              key={index}
              x="-5"
              y={8.5 + (cellSize + cellSpacing) * index}
              dy="0.31em"
            >
              {day}
            </text>
          ))}
        </g>
        <g fill="#333">
          {Array.apply(null, Array(paddedYear)).map((_, index) => {
            const a = index % 7;
            const b = parseInt(index / 7);

            const day = new Date(firstOfJan).addDays(index - dayOffset);

            const selectedYear = year === parseInt(day.format("%Y"));

            const onDayData = onDay(day);

            /*
            fill="#355215"
            filter={`brightness(${Math.floor((Math.random() * 10))})`}
            */
            return (
              <rect
                key={index}
                width={cellSize}
                height={cellSize}
                x={0.5 + (cellSize + cellSpacing) * b}
                y={0.5 + (cellSize + cellSpacing) * a}
                fill={
                  selectedYear
                    ? onDayData?.color
                      ? onDayData?.color
                      : "#dddddd"
                    : "#ffffff"
                }
              >
                <title>
                  {onDayData?.title ? onDayData?.title : `${day.full()}`}
                </title>
              </rect>
            );
          })}
        </g>
        <g>
          {months.map((month, index) => (
            <g key={index}>
              <text
                x={
                  monthDisplayOffset +
                  (weeks / months.length) * (cellSize + cellSpacing) * index
                }
                y="-5"
                d={`M${
                  monthDisplayOffset +
                  (weeks / months.length) * (cellSize + cellSpacing) * index
                },0V85`}
              >
                {month}
              </text>
            </g>
          ))}
        </g>
      </g>
    </svg>
  );
}

export default YearlyHeatmap;
