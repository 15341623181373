import React, { Component } from "react";
import Select from "react-select";

//import {Select} from 'react-bootstrap';

import { gql, useQuery } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query GetCategories {
    categories {
      id
      title
    }
  }
`;

function CategorySelect(props) {
  const { loading, error, data } = useQuery(GET_CATEGORIES);
  if (error) return <p>Error...</p>;

  const getOptions = (data) => {
    var options = [];
    if (data && data.categories) {
      options = data.categories.map((cc) => {
        return { value: cc.id, label: cc.title };
      });
    }
    if (props.extraOptions) {
      options = [...props.extraOptions, ...options];
    }
    return options;
  };

  const getValue = (data, value) => {
    const val = getOptions(data).find((v) => v.value === value);
    return val;
  };

  return (
    <React.Fragment>
      <Select
        id={props.id}
        styles={{
          control: (provided) => ({
            ...provided,
            borderRadius: "0px",
          }),
        }}
        className="basic-single form-control"
        classNamePrefix="select"
        placeholder={""}
        isLoading={loading}
        value={getValue(data, props.value)}
        onChange={(e) => props.handleSelectUpdate(e, props.name)}
        name={props.name}
        options={getOptions(data)}
      />
    </React.Fragment>
  );
}

export default CategorySelect;
