import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import useDebouncedEffect from "use-debounced-effect";

import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import { getGravatarURL } from "../../utils/Gravatar";
import { isEmail } from "../../utils/Email";
import RNG from "../../utils/RNG";
import UserProfileImage from "../../components/UserProfileImage";

function UserModal({ user, persist, title, onClose, show }) {
  const [formData, setFormData] = useState(user);
  const [email, setEmail] = useState(user.email || "");
  const [gravatarLoading, setGravarLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [muterror, setMuterror] = useState(null);

  const updateForm = (data) =>
    setFormData({
      ...formData,
      ...data,
    });

  const validPhoneNumber = isValidPhoneNumber(formData.phone || "", "IS");

  useEffect(() => {
    const avatarUrl = `${getGravatarURL(formData.email)}?s=50&d=404`;

    if (isEmail(formData.email) && !formData.profileImageUrl) {
      setGravarLoading(true);
      fetch(avatarUrl)
        .then((result) => {
          if (!formData.profileImageUrl && result.status == 200) {
            updateForm({ profileImageUrl: result.url });
          }
          setGravarLoading(false);
        })
        .catch((error) => {
          setGravarLoading(false);
        });
    }
  }, [email, user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (saving) return;
    setSaving(true);
    const data = { ...formData };
    if (validPhoneNumber && data.phone) {
      const phoneNumber = parsePhoneNumber(data.phone, "IS");
      data.phone = phoneNumber.formatInternational();
    }

    persist(data, () => {
      setSaving(false);
    });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <UserProfileImage
              size={40}
              user={formData}
              style={{ float: "left", marginRight: 10 }}
            />
            <div style={{ display: "inline-block" }}>
              <b>{formData.name || "..."}</b>
              <br />
              {formData.email || "..."}
            </div>
          </div>
          <br />
          <Form.Label>Nafn</Form.Label>
          <Form.Control
            required
            type="text"
            minLength={2}
            value={formData.name}
            onChange={(e) => updateForm({ name: e.target.value })}
          />
          <Form.Label>Netfang</Form.Label>
          <Form.Control
            required
            type="email"
            minLength={5}
            value={formData.email}
            onChange={(e) => {
              updateForm({ email: e.target.value });
              setEmail(e.target.value);
            }}
          />
          <Form.Label>
            Sími &nbsp;
            {formData.phone && !validPhoneNumber && (
              <b style={{ color: "red" }} title="Get ekki notað símanúmer">
                Ekki gilt símanúmer
              </b>
            )}
          </Form.Label>
          <Form.Control
            required
            type="text"
            minLength={7}
            value={formData.phone}
            onChange={(e) => updateForm({ phone: e.target.value })}
          />
          <Form.Label>
            Slóð í mynd &nbsp;
            <span
              style={{
                cursor: "pointer",
                userSelect: "none",
              }}
              onClick={(e) => {
                const rng = (a) => RNG(new Date().getTime() * a);
                updateForm({
                  profileImageUrl: `https://avatars.dicebear.com/api/bottts/${rng(
                    1
                  ).next48BitHexNumber()}${rng(2).next48BitHexNumber()}.svg`,
                });
              }}
              title="Fá random robot mynd sem prófil"
            >
              🤖
            </span>
            &nbsp;
            {gravatarLoading && "⌛"}
          </Form.Label>
          <Form.Control
            type="text"
            minLength={7}
            value={formData.profileImageUrl}
            onChange={(e) => updateForm({ profileImageUrl: e.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">{saving ? "Vista..." : "Áfram"}</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default UserModal;
