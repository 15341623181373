import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  Dropdown,
} from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import DatePicker from "react-datepicker";

import UserSelect from "./UserSelect";
import { GET_TASKS } from "./Tasks";

const CREATE_TASK = gql`
  mutation CreateTask(
    $title: String!
    $description: String
    $dueDate: String
    $assigneeId: ID
    $imageId: ID
    $qaId: ID
    $campaignId: ID
  ) {
    created: createTask(
      title: $title
      description: $description
      dueDate: $dueDate
      assigneeId: $assigneeId
      imageId: $imageId
      qaId: $qaId
      campaignId: $campaignId
    ) {
      id
      title
      dueDate
      assignee {
        id
        name
      }
    }
  }
`;

export function CreateTaskModal({
  title: incommingTitle,
  description: incommmingDescription,
  ...props
}) {
  const [title, setTitle] = useState(incommingTitle || "");
  const [description, setDescription] = useState(incommmingDescription || "");
  const [dueDate, setDueDate] = useState(undefined);
  const [assigneeId, setAssignee] = useState(undefined);

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nýtt verkefni</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="CreateTaskForm.title">
          <Form.Label>Titill</Form.Label>
          <Form.Control
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="CreateTaskForm.description">
          <Form.Label>Lýsing</Form.Label>
          <Form.Control
            as="textarea"
            row={3}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="CreateTaskForm.assignee">
          <Form.Label>Ábyrgð</Form.Label>
          <UserSelect
            name={"assigneeId"}
            handleSelectUpdate={(selected) => {
              setAssignee(selected.value);
            }}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="CreateTaskForm.dueDate">
          <Form.Label>Þarf að klárast fyrir</Form.Label>
          <DatePicker
            selected={dueDate}
            wrapperClassName="w-100"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            onChange={(date) => setDueDate(date)}
            className="form-control"
          />
        </Form.Group>
        <Button
          className="pull-right"
          size="sm"
          onClick={() => {
            props.createTask({
              variables: {
                title: title,
                dueDate,
                description,
                assigneeId,
                imageId: props.image?.id,
                qaId: props.qa?.id,
                campaignId: props.campaign?.id,
              },
            });
            setTitle("");
            setDescription("");
            setDueDate(undefined);
            setAssignee(undefined);
            props.onClose();
          }}
        >
          Vista
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default function CreateTaskButton({ children: Child, ...props }) {
  const [show, setShow] = useState(false);

  const [create, { loading }] = useMutation(CREATE_TASK, {
    update(cache, { data: { created } }) {
      const queryResult = cache.readQuery({
        query: GET_TASKS,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_TASKS,
          data: {
            allTasks: [...queryResult.allTasks, created],
          },
        });
      }
    },
  });

  return (
    <React.Fragment>
      <Child
        {...props}
        onClick={() => {
          setShow(true);
        }}
      />
      <CreateTaskModal
        {...props}
        createTask={create}
        show={show}
        onClose={() => setShow(false)}
      />
    </React.Fragment>
  );
}
