import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import AppLogoHG from "../assets/hg-logo-1.png";

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($userEmail: ID!) {
    forgotPassword(userEmail: $userEmail)
  }
`;

function Forgot() {
  const history = useHistory();
  const [userEmail, setUserEmail] = useState("");

  const [forgot, { loading, error }] = useMutation(FORGOT_PASSWORD, {
    errorPolicy: "all",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    await forgot({
      variables: {
        userEmail,
      },
    })
      .then(() => {
        console.log("Feedback!!!!!");
        //history.push('/');
      })
      .catch((error) => {
        console.log("Errors:", error);
      });
  };
  return (
    <Row
      className="justify-content-center align-items-center text-center"
      style={{
        height: 700,
      }}
    >
      <Col
        xs={12}
        style={{
          maxWidth: "400px",
        }}
      >
        <img
          onClick={() => {
            history.push("/");
          }}
          src={AppLogoHG}
          alt="Herferðagreining"
          style={{
            width: "100px",
            marginBottom: "50px",
          }}
        />
        <p className="h3 text-white">Gleymt lykilorð</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-4" controlId="userEmail">
            <Form.Control
              required
              minLength="5"
              className="bg-light"
              type="email"
              placeholder="Netfang"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="Submit">
            <Button className="w-75 f-w-600" variant="primary" type="submit">
              {loading ? "Vinna..." : "Áfram"}
            </Button>
            {(error?.graphQLErrors || []).map((e) => (
              <small className="form-text text-warning">{e.message}</small>
            ))}
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

export default Forgot;
