import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { CloseTask } from "./Tasks";
import {
  Row,
  Col,
  Media,
  Table,
  Image,
  Modal,
  Form,
  Badge,
  Dropdown,
} from "react-bootstrap";

import ReactStars from "react-stars";

import Card from "../App/components/MainCard";
import GalleryMasonry from "../App/components/GalleryMasonry";
import NoResults from "../App/components/NoResults";
import Button from "../components/Button";
import UserProfileImage from "../components/UserProfileImage";
import { Images } from "./Images";
import { UserContext } from "./../UserContext";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import SelectWithData from "./SelectWithData";
import GaugeChart from "./GaugeChart";
import FinishModalButton from "./FinishModal";
import CreateTaskModal from "./CreateTaskModal";

import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";

const brakePoints = [200, 500, 750];
const Tile = ({ src }) => {
  return (
    <div className="tile">
      <img src={src} />
    </div>
  );
};

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      value: id
      label: name
    }
  }
`;

const REMOVE_IMAGE = gql`
  mutation RemoveImage($id: ID!, $projectId: ID!) {
    deleted: removeImageFromProject(id: $id, projectId: $projectId) {
      id
    }
  }
`;

import DatePicker from "react-datepicker";
import { useApolloClient } from "@apollo/client";

function ImageUploader(props) {
  const [show, setShow] = useState(false);
  let Component = null;
  let buttonProps = {};
  if (props.as === "dropdown") {
    Component = Dropdown.Item;
  } else {
    Component = Button;
    buttonProps = {
      size: "sm",
    };
  }
  return (
    <React.Fragment>
      <Component
        {...buttonProps}
        className={props.className}
        style={props.style}
        disabled={props.locked}
        onClick={() => setShow(true)}
      >
        Hlaða upp mynd
      </Component>

      <ImageUploadModal {...props} show={show} onClose={() => setShow(false)} />
    </React.Fragment>
  );
}

function beDate(date) {
  if (typeof date !== Date) {
    date = new Date(date);
  }
  return date;
}

function ImageThumb(props) {
  const imgRef = useRef(null);
  const loadImage = (ref) => (e) => {
    ref.current.src = e.target.result;
  };
  const reader = new FileReader();
  reader.onload = loadImage(imgRef);
  reader.readAsDataURL(props.image);
  return <img ref={imgRef} style={{ width: "40px", height: "40px" }} />;
}

function TagsInput({ onUpdate, ...props }) {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const components = {
    DropdownIndicator: null,
  };
  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleChange = (value, actionMeta) => {
    onUpdate((value || []).map((v) => v.value));
    setTags(value || []);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        const newTags = [...tags, createOption(inputValue)];
        onUpdate(newTags.map((v) => v.value));
        setTags([...tags, createOption(inputValue)]);
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className="w-100"
      components={components}
      menuIsOpen={false}
      isMulti
      inputValue={inputValue}
      value={tags}
      onInputChange={(input) => setInputValue(input)}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Tögg"
    />
  );
}

function ImageUploadModal(props) {
  const client = useApolloClient();

  const [imagesAndTags, setImagesAndTags] = useState([]);
  const [date, setDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [feedback, setFeedback] = useState("");

  const trimName = (string) => {
    var maxLength = 20;
    var trimmedString =
      string.length > maxLength
        ? string.substring(0, maxLength - 3) + "..."
        : string;
    return trimmedString;
  };

  const removeImage = (imageToRemove) => {
    const newImages = imagesAndTags.filter(
      (imageAndTags) => imageToRemove.name !== imageAndTags.image.name
    );
    setImagesAndTags(newImages);
  };

  const Submit = (imagesAndTags, store, date, campaignId) => {
    if (store == "") {
      setFeedback("Þarf að hafa staðsetningu");
      return;
    }
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open("POST", "/upload/images-campaign");
    imagesAndTags.map((imageAndTags) => {
      formData.append("files", imageAndTags.image, imageAndTags.image.name);
      formData.append("tags[]", imageAndTags.tags.join("<#>"));
      formData.append(`comments[]`, imageAndTags.comment);
      const needsAttention = imageAndTags.needsAttention ? "1" : "0";
      formData.append("needsAttentions[]", needsAttention);
    });
    formData.append("campaignId", campaignId);
    formData.append("storeId", store);
    formData.append("date", date.toISOString());

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        var status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          const resImages = JSON.parse(request.responseText);
          let data = client.readQuery({
            query: GET_CAMPAIGN,
            variables: { id: campaignId },
          });
          let mutData = JSON.parse(JSON.stringify(data));
          Object.set(mutData, `campaign.images`, [
            ...mutData.campaign.images,
            ...resImages,
          ]);
          client.writeQuery({ query: GET_CAMPAIGN, data: mutData });

          setImagesAndTags([]);
          setStore("");
          setFeedback("");
          props.onClose();
        } else {
          if (status === 401) {
            location.reload(true);
          }
        }
      }
    };
    request.send(formData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Submit(imagesAndTags, store, date, props.campaignId);
  };

  const AddTagsToImages = (index) => (tags) => {
    imagesAndTags[index].tags = tags;
    setImagesAndTags(imagesAndTags);
  };

  const updateNeedsAttention = (index, needsAttention) => {
    // NOTE: This spread needs to be done so that the components renders the changes
    // set function needs to get a new reference(spreading make it a new ref) so that it updates the dom
    const newArray = [...imagesAndTags];
    newArray[index].needsAttention = needsAttention;
    setImagesAndTags(newArray);
  };

  const updateComment = (index, comment) => {
    const newArray = [...imagesAndTags];
    newArray[index].comment = comment;
    setImagesAndTags(newArray);
  };
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Hlaða upp myndir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            id="custom-file"
            label=""
            style={{ marginBottom: "20px" }}
            custom
            accept="image/*"
            multiple
            onChange={(e) => {
              const newImages = [...e.target.files]
                .filter((file) => {
                  const isImage = file.type.startsWith("image/");
                  return isImage;
                })
                .map((image) => ({
                  image,
                  tags: [],
                  comment: "",
                  needsAttention: false,
                }));
              const allImages = [...imagesAndTags, ...newImages];
              setImagesAndTags(allImages);
            }}
          />
          <Table responsive>
            <tbody>
              {imagesAndTags.map((img, index) => (
                <React.Fragment>
                  <tr key={"image-" + img.image.name}>
                    <td width="42px">
                      <ImageThumb image={img.image} />
                    </td>
                    <td width="320px">
                      <TagsInput onUpdate={AddTagsToImages(index)} />
                    </td>
                    <td width="16px">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          updateNeedsAttention(index, !img.needsAttention);
                        }}
                      >
                        <i
                          className="fas fa-exclamation-circle f-24"
                          style={{
                            color: img.needsAttention ? "#d40" : "#999",
                          }}
                          title="Þarf athygli"
                        />
                      </div>
                    </td>
                    <td width="30px">
                      <Button onClick={() => removeImage(img.image)} size="sm">
                        <i className={"fas fa-trash"} />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={{ paddingTop: "0px" }}>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="comment"
                          placeholder="Athugasemd"
                          onChange={(e) => updateComment(index, e.target.value)}
                          rows={2}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <Form.Label>Dagsetning</Form.Label>
          <DatePicker
            selected={date}
            wrapperClassName="w-100"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            onChange={(e) => {
              e.setHours(0);
              e.setMinutes(0);
              e.setSeconds(0);
              setDate(e);
            }}
            className="form-control"
          />
          <Form.Label>Staðsetning</Form.Label>
          <SelectWithData
            query={GET_STORES}
            placeholder={"Veldu búð"}
            onChange={(e) => setStore(e.value)}
            value={store}
          />
        </Modal.Body>
        <Modal.Footer>
          {feedback && <div style={{ color: "red" }}>*{feedback}</div>}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export const GET_CAMPAIGN = gql`
  query GetCampaign($id: ID!) {
    campaign(id: $id) {
      id
      title
      thumbImageUrl
      dateStart
      dateEnd
      locked
      stars
      review
      goal
      minimumGoal
      result
      linkedTasks {
        id
        title
        closed
      }
      comments {
        user {
          id
          name
        }
        content
        date
      }
      manager {
        id
        name
      }
      category {
        id
        title
      }
      images {
        id
        date
        url
        tags
        needsAttention
        comment
        store {
          id
          name
        }
        linkedTasks {
          id
          title
        }
      }
      stores {
        id
        name
        gps {
          lat
          lng
        }
      }
      franchises {
        id
        name
      }
      product {
        id
        title
      }
      trademark {
        id
        title
        manager {
          id
          name
          profileImageUrl
        }
      }
      supplier {
        id
        title
        manager {
          id
          name
          profileImageUrl
        }
      }
      costsExpected {
        production
        radio
        television
        internet
        print
        franchise
        other
      }
      costsReal {
        production
        radio
        television
        internet
        print
        franchise
        other
      }
    }
  }
`;

const LOCK_CAMPAIGN = gql`
  mutation LockCampaign($campaignId: ID!) {
    updateCampaign(id: $campaignId, locked: true) {
      id
      locked
    }
  }
`;

function UploadProfileButton(props) {
  const client = useApolloClient();
  const inputFile = useRef(null);
  const Submit = (file, campaignId) => {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open("POST", "/upload/profile-project");
    formData.append("file", file);
    formData.append("projectId", campaignId);

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        var status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          const imageUrl = request.responseText;
          let data = client.readQuery({
            query: GET_CAMPAIGN,
            variables: { id: campaignId },
          });
          let mutData = JSON.parse(JSON.stringify(data));
          Object.set(mutData, `campaign.thumbImageUrl`, imageUrl);
          client.writeQuery({ query: GET_CAMPAIGN, data: mutData });
        } else {
        }
      }
    };
    request.send(formData);
  };

  return (
    <React.Fragment>
      <Dropdown.Item
        className={props.className}
        style={props.style}
        disabled={props.locked}
        onClick={() => {
          inputFile.current.click();
        }}
      >
        Profile mynd
        <i className="fas fa-upload pl-1" />
      </Dropdown.Item>

      <input
        ref={inputFile}
        style={{ display: "none" }}
        id="upload"
        type="file"
        accept="image/*"
        onChange={(e) => {
          Submit(e.target.files[0], props.campaign.id);
        }}
      />
    </React.Fragment>
  );
}

function BasicInfoCard(props) {
  const { loading, data, error } = props;
  const { campaign } = data || { campaign: {} };

  const [lockCampaign] = useMutation(LOCK_CAMPAIGN, {
    update(cache, { data }) {
      const cachedData = cache.readQuery({
        query: GET_CAMPAIGN,
        variables: { id: data.updateCampaign.id },
      });

      const campaignCache = {
        ...cachedData.campaign,
        locked: data.updateCampaign.locked,
      };
      cache.writeQuery({
        query: GET_CAMPAIGN,
        variables: { id: data.updateCampaign.id },
        data: { campaignCache },
      });
      props.refetch();
    },
  });

  let dateDiffDays = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
  };

  let now = new Date();
  let end = new Date(campaign.dateEnd);

  let stakeholders = [];
  if (!loading) {
    if (campaign.trademark?.manager) {
      stakeholders.push(
        <UserProfileImage
          size={20}
          user={campaign.trademark?.manager}
          title={
            campaign.trademark.title + ": " + campaign.trademark?.manager.name
          }
        />
      );
    }

    if (campaign.supplier?.manager) {
      stakeholders.push(
        <UserProfileImage
          size={20}
          user={campaign.supplier?.manager}
          title={
            campaign.supplier?.title + ": " + campaign.supplier?.manager.name
          }
        />
      );
    }
  }

  return (
    <Card title={loading ? "..." : campaign.title}>
      <Row>
        <Col xs={12} md={4}>
          {!loading && campaign.thumbImageUrl && (
            <img
              style={{
                display: "block",
                width: "100%",
                margin: "0px auto 10px",
              }}
              src={campaign.thumbImageUrl + "?w=150"}
            />
          )}
        </Col>
        <Col xs={12} md={8}>
          <Table responsive size="sm">
            <tbody>
              <tr key="info-campaign-date-start">
                <th>Herferð hefst</th>
                <td className="text-right">
                  {loading ? "..." : dateFormat(new Date(campaign.dateStart))}
                </td>
              </tr>
              <tr key="info-campaign-date-end">
                <th>Herferð lýkur</th>
                <td className="text-right">
                  {loading ? "..." : dateFormat(new Date(campaign.dateEnd))}
                </td>
              </tr>
              <tr key="info-campaign-days-total">
                <th>Fjöldi daga í herferð</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : dateDiffDays(campaign.dateEnd, campaign.dateStart)}
                </td>
              </tr>
              <tr key="info-campaign-category">
                <th>Flokkur</th>
                <td data-test="category" className="text-right">
                  {loading || !campaign.category ? "" : campaign.category.title}
                </td>
              </tr>
              <tr key="info-campaign-manager">
                <th>Ábyrgðarmaður</th>
                <td data-test="manager" className="text-right">
                  {loading || !campaign.manager ? "" : campaign.manager.name}
                </td>
              </tr>
              <tr key="info-campaign-supplier">
                <th>Birgi</th>
                <td data-test="supplier" className="text-right">
                  {loading || !campaign.supplier ? "" : campaign.supplier.title}
                </td>
              </tr>
              <tr key="info-campaign-trademark">
                <th>Vörumerki</th>
                <td data-test="trademark" className="text-right">
                  {loading || !campaign.trademark
                    ? ""
                    : campaign.trademark.title}
                </td>
              </tr>
              <tr key="info-campaign-product">
                <th>Vara</th>
                <td data-test="product" className="text-right">
                  {loading || !campaign.product ? "" : campaign.product.title}
                </td>
              </tr>
              <tr key="info-campaign-stakeholders">
                <th>Hagaðilar</th>
                <td className="text-right">{stakeholders}</td>
              </tr>
              <tr key="info-campaign-franchises">
                <th>Keðjur</th>
                <td className="text-right">
                  {loading
                    ? ""
                    : campaign.franchises.map((franchise) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"franchise-" + franchise.id}
                        >
                          {franchise.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-campaign-stores">
                <th>Verslanir</th>
                <td className="text-right">
                  {loading
                    ? ""
                    : campaign.stores.map((store) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"store-" + store.id}
                        >
                          {store.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-campaign-tasks">
                <th>Tengd verkefni</th>
                <td>
                  {loading
                    ? ""
                    : campaign.linkedTasks.map((task) => {
                        const length = 28;
                        let shortTitle = task.title.substring(0, length);
                        if (shortTitle !== task.title) {
                          shortTitle =
                            task.title.substring(0, length - 3) + "...";
                        }
                        return (
                          <div key={"task-" + task.id}>
                            <CloseTask
                              item={task}
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            />
                            <Link to={"/tasks/" + task.id}>{shortTitle}</Link>
                          </div>
                        );
                      })}
                </td>
              </tr>
              {campaign.stars != null && (
                <React.Fragment>
                  <tr key="info-campaign-stars">
                    <th>Einkunn</th>
                    <td>
                      <ReactStars
                        className="pull-right text-height-0"
                        count={5}
                        size={20}
                        half={false}
                        edit={false}
                        value={campaign.stars}
                      />
                    </td>
                  </tr>
                  <tr key="info-campaign-review">
                    <th>Einkunnar umsögn</th>
                    <td>
                      <div className="text-wrap text-right">
                        {campaign.review}
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        {now > end && (
          <React.Fragment>
            <FinishModalButton campaign={campaign} />
            <Button
              size="sm"
              className="ml-1"
              variant="primary"
              disabled={loading || campaign.locked}
              onClick={async () => {
                const response = await lockCampaign({
                  variables: { campaignId: campaign.id },
                });
              }}
            >
              {campaign.locked ? "læst" : "læsa"}
              <i className="fas fa-lock pl-1" />
            </Button>
          </React.Fragment>
        )}
        <Button
          size="sm"
          className="ml-1"
          variant="primary"
          disabled={loading || campaign.locked}
          href={loading ? "" : "/campaigns/edit/" + campaign.id}
        >
          Breyta
          <i className="fas fa-chevron-right" />
        </Button>

        <Dropdown className="ml-1 d-inline-block">
          <Dropdown.Toggle variant="primary" size="sm">
            <span className="fa fa-bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <CreateTaskModal title={campaign.title} campaign={campaign}>
              {(props) => (
                <Dropdown.Item {...props}>Nýtt verkefni</Dropdown.Item>
              )}
            </CreateTaskModal>
            <Dropdown.Divider />
            <ImageUploader
              as="dropdown"
              campaignId={campaign.id}
              locked={loading ? null : campaign.locked}
            />
            <UploadProfileButton campaign={campaign} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Card>
  );
}

function SalesCard(props) {
  let resultResolver = (item) => {
    return item.result !== null
      ? item.result > item.goal
        ? "success"
        : "danger"
      : "warning";
  };

  const { data = {} } = props;
  const { campaign = {} } = data;

  return (
    <Card title="Markmið">
      <Table responsive size="sm">
        <tbody>
          <tr key="info-campaign-header">
            <th></th>
            <th className="text-right">Sölumarkmið</th>
            <th className="text-right">Lágmark</th>
          </tr>
          <tr key="info-campaign-goal">
            <th>Heild</th>
            <td className="text-right">
              {props.loading ? "..." : campaign.goal}
            </td>
            <td className="text-right">
              {props.loading ? "..." : campaign.minimumGoal}
            </td>
          </tr>
          <tr key="info-campaign-result">
            <th>Raun</th>
            <td
              className={
                "text-right " + resultResolver(props.loading ? {} : campaign)
              }
            >
              {props.loading
                ? "..."
                : resultResolver(campaign) === "warning"
                ? "herferð ólokin"
                : campaign.result}
            </td>
            <td
              className={
                "text-right " + resultResolver(props.loading ? {} : campaign)
              }
            >
              {props.loading
                ? "..."
                : resultResolver(campaign) === "warning"
                ? "herferð ólokin"
                : campaign.result}
            </td>
          </tr>
          <tr key="info-campaign-goal-diff">
            <th>Mismunur</th>
            <td className="text-right">
              {props.loading ? "..." : campaign.result - campaign.goal}
            </td>
            <td className="text-right">
              {props.loading ? "..." : campaign.result - campaign.minimumGoal}
            </td>
          </tr>
          <tr key="info-campaign-goal-diff-percent">
            <th>Hlutfall</th>
            <td className="text-right">
              {props.loading
                ? "..."
                : (
                    ((campaign.result - campaign.goal) / (campaign.goal || 1)) *
                    100
                  ).toFixed(2) + "%"}
            </td>
            <td className="text-right">
              {props.loading
                ? "..."
                : (
                    ((campaign.result - campaign.minimumGoal) /
                      (campaign.minimumGoal || 1)) *
                    100
                  ).toFixed(2) + "%"}
            </td>
          </tr>
        </tbody>
      </Table>
      <GaugeChart
        goal={props.loading ? undefined : campaign.goal}
        minGoal={props.loading ? undefined : campaign.minimumGoal}
        result={props.loading ? undefined : campaign.result}
      />
    </Card>
  );
}

const ADD_COMMENT = gql`
  mutation AddComment($id: ID!, $content: String!) {
    newComment: addCommentCampaign(id: $id, content: $content) {
      content
      date
      user {
        id
        name
      }
    }
  }
`;
function Comments(props) {
  let { campaign, loading } = props;
  if (loading) {
    return <Card title="Athugasemdir">Loading...</Card>;
  }
  const [content, setContent] = useState("");
  const [posting, setPosting] = useState(false);

  const [addComment] = useMutation(ADD_COMMENT, {
    update(cache, { data: { newComment } }) {
      const cachedData = cache.readQuery({
        query: GET_CAMPAIGN,
        variables: { id: campaign.id },
      });

      const campaignCache = {
        ...cachedData.campaign,
        comments: [newComment, ...cachedData.campaign.comments],
      };
      cache.writeQuery({
        query: GET_CAMPAIGN,
        variables: { id: campaign.id },
        data: { campaignCache },
      });
      props.refetch();
    },
  });

  const onKey = async (e) => {
    if (e.key === "Enter" && content != "") {
      setPosting(true);
      const response = await addComment({
        variables: {
          id: campaign.id,
          content,
        },
      });

      setContent("");
      setPosting(false);
    }
  };

  const onChange = (e) => {
    if (posting) {
      return;
    }
    setContent(e.target.value);
  };

  return (
    <Card title="Athugasemdir">
      <Form.Group>
        <Form.Control
          disabled={loading || posting || (campaign || {}).locked}
          type="textarea"
          rows={3}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          onKeyPress={onKey}
        />
      </Form.Group>
      <div className="overflow-auto" style={{ height: "300px" }}>
        {loading
          ? "..."
          : [...(campaign.comments || [])].map((comment, index) => (
              <Media key={"comment-" + index}>
                <Media.Body>
                  <h5>
                    {comment.user.name}
                    {" - "}
                    {dateTimeFormat(new Date(comment.date))}
                  </h5>
                  <p>{comment.content}</p>
                </Media.Body>
              </Media>
            ))}
      </div>
    </Card>
  );
}

function ViewCampaign(props) {
  let campaignId = props.match.params.campaignId;

  const [remove] = useMutation(REMOVE_IMAGE, {
    variables: { projectId: campaignId },
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      let data = cache.readQuery({
        query: GET_CAMPAIGN,
        variables: { id: campaignId },
      });
      let mutData = JSON.parse(JSON.stringify(data));
      Object.set(
        mutData,
        `campaign.images`,
        mutData.campaign.images.filter((item) => item.id !== id)
      );
      cache.writeQuery({ query: GET_CAMPAIGN, data: mutData });
    },
  });

  return (
    <React.Fragment>
      <Query query={GET_CAMPAIGN} variables={{ id: campaignId }}>
        {({ loading, error, data, refetch }) => {
          if (data && !data.campaign) {
            return (
              <Row className="justify-content-md-center">
                <Col xs md="6" xl="4">
                  <Card title="Villa">
                    <h3 className="font-weight-bold text-muted">
                      Engin herferð fannst.
                    </h3>
                  </Card>
                </Col>
              </Row>
            );
          }

          let costExpected = 0;
          let costReal = 0;

          if (!loading && !data) {
            return (
              <Row className="justify-content-md-center">
                <Col xs md="6" xl="4">
                  <Card title="Villa">
                    <h3 className="font-weight-bold text-muted">
                      Villa við birtingu herferðar
                    </h3>
                  </Card>
                </Col>
              </Row>
            );
          }

          if (!loading && data) {
            let acumulate = (current, entry) => {
              if (entry[0] == "__typename") return 0;
              return parseFloat(entry[1] || 0) + current;
            };

            costExpected = Object.entries(data.campaign.costsExpected).reduce(
              acumulate,
              0.0
            );
            costReal = Object.entries(data.campaign.costsReal).reduce(
              acumulate,
              0.0
            );
          }

          return (
            <React.Fragment>
              <Row>
                <Col xs={12} xl={6} key={"information-campaign-" + campaignId}>
                  <BasicInfoCard
                    loading={loading}
                    data={data}
                    refetch={refetch}
                  />

                  <Card title="Kostnaður herferðar">
                    <Table responsive size="sm">
                      <tbody>
                        <tr>
                          <th colSpan="2" className="text-right">
                            Áætlun
                          </th>
                          <th className="text-right">Raun</th>
                        </tr>
                        <tr key={"info-campaign-production"}>
                          <th>Framleiðsla efnis</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["production"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["production"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-radio"}>
                          <th>Útvarp</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["radio"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["radio"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-television"}>
                          <th>Sjónvarp</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["television"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["television"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-internet"}>
                          <th>Netmiðlar</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["internet"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["internet"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-print"}>
                          <th>Prentmiðlar</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["print"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["print"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-franchiseCost"}>
                          <th>Markaðsfé til verslunar</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["franchise"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["franchise"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key={"info-campaign-other"}>
                          <th>Annar kostnaður</th>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsExpected["other"] || 0
                                )}
                          </td>
                          <td className="text-right">
                            {loading
                              ? "..."
                              : currencyFormat(
                                  data.campaign.costsReal["other"] || 0
                                )}
                          </td>
                        </tr>

                        <tr key="info-campaign-category-cost-totals">
                          <th>
                            <h5>Heildarkostnaður</h5>
                          </th>
                          <td className="text-right h5">
                            {loading ? "..." : currencyFormat(costExpected)}
                          </td>
                          <td className="text-right h5">
                            {loading ? "..." : currencyFormat(costReal)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Col>
                <Col xs={12} xl={6} key={"messages-campaign-" + campaignId}>
                  <SalesCard loading={loading} data={data} />
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={{ span: 12 }}>
                  {loading ? null : (
                    <Images
                      title="Myndsafn"
                      images={data.campaign.images
                        .map((x) => x)
                        .sort((a, b) => {
                          let x = beDate(a.date);
                          let y = beDate(b.date);

                          return y - x;
                        })}
                      remove={remove}
                    >
                      <ImageUploader
                        campaignId={campaignId}
                        locked={loading ? null : data.campaign.locked}
                      />
                    </Images>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12} key={"comments-campaign-" + campaignId}>
                  <Comments
                    refetch={refetch}
                    loading={loading}
                    campaign={data?.campaign}
                  />
                </Col>
              </Row>
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

export default ViewCampaign;
