import React, { useRef, useState, useEffect } from "react";

const FailWhale = () => (
  <div className="bg-white p-30 text-center">
    <h2>Opps</h2>
    <img src="https://kurvan-io.imgix.net/404_1.jpg?w=400" />
    <h5>Við fundum ekki það sem þú varst að leita að</h5>
  </div>
);

export default FailWhale;
