import React, { Fragment, useState } from "react";
import { Dropdown, Card, Collapse, Button } from "react-bootstrap";

import ComingSoon from "../../components/ComingSoon";
import { useWindowSize } from "../../utils/reactHooks";

function MainCard({
  children,
  comingSoon,
  title,
  noHeader,
  buttons = [],
  dropdownButtons,
  ...props
}) {
  const windowSize = useWindowSize();
  const [state, setTheState] = useState({
    fullCard: false,
    collapseCard: false,
    loadCard: false,
    cardRemove: false,
  });
  const { fullCard, collapseCard, loadCard, cardRemove } = state;

  const setState = (data) => setTheState({ ...state, ...data });
  const cardRemoveHandler = () => setState({ cardRemove: true });

  const cardReloadHandler = () => {
    setState({ loadCard: true });
    setInterval(() => {
      setState({ loadCard: false });
    }, 3000);
  };

  let fullScreenStyle, loader;
  let card = "";
  let cardClass = [];
  var dropdown = null;
  var visibleButtons = null;
  if (buttons && windowSize.width > 575) {
    visibleButtons = buttons.map((item, i) => {
      if (React.isValidElement(item)) return item;
      const { icon, name, onClick, ...props } = item;
      return (
        <Button key={`buttons-${i}`} onClick={onClick} {...props}>
          {name} {icon}
        </Button>
      );
    });
  }

  if (windowSize.width <= 575 && buttons && buttons.length > 0) {
    dropdown = (
      <Dropdown className="btn-group">
        <Dropdown.Toggle variant="primary" size="sm">
          <span className="fa fa-bars" />
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="list-unstyled card-option">
          {[...buttons, ...(dropdownButtons || [])].map((item, i) => {
            if (React.isValidElement(item)) return item;
            const { icon, name, onClick, ...props } = item;
            return (
              <Dropdown.Item
                as="li"
                key={`dropdown-button-${i}`}
                onClick={onClick}
                {...props}
                className="dropdown-item"
              >
                {icon}
                <a href="#"> {name} </a>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  } else if (dropdownButtons && dropdownButtons.length > 0) {
    dropdown = (
      <Dropdown className="btn-group ml-1">
        <Dropdown.Toggle variant="primary" size="sm">
          <span className="fa fa-bars" />
        </Dropdown.Toggle>
        <Dropdown.Menu as="ul" className="list-unstyled card-option">
          {dropdownButtons.map((item, i) => {
            if (React.isValidElement(item)) return item;
            const { icon, name, onClick, ...props } = item;
            return (
              <Dropdown.Item
                as="li"
                key={`dropdown-button-${i}`}
                className="dropdown-item"
                onClick={onClick}
                {...props}
              >
                {icon}
                <a href="#"> {name} </a>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  const cardHeaderRight = (
    <div
      style={{
        display: "inline-block",
        right: "10px",
        top: "10px",
        float: "right",
        padding: 0,
        position: "absolute",
      }}
    >
      {visibleButtons}
      {dropdown}
    </div>
  );

  if (loadCard) {
    cardClass = [...cardClass, "card-load"];
  }

  if (cardRemove) {
    cardClass = [...cardClass, "d-none"];
  }

  if (props.cardClass) {
    cardClass = [...cardClass, props.cardClass];
  }

  return (
    <Fragment>
      <Card
        className={cardClass.join(" ") + " " + props.className}
        style={fullScreenStyle}
      >
        {comingSoon && <ComingSoon />}
        {!noHeader && (
          <Card.Header>
            <Card.Title as="h4">{title}</Card.Title>
            {cardHeaderRight}
          </Card.Header>
        )}
        <Collapse in={!collapseCard}>
          <div>
            <Card.Body>{children}</Card.Body>
          </div>
        </Collapse>
        {loadCard && (
          <div className="card-loader justify-content-center">
            <div className="spinner-border mr-1 text-primary" role="status" />
          </div>
        )}
      </Card>
    </Fragment>
  );
}

export default MainCard;
