function RNG(seed) {
  // LCG using GCC's constants
  this.m = 0x80000000; // 2**31;
  this.a = 1103515245;
  this.c = 12345;

  if (typeof seed === "string" || seed instanceof String) {
    let buffer = 65741;
    for (var i = 0; i < seed.length; ++i) {
      var code = seed.charCodeAt(i);
      buffer += code;
    }
    seed = buffer;
  }

  this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
}
RNG.prototype.nextInt = function () {
  this.state = (this.a * this.state + this.c) % this.m;
  return this.state;
};
RNG.prototype.nextFloat = function () {
  // returns in range [0,1]
  return this.nextInt() / (this.m - 1);
};
RNG.prototype.nextRange = function (start, end) {
  // returns in range [start, end): including start, excluding end
  // can't modulu nextInt because of weak randomness in lower bits
  var rangeSize = end - start;
  var randomUnder1 = this.nextInt() / this.m;
  return start + Math.floor(randomUnder1 * rangeSize);
};
RNG.prototype.choice = function (array) {
  return array[this.nextRange(0, array.length)];
};

RNG.prototype.next48BitHexNumber = function () {
  const rangeNumber = this.nextRange(0, 0xffffff << 0);

  return rangeNumber.toString(16).length == 5
    ? "0" + rangeNumber.toString(16)
    : rangeNumber.toString(16);
};

RNG.prototype.nextHexColor = function () {
  return "#" + this.next48BitHexNumber();
};

export default function RNGInstance(seed) {
  return new RNG(seed);
}
