import React, { Component, useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Modal,
  Media,
  ButtonGroup,
  Badge,
} from "react-bootstrap";
import Select from "react-select";
import { Mutation } from "@apollo/client/react/components";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";

import UserSelect from "./UserSelect";
import CategorySelect, { GET_CATEGORIES } from "./CategorySelect";
import TrademarkSelect, { GET_TRADEMARKS } from "./TrademarkSelect";
import SupplierSelect, { GET_SUPPLIERS } from "./SupplierSelect";
import NewCategoryModal from "./Categories/NewCategoryModal";
import NewTrademarkModal from "./NewTrademarkModal";
import NewSupplierModal from "./NewSupplierModal";
import { UserContext } from "./../UserContext";

import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import FailWhale from "../components/FailWhale";
import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";
import SelectWithData from "./SelectWithData";
import { useRest } from "../utils/Rest";

export const GET_FRANCHISES = gql`
  query GetFranchises {
    options: franchises {
      value: id
      label: name
    }
  }
`;

export default function Edit(props) {
  const id = props.match.params.id;
  //const { data:franchises } = useRest("/api/franchises");
  const { data: products } = useRest("/api/products");
  const { data: categories } = useRest("/api/categories");
  const { data: subcategories } = useRest("/api/sub_categories");
  const {
    data: item,
    loading,
    persist,
    remove,
  } = useRest(`/api/shelf-analysis/${id}`);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const [formData, setFormData] = useState({
    title: "",
    ratio: 0,
    dateStart: new Date(),
    dateEnd: new Date(),
    franchises: [],
    stores: [],
    products: [],
    requestService: null,
  });

  useEffect(() => {
    const i = item || {};
    const data = {
      id: i.id,
      title: i.title || "",
      ratio: i.shelfRatio || formData.ratio,
      dateStart: i.dateStart ? new Date(i.dateStart || null) : new Date(),
      dateEnd: i.dateEnd
        ? new Date(i.dateEnd || null)
        : new Date().addMonths(1),
      products: (i.filter || [])
        .filter((r) => r.type === "product")
        .map((r) => r.value),
      categoryId: (i.filter || []).find((r) => r.type === "category")?.value,
      subcategoryId: (i.filter || []).find((r) => r.type === "sub_category")
        ?.value,
      franchises: (i.franchises || []).map((f) => ({
        label: f.name,
        value: f.id,
      })),
    };
    setFormData(data);
    setSubmitting(false);
  }, [item]);

  const handleUpdate = (name, value) => {
    setFeedback("");
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputUpdate = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (e.target.type === "number") {
      value = parseFloat(value);
    }

    handleUpdate(name, value);
  };

  const handleSubmit = () => async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    form.checkValidity();

    if (formData.title == "") {
      setFeedback("Þarf að gefa heiti");
      return;
    }

    try {
      setSubmitting(true);
      let filter = products
        .filter((p) => formData.products.includes(p.id))
        .map((p) => ({ type: "product", name: p.title, value: p.id }));

      if (formData.categoryId) {
        const category = categories.find((c) => c.id == formData.categoryId);
        filter.unshift({
          type: "category",
          name: category.title,
          value: formData.categoryId,
        });
      }

      if (formData.subcategoryId) {
        const subcategory = subcategories.find(
          (c) => c.id == formData.subcategoryId
        );
        filter.unshift({
          type: "sub_category",
          name: subcategory.title,
          value: formData.subcategoryId,
        });
      }

      let data = {
        id: formData.id,
        title: formData.title,
        dateStart: formData.dateStart.toISOString(),
        dateEnd: formData.dateEnd.toISOString(),
        categoryId: formData.categoryId,
        franchiseIds: formData.franchises.map((i) => i.value),
        filter,
        shelfRatio: formData.ratio,
      };
      setSubmitting(false);
      if (formData.id) {
        await persist(data);
        //props.history.push(`/shelf-analysis/${formData.id}`);
        window.location.replace(`/shelf-analysis/${formData.id}`);
      } else {
        data.requestService = formData.requestService;
        await persist(data);
        //props.history.push("/shelf-analysis");
        window.location.replace("/shelf-analysis");
      }
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      setError(error.message);
    }
  };

  const handleRemove = () => {
    remove();
    window.location.replace("/shelf-analysis");
  };

  if (id !== undefined && item === null) {
    return <FailWhale />;
  }

  if (error) {
    return (
      <React.Fragment>
        <Col>
          <Card title="Grunn upplýsingar">
            <h3>{error}</h3>
          </Card>
        </Col>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit()}>
        <Row>
          <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <Card title="Hillugreining">
              {feedback && <div style={{ color: "red" }}>{feedback}</div>}
              <Form.Group>
                <Form.Row key={"shelfanalysis-form-title"}>
                  <Form.Label column lg={5}>
                    Heiti
                  </Form.Label>
                  <Col>
                    <Form.Control
                      required
                      name={"title"}
                      type={"text"}
                      value={formData.title}
                      onChange={handleInputUpdate}
                    />
                  </Col>
                </Form.Row>

                <Form.Row key={"shelfanalysis-form-dateStart"}>
                  <Form.Label column lg={5}>
                    Hefst
                  </Form.Label>
                  <Col>
                    <DatePicker
                      selected={formData.dateStart}
                      wrapperClassName="w-100"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => handleUpdate("dateStart", date)}
                      className="form-control"
                    />
                  </Col>
                </Form.Row>

                <Form.Row key={"shelfanalysis-form-dateEnd"}>
                  <Form.Label column lg={5}>
                    Lýkur
                  </Form.Label>
                  <Col>
                    <DatePicker
                      selected={formData.dateEnd}
                      wrapperClassName="w-100"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={(date) => handleUpdate("dateEnd", date)}
                      className="form-control"
                    />
                  </Col>
                </Form.Row>
                <Form.Row key={"shelfanalysis-form-category"}>
                  <Form.Label column lg={5}>
                    Flokkur
                  </Form.Label>
                  <Col>
                    <select
                      className="basic-single form-control"
                      value={formData.categoryId}
                      onChange={(e) =>
                        handleUpdate("categoryId", e.target.value)
                      }
                    >
                      <option>Velja flokk</option>
                      {categories
                        ?.filter(() => true)
                        .sort((a, b) => a.title.localeCompare(b.title))
                        .map((c) => (
                          <option value={c.id}>{c.title}</option>
                        ))}
                    </select>
                  </Col>
                </Form.Row>
                {formData.categoryId && (
                  <Form.Row key={"shelfanalysis-form-subcategory"}>
                    <Form.Label column lg={5}>
                      Undirflokkur
                    </Form.Label>
                    <Col>
                      <select
                        className="form-control px-2"
                        value={formData.subcategoryId}
                        onChange={(e) => {
                          handleUpdate("subcategoryId", e.target.value);
                        }}
                      >
                        <option value={null}></option>
                        {(subcategories || [])
                          .filter((p) => p.categoryId == formData.categoryId)
                          .map((p) => (
                            <option value={p.id}>{p.title}</option>
                          ))}
                      </select>
                    </Col>
                  </Form.Row>
                )}
                <Form.Row key={"pricesurvey-form-product"}>
                  <Form.Label column lg={5}>
                    Vörur
                  </Form.Label>
                  <Col>
                    {(products || [])
                      .filter((p) => formData.products.includes(p.id))
                      .map((s) => (
                        <ButtonGroup
                          size="sm"
                          className="mr-1 mb-1"
                          key={"product-" + s.id}
                        >
                          <Button
                            variant="primary"
                            onClick={() => {
                              const newProducts = formData.products.filter(
                                (o) => o != s.id
                              );
                              handleUpdate("products", newProducts);
                            }}
                          >
                            {s.title} <i className="fas fa-times" />
                          </Button>
                        </ButtonGroup>
                      ))}
                    <Select
                      closeMenuOnSelect={false}
                      controlShouldRenderValue={false}
                      isMulti
                      placeholder={""}
                      onChange={(e) => {
                        handleUpdate(
                          "products",
                          e.map((p) => p.value)
                        );
                      }}
                      options={(products || []).map((p) => ({
                        label: p.title,
                        value: p.id,
                      }))}
                      value={(products || [])
                        .filter((p) => formData.products.includes(p.id))
                        .map((p) => ({ label: p.title, value: p.id }))}
                    />
                  </Col>
                </Form.Row>
                <Form.Row key="shelfanalysis-form-frachises">
                  <Form.Label column lg={5}>
                    Keðjur
                  </Form.Label>
                  <Col>
                    {(formData.franchises || []).map((s) => (
                      <ButtonGroup
                        size="sm"
                        className="mr-1 mb-1"
                        key={"franchise-" + s.value}
                      >
                        <Button
                          variant="primary"
                          onClick={() => {
                            const newFranchises = formData.franchises.filter(
                              (o) => o.value != s.value
                            );
                            handleUpdate("franchises", newFranchises);
                          }}
                        >
                          {s.label} <i className="fas fa-times" />
                        </Button>
                      </ButtonGroup>
                    ))}
                    <SelectWithData
                      closeMenuOnSelect={false}
                      controlShouldRenderValue={false}
                      isMulti
                      query={GET_FRANCHISES}
                      placeholder={""}
                      onChange={(e) => {
                        handleUpdate("franchises", e);
                      }}
                      value={formData.franchises}
                    />
                  </Col>
                </Form.Row>
                <Form.Row key="shelfanalysis-form-ratio">
                  <Form.Label column lg={5}>
                    Ásett hlutfall vörumerkja
                  </Form.Label>
                  <Col>
                    <Form.Control
                      required
                      name={"ratio"}
                      type={"number"}
                      value={formData.ratio}
                      onChange={handleInputUpdate}
                    />
                  </Col>
                </Form.Row>
              </Form.Group>
            </Card>
          </Col>
          {!loading && formData.id === undefined && (
            <Col xs={12} xl={{ span: 10, offset: 1 }}>
              <Card title="Óska eftir þjónustu">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    name="requestService"
                    onChange={(e) => {
                      handleUpdate("requestService", e.target.value);
                    }}
                    rows={3}
                  />
                </Form.Group>
              </Card>
            </Col>
          )}
          <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <Card noHeader>
              <div className="text-right">
                {!loading && formData.id !== undefined && (
                  <button
                    data-test="remove"
                    type="button"
                    className="btn btn-danger mr-1"
                    onClick={() => {
                      setShowConfirmDelete(true);
                    }}
                  >
                    Eyða
                  </button>
                )}
                <button
                  data-test="submit"
                  type="submit"
                  disabled={submitting}
                  className="btn btn-success"
                >
                  Vista
                </button>
              </div>
            </Card>
          </Col>
          <Confirm
            show={showConfirmDelete}
            title="Ertu viss?"
            onConfirm={async () => {
              handleRemove();
              setShowConfirmDelete(false);
            }}
            onClose={() => {
              setShowConfirmDelete(false);
            }}
          />
        </Row>
      </Form>
    </React.Fragment>
  );
}
