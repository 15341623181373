import React, { Component, useState } from "react";
import { Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import NewProductModal from "./NewProductModal";

import Card from "../App/components/MainCard";
import Confirm from "../components/Confirm";
import { hasData } from "../utils/Graphql";

import { gql, useQuery, useMutation } from "@apollo/client";

const GET_PRODUCTS = gql`
  query GetProducts {
    allProducts {
      id
      title
    }
  }
`;

const UPDATE_PRODUCT = gql`
  mutation AddProduct($id: ID!, $title: String!) {
    updated: updateProduct(id: $id, title: $title) {
      id
      title
    }
  }
`;

const DELETE_PRODUCT = gql`
  mutation AddProduct($id: ID!) {
    deleted: removeProduct(id: $id) {
      id
      title
    }
  }
`;

function ProductItem(props) {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const { item } = props;

  const [remove, { loading }] = useMutation(DELETE_PRODUCT, {
    variables: { id: item.id },
    optimisticResponse: {
      __typename: "Mutation",
      deleted: {
        id: item.id,
      },
    },
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      const queryResult = cache.readQuery({
        query: GET_PRODUCTS,
      });
      if (queryResult) {
        cache.writeQuery({
          query: GET_PRODUCTS,
          data: {
            allProducts: queryResult.allProducts.filter(
              (product) => product.id !== id
            ),
          },
        });
      }
    },
  });

  return (
    <React.Fragment>
      <tr
        key={"product-" + item.id}
        id={"product-" + item.id}
        data-id={item.id}
        onClick={() => {
          setShowUpdate(true);
        }}
      >
        <td className="align-middle">{item.title}</td>
      </tr>
      {/*
        <td className="align-middle">
          <Button size="sm" variant="outline-primary" className="mr-1">
            Skoða nánar
            <i className="fas fa-chevron-right" />
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirmDelete(true);
            }}
          >
            <i className="fas fa-trash" />
          </Button>
        </td>
      </tr>
      <UpdateProductModal
        item={item}
        show={showUpdate}
        onClose={() => setShowUpdate(false)}
      />
      <Confirm
        show={showConfirmDelete}
        title="Ertu viss?"
        confirm="Já"
        cancel="hætta við"
        onConfirm={() => {
          remove();
          setShowConfirmDelete(false);
        }}
        onClose={() => {
          setShowConfirmDelete(false);
        }}
      />
      */}
    </React.Fragment>
  );
}

function UpdateProductModal(props) {
  const [title, setTitle] = useState(props.item.title);
  const [muterror, setMuterror] = useState(null);
  const [update, { loading }] = useMutation(UPDATE_PRODUCT);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { data, error } = await update({
      variables: { id: props.item.id, title },
    });

    if (error) {
      setError("Failed to update Product");
    } else {
      props.onClose();
    }
  };

  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Uppfæra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Titill</Form.Label>
          <Form.Control
            required
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          {muterror}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

function Productlist() {
  const { loading, error, data } = useQuery(GET_PRODUCTS);

  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  return data.allProducts
    .filter(() => true)
    .sort((a, b) => a.title.localeCompare(b.title))
    .map((item) => <ProductItem key={item.id} item={item} />);
}

function Products(props) {
  const [showNew, setShowNew] = useState(false);

  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs lg="12">
          <Card title="Vörur">
            {/*
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => setShowNew(true)}
            >
              Ný vara
              <i className="fas fa-chevron-right" />
            </Button>
            */}
            <Table responsive hover>
              <thead>
                <tr>
                  <th>Titill</th>
                </tr>
              </thead>
              <tbody>{<Productlist />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <NewProductModal show={showNew} onClose={() => setShowNew(false)} />
    </React.Fragment>
  );
}

export default Products;
