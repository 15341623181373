import React, { useContext, Fragment } from "react";
import { Dropdown } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";

import { UserContext } from "../../../../../UserContext";
import UserProfileImage from "../../../../../components/UserProfileImage";

import { gql, useMutation } from "@apollo/client";

const ChangePasswordRequest = gql`
  mutation ChangePasswordRequest($userId: ID!) {
    request: getChangePasswordRequest(userId: $userId) {
      userId
      token
    }
  }
`;

function NavRight(props) {
  const history = useHistory();
  const { pathname } = useLocation();
  const user = useContext(UserContext);
  const [getRequest] = useMutation(ChangePasswordRequest);

  return (
    <Fragment>
      <ul className="navbar-nav ml-auto">
        <li>
          <Dropdown alignRight={!props.rtlLayout} className="drp-user">
            <Dropdown.Toggle>
              {/*<Image src={Avatar1} roundedCircle className="profile-picture" />*/}
              <UserProfileImage size={30} user={user} />
              <span>{user.name}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu
              alignRight={!props.rtlLayout}
              className="dropdown-menu-right"
            >
              <ul className="pro-body">
                <li>
                  <Dropdown.Item
                    onClick={async () => {
                      const response = await getRequest({
                        variables: { userId: user.id },
                      });
                      const request = response.data?.request;

                      if (!request) {
                        console.log("failed to get change password request");
                        return;
                      }

                      history.push(
                        `/reset/${request.userId}/${request.token}?redirect=${pathname}`
                      );
                    }}
                  >
                    <i className="fa fa-key" /> Breyta lykilorði
                  </Dropdown.Item>
                  <Dropdown.Item href="/logout">
                    <i className="fa fa-sign-out-alt" /> Skrá út
                  </Dropdown.Item>
                </li>
                {/*<li><Dropdown.Item href="/settings"><i className="feather icon-settings"/> Stillingar</Dropdown.Item></li>
                <li><Dropdown.Item href="/profile"><i className="feather icon-user"/> Prófill</Dropdown.Item></li>*/}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </Fragment>
  );
}

export default NavRight;
