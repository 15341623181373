import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import { gql, useQuery, useMutation } from "@apollo/client";
import ReactTimeAgo from "react-time-ago";
import DatePicker from "react-datepicker";

import MainCard from "../App/components/MainCard";
import UserProfileImage from "../components/UserProfileImage";
import { Loading } from "../utils/Dots";
import { UserContext } from "./../UserContext";
import { CloseTask } from "./Tasks";

const TASK_FRAGMENT = gql`
  fragment TaskFields on KurvanTask {
    id
    title
    closed
    description
    dueDate
    assignee {
      id
      name
      profileImageUrl
    }
    image {
      id
      url
      alt
    }
    qa {
      id
      title
    }
    campaign {
      id
      title
    }
    comments {
      t: type
      content
      date
      user {
        id
        name
        profileImageUrl
      }
    }
    followers {
      id
      name
      email
      profileImageUrl
    }
  }
`;

export const GET_TASK = gql`
  ${TASK_FRAGMENT}
  query GetTask($id: ID!) {
    task(id: $id) {
      ...TaskFields
    }
    users {
      id
      name
      profileImageUrl
    }
    allQA {
      id
      title
    }
    campaigns {
      id
      title
    }
  }
`;

const UPDATE_TASK = gql`
  ${TASK_FRAGMENT}
  mutation UpdateTask(
    $id: ID!
    $title: String
    $description: String
    $dueDate: String
    $assigneeId: ID
    $imageId: ID
    $qaId: ID
    $campaignId: ID
  ) {
    updated: updateTask(
      id: $id
      title: $title
      description: $description
      dueDate: $dueDate
      assigneeId: $assigneeId
      imageId: $imageId
      qaId: $qaId
      campaignId: $campaignId
    ) {
      ...TaskFields
    }
  }
`;

const ADD_COMMENT = gql`
  ${TASK_FRAGMENT}
  mutation AddComment($id: ID!, $content: String!) {
    newComment: addCommentToTask(taskId: $id, content: $content) {
      ...TaskFields
    }
  }
`;

const ADD_FOLLOWER = gql`
  ${TASK_FRAGMENT}
  mutation AddFollower($id: ID!, $followerId: ID!) {
    addFollowerToTask(taskId: $id, newFollowerId: $followerId) {
      ...TaskFields
    }
  }
`;

const REMOVE_FOLLOWER = gql`
  ${TASK_FRAGMENT}
  mutation RemoveFollower($id: ID!, $followerId: ID!) {
    removeFollowerFromTask(taskId: $id, followerId: $followerId) {
      ...TaskFields
    }
  }
`;

const UploadTaskImages = (taskId, images) =>
  new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open("POST", "/upload/task-images");
    formData.append("taskId", taskId);
    images.map((image) => {
      formData.append("files", image, image.name);
    });

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        var status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          resolve(request.responseText);
        } else {
          reject(status);
        }
      }
    };
    request.send(formData);
  });

function AddFollowers({ users, task }) {
  const [show, setShow] = useState(false);
  const [addFollower] = useMutation(ADD_FOLLOWER);
  const size = 25;

  return !show ? (
    <Button
      title="Bæta við fylgjanda"
      className="btn-icon"
      variant="success"
      style={{
        width: size,
        height: size,
        fontSize: `${size * 0.66}px`,
      }}
      onClick={() => {
        setShow(true);
      }}
    >
      <i className="fa fa-plus" aria-hidden="true"></i>
    </Button>
  ) : (
    <Form.Control
      tabIndex={3}
      as="select"
      onChange={(e) => {
        setShow(false);
        addFollower({
          variables: {
            id: task?.id,
            followerId: e.target.value,
          },
        });
      }}
    >
      <option key={0} value={null}></option>
      {users?.map((user) => (
        <option key={user.id} value={user.id}>
          {user.name}
        </option>
      ))}
    </Form.Control>
  );
}

function ViewTask({ refreshData, loading, task, users, updateTask, ...props }) {
  const [formData, setFormData] = useState({});
  const [unsaved, setUnsaved] = useState(false);

  const updateFormDataState = (newState) => {
    setFormData({
      ...formData,
      ...newState,
    });
    setUnsaved(true);
  };

  const taskToFormData = ({ dueDate, ...rest }) => ({
    dueDate: dueDate ? new Date(dueDate) : null,
    ...rest,
  });

  const formDataToMutationArgs = ({
    dueDate,
    assignee,
    qa,
    campaign,
    ...rest
  }) => ({
    dueDate: dueDate ? dueDate.toISOString() : null,
    assigneeId: assignee?.id,
    qaId: qa?.id,
    campaignId: campaign?.id,
    ...rest,
  });

  useEffect(
    (e) => {
      setFormData(taskToFormData(task || {}));
    },
    [task]
  );

  useEffect(
    (e) => {
      if (!unsaved) return;
      const handle = setTimeout(() => {
        updateTask(formDataToMutationArgs(formData));
        setUnsaved(false);
      }, 500);
      return () => {
        clearTimeout(handle);
      };
    },
    [formData]
  );

  const onDrop = useCallback(
    async (acceptedImages) => {
      await UploadTaskImages(task.id, acceptedImages);

      refreshData();
    },
    [task]
  );

  return (
    <Card style={{ padding: "15px", position: "relative" }}>
      <Row>
        <Col md={12} style={{ marginBottom: 15 }}>
          <CloseTask
            style={{
              float: "left",
              width: "50px",
              fontSize: "40px",
            }}
            item={task}
          />
          <Form.Control
            tabIndex={1}
            as="input"
            style={{
              width: "calc(100% - 50px)",
              fontSize: "24px",
              fontWeight: "bold",
            }}
            value={formData.title || ""}
            onChange={(e) => updateFormDataState({ title: e.target.value })}
          />
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Ábyrgð</Form.Label>
            <Form.Control
              tabIndex={3}
              as="select"
              value={formData?.assignee?.id || undefined}
              onChange={(e) => {
                updateFormDataState({
                  assignee: users.find((i) => i.id === e.target.value),
                });
              }}
            >
              {formData?.assignee?.id || <option key={0} value={null}></option>}
              {users?.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Þarf að klárast fyrir</Form.Label>
            <DatePicker
              className="form-control"
              wrapperClassName="w-100"
              dateFormat="dd/MM/yyyy"
              placeholderText="dd/mm/yyyy"
              selected={formData?.dueDate}
              onChange={(e) => {
                updateFormDataState({ dueDate: e });
              }}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Herferð / Gæðaeftirlit</Form.Label>
            <Form.Control
              as="select"
              className="basic-single form-control"
              value={formData.campaign?.id || formData.qa?.id || undefined}
              onChange={(e) => {
                const eValue = e.target.value;

                const foundQA = props.qas.filter((i) => i.id == eValue);
                const foundCampaign = props.campaigns.filter(
                  (i) => i.id == eValue
                );

                if (foundQA.length > 0) {
                  updateFormDataState({
                    qa: foundQA[0],
                    campaign: undefined,
                  });
                } else if (foundCampaign.length > 0) {
                  updateFormDataState({
                    qa: undefined,
                    campaign: foundCampaign[0],
                  });
                } else {
                }
              }}
            >
              {formData.campaign?.id || formData.qa?.id || (
                <option key={0} value={null}></option>
              )}
              {[...(props.campaigns || []), ...(props.qas || [])].map(
                (item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                )
              )}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Lýsing</Form.Label>
            <Form.Control
              as="textarea"
              value={formData.description}
              onChange={(e) =>
                updateFormDataState({ description: e.target.value })
              }
            />
          </Form.Group>
        </Col>

        <Col md={12}>
          <Comments
            {...{
              loading,
              refreshData,
              task,
              onDrop,
            }}
          />
        </Col>

        <Col md={12}>
          <Form.Group>
            Fylgjendur: <br />
            {formData.followers?.map((follower) => (
              <span
                style={{
                  position: "relative",
                  marginRight: "5px",
                }}
              >
                <UserProfileImage
                  key={follower.id}
                  user={follower}
                  size={25}
                  title={`${follower.name} <${follower.email}>`}
                />
                <Button
                  className="btn-icon"
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    width: "14px",
                    height: "14px",
                    fontSize: "10px",
                  }}
                  onClick={() => {
                    props.removeFollower(follower.id);
                  }}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </Button>
              </span>
            ))}
            <AddFollowers
              {...{
                users,
                task,
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </Card>
  );
}

function Comments({ refreshData, task, loading, onDrop }) {
  const [content, setContent] = useState("");
  const [posting, setPosting] = useState(false);
  const [commenting, setCommenting] = useState(false);
  const [addComment] = useMutation(ADD_COMMENT);
  const currentUser = useContext(UserContext);

  const doPost = async () => {
    if (content == "") return;
    setPosting(true);
    const response = await addComment({
      variables: {
        id: task.id,
        content,
      },
    });

    setContent("");
    setPosting(false);
  };

  const onKey = async (e) => {
    if (e.key === "Enter") {
      doPost();
    }
  };

  const onChange = (e) => {
    if (posting) {
      return;
    }
    setContent(e.target.value);
  };

  const displayByType = (comment) => {
    switch (comment.t) {
      case "IMAGE":
        return <img src={comment.content + "?max-w=250&h=250"} />;

      default:
        return <span>{comment.content}</span>;
    }
  };

  return (
    <Fragment>
      <div className="overflow-auto">
        {(task?.comments || []).map((comment, index) => (
          <div key={index}>
            <UserProfileImage
              user={comment.user}
              size={40}
              style={{
                float: "left",
                marginRight: 10,
              }}
            />
            <div style={{ display: "inline-block" }}>
              <div style={{}}>
                <b>{comment.user.name}</b>
                &nbsp;
                <span style={{ color: "#888" }}>
                  <ReactTimeAgo date={new Date(comment.date)} />
                </span>
              </div>
              <p>{displayByType(comment)}</p>
            </div>
          </div>
        ))}
      </div>
      {posting ? (
        <Loading />
      ) : (
        <Form.Group
          onFocus={(e) => setCommenting(true)}
          onBlur={(e) => setCommenting(false)}
        >
          <UserProfileImage
            user={currentUser}
            style={{
              float: "left",
              marginRight: 10,
            }}
          />
          <Form.Control
            disabled={loading || posting}
            type="textarea"
            style={{
              display: "inline-block",
              width: "calc(100% - 50px)",
            }}
            rows={3}
            placeholder="Spurðu spurningar eða segðu hvað er búið að gerast"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyPress={onKey}
          />
          {commenting && false && (
            <Form.Control as="button" onClick={async (e) => await doPost()}>
              Comment
            </Form.Control>
          )}

          <div
            style={{
              position: "absolute",
              bottom: "0px",
              right: "8px",
            }}
          >
            <label htmlFor="imageupload">
              <div
                className="btn btn-primary btn-icon"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              >
                <input
                  id="imageupload"
                  type="file"
                  accept="image/*"
                  tabIndex={-1}
                  multiple={true}
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    setPosting(true);
                    await onDrop(Array.from(e.target.files));
                    setPosting(false);
                  }}
                />
                <i className="fa fa-file-image f-18"></i>
              </div>
            </label>

            <div
              title="Senda"
              className="btn btn-primary btn-icon ml-1"
              style={{
                width: "30px",
                height: "30px",
              }}
              onClick={async (e) => await doPost()}
            >
              <i className="fa fa-chevron-right f-18"></i>
            </div>
          </div>
        </Form.Group>
      )}
    </Fragment>
  );
}

function ViewTaskWithData(props) {
  const { loading, error, data, refetch } = useQuery(GET_TASK, {
    variables: { id: props.match.params.id },
  });

  const [update] = useMutation(UPDATE_TASK);
  const [removeFollower] = useMutation(REMOVE_FOLLOWER);

  if (error && !(typeof error === "undefined")) {
    return (
      <MainCard>
        <h3>404 Verk fannst ekki</h3>
        <div>Muff: {typeof error}</div>
      </MainCard>
    );
  }

  return (
    <ViewTask
      loading={loading}
      task={data?.task}
      users={data?.users}
      campaigns={data?.campaigns}
      qas={data?.allQA}
      refreshData={refetch}
      removeFollower={(followerId) => {
        removeFollower({
          variables: {
            id: data?.task?.id,
            followerId,
          },
        });
      }}
      updateTask={(newData) => {
        update({
          variables: {
            id: data?.task?.id,
            ...newData,
          },
        });
      }}
    />
  );
}

export default ViewTaskWithData;
