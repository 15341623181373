import React, { useState, useContext } from "react";
import { gql, useQuery, useMutation } from "@apollo/client";

import UserModal from "./UserModal";

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: ID!
    $name: String!
    $email: String!
    $phone: String!
    $profileImageUrl: String
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      phone: $phone
      profileImageUrl: $profileImageUrl
    ) {
      id
      name
      email
      phone
      profileImageUrl
    }
  }
`;

function EditUserModal(props) {
  const [mutation] = useMutation(UPDATE_USER);

  const persist = async (data, callback) => {
    const { error } = await mutation({
      variables: data,
    });

    if (error) {
      setError("Failed to persist User");
    } else {
      props.onClose(true);
    }

    if (callback) callback();
  };

  return (
    <UserModal
      {...{
        ...props,
        persist,
        title: "Uppfæra notanda",
      }}
    />
  );
}

export default EditUserModal;
