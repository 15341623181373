import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat } from "../Formatting";
import { useRest } from "../../utils/Rest";
import { LineCanvas } from "@nivo/line";
import { BarCanvas } from "@nivo/bar";
import Sugar from "sugar";

export default function ProductReportPerFranchise(props) {
  //const { data } = GetData();
  const [selectedProduct, setSelectedProduct] = useState();
  let id = props.match.params.id;
  const { data: measure, loading } = useRest(`/api/area_measurements/${id}`);
  const { data } = useRest(`/api/reports/measurement_report/${id}`);

  if (loading) {
    return <div>loading...</div>;
  }

  const Title = (
    <div>
      <div>Mælingar skýrsla</div>
    </div>
  );

  var totalSlots = (data || []).map((p) => p.slot_count).sum();

  //var franchises = (points || [] ).map(p=> p.franchise).unique();
  //var suppliers = (points || [] ).map(p=> p.supplier).unique();

  //<img src={measure.i} />
  return (
    <div className="w-100 d-flex">
      <div className="mx-2" style={{ width: "640px" }}>
        <Card title={Title}>
          <h6>
            {dateFormat(measure.date)} - {measure.storeArea.title} -{" "}
            {measure.store.name}
          </h6>
          <img
            src={
              "https://kurvan.imgix.net/" +
              measure.image.bucketPath +
              "?width=600"
            }
          />
        </Card>
      </div>
      <div className="w-100 flex-grow-2 overflow-hidden">
        <div className="rounded-sm mx-2 my-1 bg-white overflow-hidden">
          <Table responsive size="sm" className="p-1">
            <thead>
              <tr>
                <th>Birgjar</th>
                <th>Vara</th>
                <th>Fjöldi Feisinga</th>
                <th>Hlutfall</th>
              </tr>
            </thead>
            <tbody>
              {(data || []).map((r, i) => (
                <tr key={i}>
                  <td>{r.supplier}</td>
                  <td>{r.product}</td>
                  <td>{r.slot_count}</td>
                  <td className="text-right">
                    {((r.slot_count / totalSlots) * 100).toFixed(0)}%
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
