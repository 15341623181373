import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import AppLogoKurvan from "../assets/white_logo_transparent_background.png";
import AppLogoHG from "../assets/hg-logo-1.png";
import queryString from "query-string";

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $userId: ID!
    $token: String!
    $newPassword: String!
    $againPassword: String!
  ) {
    resetPassword(
      userId: $userId
      token: $token
      newPassword: $newPassword
      againPassword: $againPassword
    )
  }
`;

function Reset() {
  const { userId, token } = useParams();
  const history = useHistory();
  const { search } = useLocation();
  const { redirect } = queryString.parse(search);
  const [newPassword, setNewPassword] = useState("");
  const [againPassword, setAgainPassword] = useState("");
  const [validationError, setValidationError] = useState(null);

  const [reset, { loading, error }] = useMutation(RESET_PASSWORD, {
    errorPolicy: "all",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (newPassword !== againPassword) {
      setValidationError("Lykilorðin eru ekki eins!");
      return;
    }

    await reset({
      variables: {
        userId,
        token,
        newPassword,
        againPassword,
      },
    })
      .then((result) => {
        const { data } = result;
        if (data?.resetPassword) {
          if (redirect) {
            history.push(redirect);
          } else {
            history.push("/");
          }
        } else {
          setValidationError("Ekki tókst að breyta lykilorði");
        }
      })
      .catch((error) => {
        console.log("Errors:", error);
      });
  };
  return (
    <Row
      className="justify-content-center align-items-center text-center"
      style={{
        height: 700,
      }}
    >
      <Col
        xs={12}
        style={{
          maxWidth: "400px",
        }}
      >
        <img
          onClick={(e) => {
            window.location = "/";
          }}
          src={AppLogoHG}
          alt="Herferðagreining"
          style={{
            width: "100px",
            marginBottom: "50px",
          }}
        />
        <p className="h3 text-white">Velja nýtt lykilorð</p>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-4" controlId="newPassword">
            <Form.Control
              required
              minLength="10"
              className="bg-light"
              type="password"
              placeholder="Nýtt Lykilorð"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="againPassword">
            <Form.Control
              required
              minLength="10"
              className="bg-light"
              type="password"
              placeholder="Sama Lykilorð"
              value={againPassword}
              onChange={(e) => setAgainPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="Submit">
            <Button className="w-75 f-w-600" variant="primary" type="submit">
              {loading ? "Vinna..." : "Áfram"}
            </Button>
            {(error?.graphQLErrors || []).map((e) => (
              <small className="form-text text-warning">{e.message}</small>
            ))}
            {validationError && (
              <small className="form-text text-warning">
                {validationError}
              </small>
            )}
          </Form.Group>
        </Form>
      </Col>
    </Row>
  );
}

export default Reset;
