import React, { useState } from "react";
import { Row, Col, Button, Badge, Table } from "react-bootstrap";
import Select from "react-select";

import Card from "../App/components/MainCard";
import { useHistory } from "react-router-dom";

import { gql, useQuery } from "@apollo/client";
import { dateFormat } from "./Formatting";
import { hasData } from "../utils/Graphql";
import UserSelect from "./UserSelect";
import CategorySelect from "./CategorySelect";

export const GET_QAS = gql`
  query GetQA {
    allQA {
      id
      title
      locked
      stars
      imageCount
      profileImageUrl
      category {
        id
        title
      }
      manager {
        id
        name
      }
      dateStart
      dateEnd
    }
  }
`;

function QAlist(props) {
  const { loading, error, data } = useQuery(GET_QAS);

  const history = useHistory();
  if (!hasData(data) && loading)
    return (
      <tr>
        <td>Loading...</td>
      </tr>
    );
  if (error)
    return (
      <tr>
        <td>Error...</td>
      </tr>
    );

  function beDate(date) {
    if (typeof date !== Date) {
      date = new Date(date);
    }
    return date;
  }
  let getStatus = (item) => {
    let now = new Date();
    let start = new Date(item.dateStart);
    let end = new Date(item.dateEnd);

    let lock = null;

    if (item.locked) {
      lock = <i className="fas fa-lock" />;
    }

    if (now < start) {
      //Upcoming
      return (
        <Badge variant={"primary"} className="mr-1">
          Væntanlegt {lock}
        </Badge>
      );
    } else if (now < end) {
      //Ongoing
      return (
        <Badge variant={"warning"} className="mr-1">
          Í gangi {lock}
        </Badge>
      );
    } else if (item.stars == null) {
      //Done
      return (
        <Badge variant={"secondary"} className="mr-1">
          Búið {lock}
        </Badge>
      );
    } else if (item.stars > 4) {
      return (
        <Badge variant={"success"} className="mr-1">
          Framúrskarandi {lock}
        </Badge>
      );
    } else if (item.stars > 3) {
      return (
        <Badge variant={"success"} className="mr-1">
          Ágæt {lock}
        </Badge>
      );
    } else if (item.stars > 2) {
      return (
        <Badge variant={"warning"} className="mr-1">
          Hvorki góð né slæm verkefni {lock}
        </Badge>
      );
    } else if (item.stars > 1) {
      return (
        <Badge variant={"danger"} className="mr-1">
          Undir væntingum {lock}
        </Badge>
      );
    } else {
      return (
        <Badge variant={"danger"} className="mr-1">
          Óviðunnandi {lock}
        </Badge>
      );
    }
  };

  let now = new Date();
  let listData = data.allQA
    .filter((item) => {
      if (props.filter.user && props.filter.user.value !== "") {
        if (item.manager?.id != props.filter.user.value) return false;
      }
      if (props.filter.category && props.filter.category.value !== "") {
        if (item.category?.id != props.filter.category.value) return false;
      }
      if (props.filter.status) {
        const start = new Date(item.dateStart);
        const end = new Date(item.dateEnd);
        const active = now > start && now < end;
        if (props.filter.status.value == "soon" && start < now) return false;
        if (props.filter.status.value == "active" && !active) return false;
        if (props.filter.status.value == "done" && now < end) return false;
      }
      return true;
    })
    .sort((a, b) => {
      let x = beDate(a.dateStart);
      let y = beDate(b.dateStart);

      return y - x;
    });

  return listData.map((item) => (
    <tr
      key={"qa-" + item.id}
      data-id={item.id}
      onClick={() => history.push(`/qa/${item.id}`)}
    >
      <td
        className="align-middle"
        style={{
          padding: "0 0.75em",
          textAlign: "center",
        }}
      >
        {hasData(data) && item.profileImageUrl && (
          <img
            style={{
              maxHeight: "40px",
              maxWidth: "60px",
            }}
            src={item.profileImageUrl + "?w=60"}
          />
        )}
      </td>
      <td data-test="title" className="align-middle">
        {item.title} ({item.imageCount})
      </td>
      <td data-test="category" className="align-middle">
        {item.category?.title}
      </td>
      <td data-test="status" className="align-middle">
        {getStatus(item)}
      </td>
      <td data-test="dateStartEnd" className="align-middle">
        {dateFormat(new Date(item.dateStart)) +
          " - " +
          dateFormat(new Date(item.dateEnd))}
      </td>
      <td className="align-middle">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => history.push(`/qa/${item.id}`)}
        >
          Skoða nánar
          <i className="fas fa-chevron-right" />
        </Button>
      </td>
    </tr>
  ));
}

function QAs(props) {
  const [filter, setFilter] = useState({
    status: { value: "active", label: "Í gangi" },
  });
  const history = useHistory();
  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col xs={12} lg="12">
          <Card noHeader>
            <Row>
              <Col xs={12} md={12}>
                <h5>Leit</h5>
              </Col>
              <Col xs={12} md={3} key="search-user">
                <UserSelect
                  placeholder="Allir ábyrðaraðilar"
                  extraOptions={[{ value: "", label: "Allir ábyrðaraðilar" }]}
                  value={filter.user?.value || ""}
                  handleSelectUpdate={(e) => {
                    setFilter({
                      ...filter,
                      user: e,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={3} key="search-complete">
                <CategorySelect
                  placeholder="Allir flokkar"
                  extraOptions={[{ value: "", label: "Allir flokkar" }]}
                  value={filter.category?.value || ""}
                  handleSelectUpdate={(e) => {
                    setFilter({
                      ...filter,
                      category: e,
                    });
                  }}
                />
              </Col>
              <Col xs={12} md={3}>
                <Select
                  id="statusFilter"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0px",
                    }),
                  }}
                  className="basic-single form-control"
                  classNamePrefix="select"
                  placeholder={"Staða"}
                  value={filter.status}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      status: e,
                    })
                  }
                  options={[
                    { value: "", label: "Staða allt" },
                    { value: "soon", label: "Væntanlegt" },
                    { value: "active", label: "Í gangi" },
                    { value: "done", label: "Búið" },
                  ]}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs lg="12">
          <Card title="Gæðaeftirlit">
            <Button
              style={{ position: "absolute", top: "20px", right: "20px" }}
              size="sm"
              variant="primary"
              onClick={() => history.push("/qa/new")}
            >
              Nýtt gæðaeftirlit
              <i className="fas fa-chevron-right" />
            </Button>
            <Table data-test="qas" responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>Titill</th>
                  <th>Flokkur</th>
                  <th>Staða</th>
                  <th>Tímabil</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{<QAlist filter={filter} />}</tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default QAs;
