import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Button, Form, Table } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import { dateFormat, numberFormat } from "../Formatting";
import FailWhale from "../../components/FailWhale";
import { useRest } from "../../utils/Rest";
import * as Bar from "@nivo/bar";
import Sugar from "sugar";
import Color from "../../utils/Color";
import useQuery from "../../utils/useQuery";
import { renderBar } from "../../utils/CustomGraph";

const color_scheme = [
  "#2965aa",
  "#a8322f",
  "#87a939",
  "#6e5094",
  "#288da2",
  "#e2df30",
  "#ce2ca8",
  "#d47822",
  "#43c282",
  "#2dc2b6",
];

const nullImage =
  "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
const imageCache = {};
const loadImage = (url) => {
  var img = imageCache[url];
  if (img && img.src) return img;

  const image = new Image();
  image.onerror = function (error) {
    image.src = nullImage;
    console.log(this, error);
  };
  image.src = url;

  imageCache[url] = image;
  return image;
};

export default function ProductGraphReport(props) {
  const query = useQuery();
  const history = useHistory();
  const [selectedProductId, setSelectedProductId] = useState();
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    history.replace({ search: query.toString() });
  }, [query.toString()]);

  let pricesurveyId = props.match.params.id;
  const {
    data: item = {
      products: [],
    },
    loading: priceSurveyLoading,
  } = useRest(`/api/price-surveys/${pricesurveyId}`);

  const { data: points = [] } = useRest(
    `/api/reports/price_survey_product_report/${pricesurveyId}`
  );

  const selectableProductIds = points
    .map((p) => p.product_id)
    .unique()
    .sort();

  useEffect(() => {
    if (selectedProductId === undefined && selectableProductIds) {
      const pid = query.get("product_id");
      if (pid && selectableProductIds.includes(pid)) {
        setSelectedProductId(pid);
      } else {
        const id = selectableProductIds.first();
        setSelectedProductId(id);
      }
    }
  }, [item, points]);

  const productData = (points || [])
    ////.filter((p) => p.avg_price > 0)
    .filter((p) => {
      if (selectedProductId) {
        return p.product_id === selectedProductId;
      }
      return true;
    });

  const selectableDates = productData
    .map((p) => p.date)
    .unique()
    .sort()
    .reverse();

  useEffect(() => {
    if (productData) {
      if (!selectableDates.includes(selectedDate)) {
        setSelectedDate(selectableDates.first());
      }
    }
  }, [productData]);

  if (priceSurveyLoading) {
    //TODO: use loading component
    return null;
  }

  if (item === null) {
    return <FailWhale />;
  }

  const barProps = {
    margin: { top: 50, right: 40, bottom: 50, left: 40 },
    pointSize: 8,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    pointBorderColor: { theme: "background" },
    axisBottom: {
      tickRotation: 15,
    },
    padding: 0.5,
    colors: color_scheme,
    colorBy: "indexValue",
    tooltip: ({ indexValue, value, color }) => (
      <div className="p-2 bg-light text-dark">
        <strong>
          Verð - {indexValue}: {value} kr
        </strong>
      </div>
    ),
  };

  var barData = (item.franchises || []).map((f) => {
    const data = productData
      .filter((p) => {
        if (selectedDate) {
          return p.date === selectedDate;
        }
        return true;
      })
      .find((d) => d.franchise_id == f.id);

    return {
      franchise: f.name,
      avg_price: data?.avg_price ?? 0,
      image_url: f.logo,
      barImage: f.logo ? loadImage(f.logo) : null,
      color: f.color,
    };
  });
  var selectedProduct = item.products.find((p) => p.id == selectedProductId);

  if (barData.length === 0) {
    barData.push({
      avg_price: 0,
      product: "",
      franchise: "",
      date: dateFormat(),
    });
  }
  const graphWidth = Math.max(360, barData.length * 140);
  const heighestValue = barData.map((d) => d.avg_price).max();
  const maxValue = Math.ceil((heighestValue * 1.1) / 100) * 100;

  const canRenderChart = points && !priceSurveyLoading;
  return (
    <div id="graph-report">
      <div className="sidebar">
        <Card title="Keðjumismunur vöru">
          {selectedProduct?.logoUrl && (
            <img src={selectedProduct.logoUrl + "?w=200"} />
          )}

          <label>Velja Vörur</label>
          <select
            className="form-control"
            onChange={(e) => {
              const productId = e.target.value;
              setSelectedProductId(productId);
              query.set("product_id", productId);
            }}
            value={selectedProductId}
          >
            {item.products
              .filter((p) => selectableProductIds.includes(p.id))
              .map((f) => (
                <option key={f.id} value={f.id}>
                  {f.title}
                </option>
              ))}
          </select>

          <label>Velja Dagsetningu</label>
          <select
            className="form-control"
            onChange={(e) => setSelectedDate(e.target.value)}
            value={selectedDate}
          >
            {selectableDates.map((date) => (
              <option key={date} value={date}>
                {dateFormat(date)}
              </option>
            ))}
          </select>
        </Card>
      </div>
      <div className="graph-card-container">
        <Card
          title={<div className="text-center">{dateFormat(selectedDate)}</div>}
        >
          <div
            style={{
              display: "inline-block",
              left: "10px",
              top: "10px",
              float: "left",
              padding: 0,
              position: "absolute",
            }}
          >
            <Button
              onClick={() => history.push(`/price-surveys/${item.id}`)}
              size="sm"
            >
              Til baka
            </Button>
          </div>
          <div className="graph-container">
            <div
              className="arrow arrow-left"
              onClick={() => {
                const i = selectableProductIds.indexOf(selectedProductId);
                const len = selectableProductIds.length;

                setSelectedProductId(selectableProductIds[(i - 1 + len) % len]);
              }}
            >
              <i className="feather f-32 icon-arrow-left" />
            </div>
            <div
              className="arrow arrow-right"
              onClick={() => {
                const i = selectableProductIds.indexOf(selectedProductId);
                const len = selectableProductIds.length;
                setSelectedProductId(selectableProductIds[(i + 1) % len]);
              }}
            >
              <i className="feather f-32 icon-arrow-right" />
            </div>
            <div className="graph">
              {canRenderChart && (
                <Bar.BarCanvas
                  height={600}
                  width={graphWidth}
                  maxValue={maxValue}
                  {...barProps}
                  keys={["avg_price"]}
                  indexBy="franchise"
                  data={barData}
                  renderBar={renderBar}
                />
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
