import React, { Fragment, useState, useContext } from "react";

import NavLeft from "./NavLeft";
import NavRight from "./NavRight";
import APP_LINKS from "../../../../store/constant";
import * as actionTypes from "../../../../store/actions";

import { gql, useQuery, useMutation } from "@apollo/client";
import GlobalContext from "../../../../GlobalContext";
import { useWindowSize } from "../../../../utils/reactHooks";

const GET_ACCOUNT_QUERY = gql`
  query GetAccount {
    account {
      id
      title
      logo
      top_background
    }
  }
`;

function AppTopIcon() {
  const { loading, error, data } = useQuery(GET_ACCOUNT_QUERY);
  const { title, logo, top_background } = data?.account || {};

  return (
    <a href={APP_LINKS.HOME_LINK} className="b-brand">
      {/*<img id="main-logo" src={logo} alt={title} className="logo img-fluid" />*/}
      {top_background && (
        <style>{`body { background-image: url(${top_background}) !important; background-size: cover; }`}</style>
      )}
      <style>
        {".pcoded-main-container{background:transparent !important;}"}
      </style>
    </a>
  );
}

function NavBar() {
  const {
    state: { rtlLayout, headerFixedLayout, collapseMenu, layout, subLayout },
    dispatch,
  } = useContext(GlobalContext);
  const windowSize = useWindowSize();

  const onToggleNavigation = () =>
    dispatch({ type: actionTypes.COLLAPSE_MENU });
  let headerClass = [
    "navbar",
    "pcoded-header",
    "navbar-expand-lg",
    "header-blue",
  ];

  if (headerFixedLayout) {
    headerClass = [...headerClass, "headerpos-fixed"];
  }

  let toggleClass = ["mobile-menu"];
  if (collapseMenu) {
    toggleClass = [...toggleClass, "on"];
  }

  let navHtml = windowSize.width >= 992 && (
    <div className="collapse navbar-collapse d-flex">
      <NavLeft />
      <NavRight rtlLayout={rtlLayout} />
    </div>
  );

  let navBar = (
    <Fragment>
      <div className="m-header">
        <a
          className={toggleClass.join(" ")}
          id="mobile-collapse1"
          href={APP_LINKS.BLANK_LINK}
          onClick={onToggleNavigation}
        >
          <span />
        </a>
        <AppTopIcon />
      </div>
      {navHtml}
    </Fragment>
  );

  if (layout === "horizontal" && subLayout === "horizontal-2") {
    navBar = <div className="container">{navBar}</div>;
  }

  return (
    <Fragment>
      <header className={headerClass.join(" ")}>{navBar}</header>
    </Fragment>
  );
}

export default NavBar;
