import React, { useContext, useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { CloseTask } from "./Tasks";
import {
  Row,
  Col,
  Media,
  Table,
  Image,
  Modal,
  Form,
  Badge,
  Dropdown,
} from "react-bootstrap";

import ReactStars from "react-stars";

import Card from "../App/components/MainCard";
import GalleryMasonry from "../App/components/GalleryMasonry";
import NoResults from "../App/components/NoResults";
import Button from "../components/Button";
import { Images } from "./Images";
import { UserContext } from "./../UserContext";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Query } from "@apollo/client/react/components";
import SelectWithData from "./SelectWithData";
import GaugeChart from "./GaugeChart";
import FinishModalButton from "./FinishModal";
import UserProfileImage from "../components/UserProfileImage";
import CreateTaskButton from "./CreateTaskModal";

import { dateFormat, dateTimeFormat, currencyFormat } from "./Formatting";

const brakePoints = [200, 500, 750];
const Tile = ({ src }) => {
  return (
    <div className="tile">
      <img src={src} />
    </div>
  );
};

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const GET_STORES = gql`
  query GetStores {
    options: stores {
      value: id
      label: name
    }
  }
`;

const REMOVE_IMAGE = gql`
  mutation RemoveImage($id: ID!, $projectId: ID!) {
    deleted: removeImageFromProject(id: $id, projectId: $projectId) {
      id
    }
  }
`;

import DatePicker from "react-datepicker";
import { useApolloClient } from "@apollo/client";

function ImageUploader(props) {
  const [show, setShow] = useState(false);
  let Component = null;
  let buttonProps = {};
  if (props.as === "dropdown") {
    Component = Dropdown.Item;
  } else {
    Component = Button;
    buttonProps = {
      size: "sm",
    };
  }
  return (
    <React.Fragment>
      <Component
        {...buttonProps}
        className={props.className}
        style={props.style}
        disabled={props.locked}
        onClick={() => setShow(true)}
      >
        Hlaða upp mynd
      </Component>

      <ImageUploadModal {...props} show={show} onClose={() => setShow(false)} />
    </React.Fragment>
  );
}

function ImageThumb(props) {
  const imgRef = useRef(null);
  const loadImage = (ref) => (e) => {
    ref.current.src = e.target.result;
  };
  const reader = new FileReader();
  reader.onload = loadImage(imgRef);
  reader.readAsDataURL(props.image);
  return <img ref={imgRef} style={{ width: "40px", height: "40px" }} />;
}

function TagsInput({ onUpdate, ...props }) {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const components = {
    DropdownIndicator: null,
  };
  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleChange = (value, actionMeta) => {
    onUpdate((value || []).map((v) => v.value));
    setTags(value || []);
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setInputValue("");
        const newTags = [...tags, createOption(inputValue)];
        onUpdate(newTags.map((v) => v.value));
        setTags([...tags, createOption(inputValue)]);
        event.preventDefault();
    }
  };

  return (
    <CreatableSelect
      className="w-100"
      components={components}
      menuIsOpen={false}
      isMulti
      inputValue={inputValue}
      value={tags}
      onInputChange={(input) => setInputValue(input)}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder="Tögg"
    />
  );
}

function ImageUploadModal(props) {
  const client = useApolloClient();

  const [imagesAndTags, setImagesAndTags] = useState([]);
  const [date, setDate] = useState(new Date());
  const [store, setStore] = useState("");
  const [feedback, setFeedback] = useState("");

  const trimName = (string) => {
    var maxLength = 20;
    var trimmedString =
      string.length > maxLength
        ? string.substring(0, maxLength - 3) + "..."
        : string;
    return trimmedString;
  };

  const removeImage = (imageToRemove) => {
    const newImages = imagesAndTags.filter(
      (imageAndTags) => imageToRemove.name !== imageAndTags.image.name
    );
    setImagesAndTags(newImages);
  };

  const Submit = (imagesAndTags, store, date, qaId) => {
    if (store == "") {
      setFeedback("Þarf að hafa staðsetningu");
      return;
    }
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open("POST", "/upload/images-qa");
    imagesAndTags.map((imageAndTags) => {
      formData.append("files", imageAndTags.image, imageAndTags.image.name);
      formData.append("tags[]", imageAndTags.tags.join("<#>"));
      formData.append(`comments[]`, imageAndTags.comment);
      const needsAttention = imageAndTags.needsAttention ? "1" : "0";
      formData.append("needsAttentions[]", needsAttention);
    });
    formData.append("qaId", qaId);
    formData.append("storeId", store);
    formData.append("date", date.toISOString());

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        var status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          const resImages = JSON.parse(request.responseText);
          let data = client.readQuery({
            query: GET_QA,
            variables: { id: qaId },
          });
          let mutData = JSON.parse(JSON.stringify(data));
          Object.set(mutData, `qa.images`, [
            ...mutData.qa.images,
            ...resImages,
          ]);
          client.writeQuery({ query: GET_QA, data: mutData });

          setImagesAndTags([]);
          setStore("");
          setFeedback("");
          props.onClose();
        } else {
          if (status === 401) {
            location.reload(true);
          }
        }
      }
    };
    request.send(formData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Submit(imagesAndTags, store, date, props.qaId);
  };

  const AddTagsToImages = (index) => (tags) => {
    imagesAndTags[index].tags = tags;
    setImagesAndTags(imagesAndTags);
  };

  const updateNeedsAttention = (index, needsAttention) => {
    // NOTE: This spread needs to be done so that the components renders the changes
    // set function needs to get a new reference(spreading make it a new ref) so that it updates the dom
    const newArray = [...imagesAndTags];
    newArray[index].needsAttention = needsAttention;
    setImagesAndTags(newArray);
  };

  const updateComment = (index, comment) => {
    const newArray = [...imagesAndTags];
    newArray[index].comment = comment;
    setImagesAndTags(newArray);
  };
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Hlaða upp myndir</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.File
            id="custom-file"
            label=""
            style={{ marginBottom: "20px" }}
            custom
            accept="image/*"
            multiple
            onChange={(e) => {
              const newImages = [...e.target.files]
                .filter((file) => {
                  const isImage = file.type.startsWith("image/");
                  return isImage;
                })
                .map((image) => ({
                  image,
                  tags: [],
                  comment: "",
                  needsAttention: false,
                }));
              const allImages = [...imagesAndTags, ...newImages];
              setImagesAndTags(allImages);
            }}
          />
          <Table responsive>
            <tbody>
              {imagesAndTags.map((img, index) => (
                <React.Fragment>
                  <tr key={"image-" + img.image.name}>
                    <td width="42px">
                      <ImageThumb image={img.image} />
                    </td>
                    <td width="320px">
                      <TagsInput onUpdate={AddTagsToImages(index)} />
                    </td>
                    <td width="16px">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          updateNeedsAttention(index, !img.needsAttention);
                        }}
                      >
                        <i
                          className="fas fa-exclamation-circle f-24"
                          style={{
                            color: img.needsAttention ? "#d40" : "#999",
                          }}
                          title="Þarf athygli"
                        />
                      </div>
                    </td>
                    <td width="30px">
                      <Button onClick={() => removeImage(img.image)} size="sm">
                        <i className={"fas fa-trash"} />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={{ paddingTop: "0px" }}>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          name="comment"
                          placeholder="Athugasemd"
                          onChange={(e) => updateComment(index, e.target.value)}
                          rows={2}
                        />
                      </Form.Group>
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <Form.Label>Dagsetning</Form.Label>
          <DatePicker
            selected={date}
            wrapperClassName="w-100"
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            onChange={(e) => {
              e.setHours(0);
              e.setMinutes(0);
              e.setSeconds(0);
              setDate(e);
            }}
            className="form-control"
          />
          <Form.Label>Staðsetning</Form.Label>
          <SelectWithData
            query={GET_STORES}
            placeholder={"Veldu búð"}
            onChange={(e) => setStore(e.value)}
            value={store}
          />
        </Modal.Body>
        <Modal.Footer>
          {feedback && <div style={{ color: "red" }}>*{feedback}</div>}
          <Button type="submit">Áfram</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export const GET_QA = gql`
  query GetQA($id: ID!) {
    qa(id: $id) {
      id
      title
      thumbImageUrl
      dateStart
      dateEnd
      locked
      stars
      review
      linkedTasks {
        id
        title
        closed
      }
      manager {
        id
        name
        profileImageUrl
      }
      category {
        id
        title
      }
      images {
        id
        date
        url
        tags
        needsAttention
        comment
        store {
          id
          name
          gps {
            lat
            lng
          }
        }
      }
      stores {
        id
        name
      }
      franchises {
        id
        name
      }
      product {
        id
        title
      }
      trademark {
        id
        title
        manager {
          id
          name
          profileImageUrl
        }
      }
      supplier {
        id
        title
        manager {
          id
          name
          profileImageUrl
        }
      }
      comments {
        user {
          id
          name
        }
        content
        date
      }
    }
  }
`;

const LOCK_QA = gql`
  mutation LockQA($id: ID!) {
    updateQA(id: $id, locked: true) {
      id
    }
  }
`;

function beDate(date) {
  if (typeof date !== Date) {
    return new Date(date);
  }
  return date;
}

function UploadProfileButton(props) {
  const client = useApolloClient();
  const inputFile = useRef(null);
  const Submit = (file, qaId) => {
    const request = new XMLHttpRequest();
    const formData = new FormData();
    request.open("POST", "/upload/profile-project");
    formData.append("file", file);
    formData.append("projectId", qaId);

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        var status = request.status;
        if (status === 0 || (status >= 200 && status < 400)) {
          const imageUrl = request.responseText;
          let data = client.readQuery({
            query: GET_QA,
            variables: { id: qaId },
          });
          let mutData = JSON.parse(JSON.stringify(data));
          Object.set(mutData, `qa.thumbImageUrl`, imageUrl);
          client.writeQuery({ query: GET_QA, data: mutData });
        } else {
        }
      }
    };
    request.send(formData);
  };

  return (
    <React.Fragment>
      <Dropdown.Item
        className={props.className}
        style={props.style}
        disabled={props.locked}
        onClick={() => {
          inputFile.current.click();
        }}
        as="li"
      >
        Profile mynd
        <i className="fas fa-upload pl-1" />
      </Dropdown.Item>

      <input
        ref={inputFile}
        style={{ display: "none" }}
        id="upload"
        type="file"
        accept="image/*"
        onChange={(e) => {
          Submit(e.target.files[0], props.qa.id);
        }}
      />
    </React.Fragment>
  );
}

function BasicInfoCard(props) {
  const { loading, data, error } = props;
  const { qa } = data || { qa: {} };

  const [lockqa] = useMutation(LOCK_QA, {
    update(cache, { data: { locked } }) {
      const cachedData = cache.readQuery({
        query: GET_QA,
        variables: { id: qa.id },
      });

      const qaCache = {
        ...cachedData.qa,
        locked: locked,
      };
      cache.writeQuery({
        query: GET_QA,
        variables: { id: qa.id },
        data: { qaCache },
      });
      props.refetch();
    },
  });

  let dateDiffDays = (date1, date2) => {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    return Math.floor((d1 - d2) / (1000 * 60 * 60 * 24));
  };

  let now = new Date();
  let end = new Date(qa.dateEnd);

  let stakeholders = [];
  if (!loading) {
    if (qa.trademark?.manager) {
      stakeholders.push(
        <UserProfileImage
          size={20}
          user={qa.trademark?.manager}
          title={qa.trademark.title + ": " + qa.trademark?.manager.name}
        />
      );
    }

    if (qa.supplier?.manager) {
      stakeholders.push(
        <UserProfileImage
          size={20}
          user={qa.supplier?.manager}
          title={qa.supplier?.title + ": " + qa.supplier?.manager.name}
        />
      );
    }
  }
  return (
    <Card title={loading ? "..." : qa.title}>
      <Row>
        <Col xs={12} md={4}>
          {!loading && qa.thumbImageUrl && (
            <img
              style={{
                display: "block",
                width: "150px",
                margin: "0px auto 10px",
              }}
              src={qa.thumbImageUrl + "?w=150"}
            />
          )}
        </Col>
        <Col xs={12} md={8}>
          <Table responsive size="sm">
            <tbody>
              <tr key="info-qa-date-start">
                <th>Herferð hefst</th>
                <td className="text-right">
                  {loading ? "..." : dateFormat(new Date(qa.dateStart))}
                </td>
              </tr>
              <tr key="info-qa-date-end">
                <th>Herferð lýkur</th>
                <td className="text-right">
                  {loading ? "..." : dateFormat(new Date(qa.dateEnd))}
                </td>
              </tr>
              <tr key="info-qa-days-total">
                <th>Fjöldi daga í herferð</th>
                <td className="text-right">
                  {loading ? "..." : dateDiffDays(qa.dateEnd, qa.dateStart)}
                </td>
              </tr>
              <tr key="info-qa-category">
                <th>Flokkur</th>
                <td data-test="category" className="text-right">
                  {loading || !qa.category ? "" : qa.category.title}
                </td>
              </tr>
              <tr key="info-qa-manager">
                <th>Ábyrgðarmaður</th>
                <td data-test="manager" className="text-right">
                  {loading || !qa.manager ? "" : qa.manager.name}
                </td>
              </tr>
              <tr key="info-qa-supplier">
                <th>Birgi</th>
                <td data-test="supplier" className="text-right">
                  {loading || !qa.supplier ? "" : qa.supplier.title}
                </td>
              </tr>
              <tr key="info-qa-trademark">
                <th>Vörumerki</th>
                <td data-test="trademark" className="text-right">
                  {loading || !qa.trademark ? "" : qa.trademark.title}
                </td>
              </tr>
              <tr key="info-qa-product">
                <th>Vara</th>
                <td data-test="product" className="text-right">
                  {loading || !qa.product ? "" : qa.product.title}
                </td>
              </tr>
              <tr key="info-campaign-stakeholders">
                <th>Hagaðilar</th>
                <td className="text-right">{stakeholders}</td>
              </tr>
              <tr key="info-qa-franchises">
                <th>Keðjur</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : qa.franchises.map((franchise) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"franchise-" + franchise.id}
                        >
                          {franchise.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-qa-stores">
                <th>Verslanir</th>
                <td className="text-right">
                  {loading
                    ? "..."
                    : qa.stores.map((store) => (
                        <Badge
                          className="ml-1"
                          style={{
                            display: "inline-block",
                            float: "left",
                            marginBottom: "5px",
                          }}
                          variant="primary"
                          key={"store-" + store.id}
                        >
                          {store.name}
                        </Badge>
                      ))}
                </td>
              </tr>
              <tr key="info-qa-tasks">
                <th>Tengd verkefni</th>
                <td>
                  {loading
                    ? ""
                    : qa.linkedTasks.map((task) => {
                        const length = 40;
                        let shortTitle = task.title.substring(0, length);
                        if (shortTitle !== task.title) {
                          shortTitle =
                            task.title.substring(0, length - 3) + "...";
                        }
                        return (
                          <div key={"task-" + task.id}>
                            <CloseTask
                              item={task}
                              style={{
                                display: "inline-block",
                                marginRight: "10px",
                              }}
                            />
                            <Link to={"/tasks/" + task.id}>{shortTitle}</Link>
                          </div>
                        );
                      })}
                </td>
              </tr>
              {qa.stars != null && (
                <React.Fragment>
                  <tr key="info-qa-stars">
                    <th>Einkunn</th>
                    <td>
                      <ReactStars
                        className="pull-right text-height-0"
                        count={5}
                        size={20}
                        half={false}
                        edit={false}
                        value={qa.stars}
                      />
                    </td>
                  </tr>
                  <tr key="info-qa-review">
                    <th>Einkunnar umsögn</th>
                    <td>
                      <div className="text-wrap text-right">{qa.review}</div>
                    </td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
      <div style={{ position: "absolute", top: "20px", right: "20px" }}>
        {now > end && (
          <React.Fragment>
            <FinishModalButton qa={qa} />
            <Button
              size="sm"
              className="ml-1"
              variant="primary"
              disabled={loading || qa.locked}
              onClick={async () => {
                const response = await lockqa({
                  variables: { id: qa.id },
                });
              }}
            >
              {qa.locked ? "læst" : "læsa"}
              <i className="fas fa-lock pl-1" />
            </Button>
          </React.Fragment>
        )}

        <Button
          size="sm"
          className="ml-1"
          variant="primary"
          disabled={loading || qa.locked}
          href={loading ? "" : "/qa/edit/" + qa.id}
        >
          Breyta
          <i className="fas fa-chevron-right" />
        </Button>

        <Dropdown className="d-inline-block ml-1">
          <Dropdown.Toggle variant="primary" size="sm">
            <span className="fa fa-bars" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <CreateTaskButton title={qa.title} qa={qa}>
              {(props) => (
                <Dropdown.Item {...props}>Nýtt verkefni</Dropdown.Item>
              )}
            </CreateTaskButton>
            <Dropdown.Divider />
            <ImageUploader
              as="dropdown"
              qaId={qa.id}
              locked={loading ? null : qa.locked}
            />
            <UploadProfileButton qa={qa} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Card>
  );
}

const ADD_COMMENT = gql`
  mutation AddComment($id: ID!, $content: String!) {
    newComment: addCommentQA(id: $id, content: $content) {
      content
      date
      user {
        id
        name
      }
    }
  }
`;

function Comments(props) {
  let { qa, loading } = props;
  if (loading) {
    return <Card title="Athugasemdir">Loading...</Card>;
  }
  const user = useContext(UserContext);

  const [content, setContent] = useState("");
  const [posting, setPosting] = useState(false);

  const [addComment] = useMutation(ADD_COMMENT, {
    update(cache, { data: { newComment } }) {
      const cachedData = cache.readQuery({
        query: GET_QA,
        variables: { id: qa.id },
      });

      const qaCache = {
        ...cachedData.qa,
        comments: [newComment, ...cachedData.qa.comments],
      };
      cache.writeQuery({
        query: GET_QA,
        variables: { id: qa.id },
        data: { qaCache },
      });
      props.refetch();
    },
  });

  const onKey = async (e) => {
    if (e.key === "Enter" && content != "") {
      setPosting(true);
      const response = await addComment({
        variables: {
          id: qa.id,
          content,
        },
      });

      setContent("");
      setPosting(false);
    }
  };

  const onChange = (e) => {
    if (posting) {
      return;
    }
    setContent(e.target.value);
  };

  return (
    <Card title="Athugasemdir">
      <Form.Group>
        <Form.Control
          disabled={loading || posting || (qa || {}).locked}
          type="textarea"
          rows={3}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          onKeyPress={onKey}
        />
      </Form.Group>
      <div className="overflow-auto" style={{ height: "300px" }}>
        {loading
          ? "..."
          : [...(qa.comments || [])].map((comment, index) => (
              <Media key={"comment-" + index}>
                <Media.Body>
                  <h5>
                    {comment.user.name}
                    {" - "}
                    {dateTimeFormat(new Date(comment.date))}
                  </h5>
                  <p>{comment.content}</p>
                </Media.Body>
              </Media>
            ))}
      </div>
    </Card>
  );
}

function ViewQA(props) {
  let qaId = props.match.params.qaId;

  const [remove] = useMutation(REMOVE_IMAGE, {
    variables: { projectId: qaId },
    update(
      cache,
      {
        data: {
          deleted: { id },
        },
      }
    ) {
      let data = cache.readQuery({
        query: GET_QA,
        variables: { id: qaId },
      });
      let mutData = JSON.parse(JSON.stringify(data));
      Object.set(
        mutData,
        `qa.images`,
        mutData.qa.images.filter((item) => item.id !== id)
      );
      cache.writeQuery({ query: GET_QA, data: mutData });
    },
  });

  return (
    <React.Fragment>
      <Query query={GET_QA} variables={{ id: qaId }}>
        {({ loading, error, data, refetch }) => {
          if (data && !data.qa) {
            return (
              <Row className="justify-content-md-center">
                <Col xs md="6" xl="4">
                  <Card title="Villa">
                    <h3 className="font-weight-bold text-muted">
                      Engin herferð fannst.
                    </h3>
                  </Card>
                </Col>
              </Row>
            );
          }

          if (!loading && !data) {
            return (
              <Row className="justify-content-md-center">
                <Col xs md="6" xl="4">
                  <Card title="Villa">
                    <h3 className="font-weight-bold text-muted">
                      Villa við birtingu gæðaeftirlits
                    </h3>
                  </Card>
                </Col>
              </Row>
            );
          }

          return (
            <React.Fragment>
              <Row>
                <Col xs={12} xl={12} key={"information-qa-" + qaId}>
                  <BasicInfoCard
                    refetch={refetch}
                    loading={loading}
                    data={data}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} xl={{ span: 12 }}>
                  {loading ? null : (
                    <Images
                      title="Myndsafn"
                      images={data.qa.images
                        .map((x) => x)
                        .sort((a, b) => {
                          let x = beDate(a.date);
                          let y = beDate(b.date);

                          return y - x;
                        })}
                      remove={remove}
                    >
                      <ImageUploader
                        qaId={qaId}
                        locked={loading ? null : data.qa.locked}
                      />
                    </Images>
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12} key={"comments-qa-" + qaId}>
                  <Comments refetch={refetch} loading={loading} qa={data?.qa} />
                </Col>
              </Row>
            </React.Fragment>
          );
        }}
      </Query>
    </React.Fragment>
  );
}

export default ViewQA;
